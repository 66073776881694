import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 42px;
  background-color: ${props=> props.theme.background.Grey_1};
  box-sizing: border-box;
  display: flex;
  border-radius: 15px;
  align-items: center;
  padding: 12px;
  border: ${(props) =>
    props.isSearchButtonActived === true
      ? `2px solid ${props.theme.colors.Grey_5}` 
      : "none"};


@media (min-width: 320px) and (max-width: 520px) {
    input{
        font-size: 16px;
      }
  }
`;

export const SearchIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export const InputBox = styled.input`
  width: 100%;
  height: 18px;
  border: none;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  color: ${props=> props.theme.text.Black_1};
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;

  input::placeholder {
    color: ${props=> props.theme.text.Primary};
  }

`;

export const ClearSearchIcon = styled.button`
  width: 20px;
  height: 20px;
  display: ${(props) => (props.isSearchButtonActived ? "flex" : "none")};
  background-color: transparent;
  outline: none;
  border: none;
  margin-left: 8px;
  text-align: center;
  justify-content: center;
`;
