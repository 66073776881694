import styled from "styled-components";


export const BoxInicial = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  font-style: normal;
  font-weight: 500;
  padding: 2em;
  margin-bottom: 50px;
  background-color: ${props=>props.theme.background.Primary};

  #categoria {
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 100%;
    margin: 16px 0px;
    background-color: ${props=>props.theme.background.Primary};

    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
      height: 0px;
    }
    
    .button, .focusbutton {
      margin-right: 12px;
      background: ${props=>props.theme.background.Grey_1};
      color: ${props=>props.theme.colors.Grey_4};
      white-space: nowrap;
      height: 36px;
      outline: none;
      box-shadow: 0 0 0 0;
      border: none;
      border-radius: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      padding: 6px 15px;
    }
    .focusbutton {

      background: ${props=>props.theme.background.Red_1};
      color: ${props=>props.theme.colors.White};

    }

  }

  .itens-categoria-wrapper {
    width: 100%;
    background-color: green;
    z-index: 100;
    background-color: ${props=>props.theme.background.Primary};
    display: flex;
    align-items: center;
    border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};
    left: 0;
  }

  .spinner {
    justify-content: center;
    min-height: calc(100vh - 55px);
    align-items: center;
  }

  .row {
    margin: 0;
    padding: 0;
  }

  .linkinterno {
    display: flex;
    justify-items: center;
    align-items: center;
    text-decoration: none;
  }

  .categoriaitens {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    padding: 1em 0 1em 0;

    h2 {
      padding: 0;
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
  }

  @media (width: 320px){
    padding: 1em;
  }

  @media (width: 280px){
    padding: 0;
  }

`;

export const BoxFoto = styled.img`
  display: flex;
  height: 100%;
  width: 100%;
  object-fit: cover;
  justify-content: center;
  margin: 0;
  padding: 0;
`;

export const BoxIconNavBar = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;


export const BoxInfoPedidos = styled.div`
  display: flex;
  width: 100%;
  height: 75px;
  align-items: center;
  padding: 10px 20px;
  background: ${props=>props.theme.background.White_2};
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  z-index: 100
`;

export const Boxcategory = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  padding: 1em 0 1em 0;
  

  h2 {
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
  
`;

