import * as Styles from "./styles";
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import iconPromotion from '../../assets/iconPromotion.svg';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";

export const ProductPromotion = (props) => {

    return (
        <Styles.Boxwrapperpromotion>
            {
                props.arrowLeft? (
                    <Styles.BoxButtonLeft onClick={props.left} src={IconeVoltar} alt='icone de seta para avançar'
                    />
                )
                :(
                    null
                )
            }
        
            <Styles.BoxContainerpromotion id='boxcontainerpromotion' onScroll={() => props.verifyScroll()} >
                {
                    props.productCategory.map((item, index) => 
                        <Styles.BoxCardPromotion className="containergrupoprodutos" key={index+1
                        } id={`boxpromotion${index+1}`} tabIndex={index}> 
                            <Link className='link'  
                                onClick={() => props.routerProductAdd(item)} 
                                to={`/AdicionarProduto/${item.id}/${item.link_web}`}>
                                <Row>

                                    <LazyLoadImage
                                        width={'100px'} 
                                        height={'100px'}
                                        src={`${props.urlImg}${item.foto_descricao}`}
                                        // src={`https://www.sistefood.com.br/img/19651715000132/${item.foto_descricao}`}
                                        alt={item.foto_descricao}
                                        effect={'blur'}
                                    />  
                                </Row>
                        
                                <Styles.BoxCardinternoPromotion>
                                    <p className="nomeproduto">{item.nome}</p>
                                    <p className="infoitem">{item.descricao_web}</p> 

                                    <div className="prices">
                                        <p className="valuepromotion">{`${parseFloat(item.preco_comparativo_web).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p> 
                                        <p className="valuenotpromotion">{`${parseFloat(item.preco_venda).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p> 
                                        <Styles.IconPromotion src={iconPromotion} alt={'ícone de promoção'}/>
                                    </div>
                                </Styles.BoxCardinternoPromotion>    
                
                            </Link> 
                        </Styles.BoxCardPromotion> 
                    )
                }
            </Styles.BoxContainerpromotion>

            {
                props.arrowRight ? (
                    <Styles.BoxButtonRight onClick={props.right} src={IconeVoltar} alt='icone de seta para voltar'/>
                )
                :(
                    null
                )
            }
        </Styles.Boxwrapperpromotion>
    )
}
