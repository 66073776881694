import React from "react";

import 
{
    BoxAddProduto, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxQtdProduto,
    BoxBotaototal,
    BoxModal,
    BoxSacolaProdutos,
    BoxQtdProdutosSacola,
    BoxFoto,
    BoxInfoProdutos,
    BoxAcoes,
    BoxTotais,
    BoxEndereco,
    WrapperBoxTotais,
    BoxFormaPagamento,
    DeliveryOptionsWrapper,
    DeliveryOptionsButton,
    DeliveryOptionsHeader,
    BoxIcone,
    BoxMessage,
    DeliveryAskForMore
} from './style' 

import { Spinner} from 'react-bootstrap'
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import Exluir from '../../assets/Commons/LIXEIRA.svg';
import Editar from '../../assets/Commons/EDITAR.svg';
import { entrar, getToken } from "../../service/auth";
import { logout } from "../../service/auth";
import iconButtonDelivery from '../../assets/ProductsBag/iconButtonDelivery.svg'
import iconButtonDeliveryActive from '../../assets/ProductsBag/iconButtonDeliveryActive.svg'
import iconButtonWithdrawActive from '../../assets/ProductsBag/iconButtonWithdrawActive.svg'
import iconButtonWithdraw from '../../assets/ProductsBag/iconButtonWithdraw.svg'
import {verifyMouse } from "../../functionsGlobal";
import { FinishDelivery } from "../../Class/finishDelivery";
import { login } from "../../Class/login";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css'


class Sacola extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            buttonDelivery:true,
            buttonWithdraw:false,
            tx_entrega:0.00,
            isLoadingPedido: false, 
            disabled : true
        }
    }

    componentDidMount() {
        this.notWithDraw();
        this.setState({tx_entrega: this.props.tx_entrega});
        entrar('WITHDRAW', false)
        this.props.alterarEstadoNomeFormaPagamento();
    }   

    componentDidUpdate (prevProps, prevState, snapshot) {
        if(prevState.buttonDelivery){
            if(prevProps.revalidadorNaoEntregamosAi !== prevState.disabled){
                this.setState({disabled: prevProps.revalidadorNaoEntregamosAi})
                if (prevProps.revalidadorNaoEntregamosAi) this.removeColorButtonBag();
                if (!prevProps.revalidadorNaoEntregamosAi) this.changingColorButtonBag();
            }

        }
    }

    changingColorButtonBag = () => {
        const element = document.getElementById('ButtonBag');

        if (element !== null){
            element.style.color= 'rgb(255, 255, 255)';
            element.style.background = 'rgb(197, 31, 42)';
        }

    }

    removeColorButtonBag = () => {
        const element = document.getElementById('ButtonBag');

        if (element !== null){
            element.style.color = 'black';
            element.style.background = 'rgb(238, 236, 236)';
        }  
    }

    fecharModal = (event) => {
        if ( event.target.id === this.props.idd) {  

            this.props.FecharModal()
            this.props.location.history.push('/')   
        }
    }

    fecharModalAdd = () => {
        this.props.location.history.push('/')
        this.props.FecharModal();
    }

    mostrarModalEndereco = async() => {
        try {
            const usuarioLogado = getToken('IDUSUARIO');
            const token = getToken('TOKEN');
            
            if (token !== '' || token !== null){

                if (usuarioLogado !== '' && usuarioLogado !== null){
                    this.props.alterandoEstadoEndereco();
                }
                else{
                    this.props.location.history.push('/Sacola/Login'); 
                    this.props.MostrarModalLogin();
                } 

            }else if ( this.props.NomeFormaPagamento === '' || this.props.NomeFormaPagamento === null ) return this.props.mostrarModalFormaPagamento();
            
        } catch (error) {
 
        }
    }

    mostrarAddProdutos = (nome, id) => {
        this.props.MudandoEstadoModalAdd()
        this.props.location.history.push(`/Sacola/AdicionarProduto/${id}/${nome}`)
    }

    removendoProdutosIndexDB = (id) =>{
        let db ;
        const request = indexedDB.open('cardapio', 2 );  
        request.onsuccess = () => {
            db = request.result;

            const transaction = db.transaction(["Produtos"], "readwrite")
            const objectStore = transaction.objectStore("Produtos")
            const result = objectStore.getKey(id)

            // caso a transação der Ok eu faço algo
            result.onsuccess = () => {
                if (result.result > 0){
                    objectStore.delete(result.result) 
                    const verificandoProdutos = objectStore.getAll()

                    verificandoProdutos.onsuccess = () => {
                       
                        if (verificandoProdutos.result.length === 0) {
                            this.fecharModalAdd();
                            this.props.alterandoTotalSacola();
                            this.props.alterandoQtdSacola();
                        }

                        this.props.verificandoQtdSacola();
     
                    }
                }
            }
        }
    }

    refatorandoArray = (value) => {
        const array = [];
        let tipo

        if (value.produtoAcompanhamento[0] !== undefined){
            value.produtoAcompanhamento.map(item => {
   
                tipo = item.tipo
                delete item.id
                delete item.nome
                delete item.qtd
                delete item.qtd2
                delete item.stipo
                delete item.cor
                delete item.tipo
    
       
                item.itens.map(item => {
    
                    delete  item.id
                    delete  item.fixo
                    delete  item.marcado
                    delete  item.id
                    // delete  item.id_produto_acompanhamento
                    delete  item.id_produto_grupo_acompanhamento
                    delete  item.nome
                    delete  item.descricao
                    delete  item.pedidoId
                    delete  item.updatedAt
                    delete  item.createdAt
                    delete  item.usuarioId  
    
                    
                    if (item.qtd_escolhida > 0 &&  (tipo === 'OB' || tipo === 'OP') ){
                        array.push(item)
                        
                    } 
                })
    
            })
            
            return array
        }
        else{
            return array 
        }
        
    }

    finalizarPedido = async() => {
        try {
            let statusLoja = this.props.parametros.map(item => item.status_cardapio);
            if (statusLoja.toString() === 'N') return alert('Estabelecimento fechado!')
            if (this.props.totalGeral <= 0) return alert('Sacola sem produto. Informa algum produto para finalizar!')

            const {buttonWithdraw} = this.state;
            const token = getToken('TOKEN');
            const cnpj = this.props.cnpj;
            const hoje = new Date();
            const dia = hoje.getDate().toString().padStart(2,'0');
            const mes = String(hoje.getMonth() + 1).padStart(2,'0');
            const ano = hoje.getFullYear();
            const data = `${dia}/${mes}/${ano}`;    
            const hora = hoje.toLocaleTimeString();

            let troco_para = getToken('VALORTROCO');
            const formapag = getToken('FORMAPAGAMENTO');
            const cliente = getToken('CLIENTE');
            const numero_web = getToken('NUMERO_WEB');
            const CPF_CNPJ = getToken('CPF_CNPJ');

            const FinishDelivery1 = new FinishDelivery();

            FinishDelivery1.pedidoMinimo = this.props.parametros[0].pedido_minimo;
            FinishDelivery1.subtotal = this.props.totalGeral;

            const verificarPedidoMinimo = FinishDelivery1.verificarPedidoMinimo();
            if (verificarPedidoMinimo.success === false) return alert(verificarPedidoMinimo.message);

            if ( this.props.NomeFormaPagamento === '' || this.props.NomeFormaPagamento === null) return this.props.mostrarModalFormaPagamento();
            
            if( token !== null) {   
                
                if ( this.props.enderecoEscolhido.length <= 0 && buttonWithdraw === false) {
                    this.props.mudandoEstadoEndereco();
                    return this.props.location.history.push('/Endereco');                    
                }

                FinishDelivery1.cnpj = cnpj;
                FinishDelivery1.data = data;
                FinishDelivery1.hora = hora;
                FinishDelivery1.produtos = this.props.produtosSacola;
                FinishDelivery1.RETIRAR_LOJA = buttonWithdraw ? 'S' :'N';
                FinishDelivery1.tx_entrega = buttonWithdraw === false ? parseFloat(this.props.tx_entrega) : 0.00;
                FinishDelivery1.numero_web = numero_web;
                FinishDelivery1.CPF_CNPJ = CPF_CNPJ;
                FinishDelivery1.total = buttonWithdraw === false? (parseFloat(this.props.totalGeral) + parseFloat(this.props.tx_entrega)) : parseFloat(this.props.totalGeral);
                FinishDelivery1.cliente = cliente;
                FinishDelivery1.formapag = formapag;
                FinishDelivery1.status = 'Aguardando o Estabelecimento Aceitar';
                FinishDelivery1.troco_para = troco_para;
                FinishDelivery1.enderecoEscolhido = this.props.enderecoEscolhido;
                FinishDelivery1.NomeFormaPagamento = this.props.NomeFormaPagamento;
                FinishDelivery1.enderedoEntrega = this.props.parametros;
                FinishDelivery1.sistefood = this.props.sistefood


                this.setState({disabled: true, isLoadingPedido:true});
                this.disabledButton();
                
                const Finish = new login();
                const result = await FinishDelivery1.salvarPedido1();



                if (result.success === true ) {
                    logout('WITHDRAW');
                    logout('FORMAPAGAMENTO');
                    logout('VALORTROCO');
                    logout('NOMEFORMAPAGAMENTO');
                    Finish.removendoProdutosIndexDB();
                    this.props.selecionandoProdutosAdd();
                    this.props.carregarPedidos();
                    this.setState({isLoadingPedido:false});
                    this.props.FecharModal();
                }
                else if (result.success === false){
                    this.setState({disabled: false, isLoadingPedido:false});
                    this.disablingButton();
                }
            }
            else{
                if ( this.props.enderecoEscolhido.length <= 0 ){
                    this.props.location.history.push('/Sacola/Login'); 
                    return this.props.MostrarModalLogin();
                } 
                if ( this.props.NomeFormaPagamento === '' || this.props.NomeFormaPagamento === null) return this.props.mostrarModalFormaPagamento();
            }

        } catch (error) {
            console.log(error)
            this.setState({isLoadingPedido: false, disabled: false});
            this.disablingButton();

        }
    }

    notWithDraw = () => {
        if (this.props.revalidadorNaoEntregamosAi){
            this.removeColorButtonBag();
            this.setState({disabled: true});
            this.props.alteraNaoEntregamosAi(true, 'Infelizmente não entregamos para sua localidade!')
        } 
    }

    buttonDelivery = () => {
        this.setState({buttonDelivery:true,buttonWithdraw:false, disabled: false});
        entrar('WITHDRAW', false);
        this.props.alterarEntrega(true);
        let token = getToken('TOKEN');


        if(token !== null){
            this.notWithDraw();
        }
        
    }

    buttonWithdraw = () => {

        this.changingColorButtonBag()
        this.setState({buttonWithdraw:true,buttonDelivery:false, disabled: false});
        entrar('WITHDRAW', true);
        this.props.alterarEntrega(false);
        
        this.props.alteraNaoEntregamosAi(false, '')
    }

    disablingButton = () => {
        const DivdAddress = document.getElementById('address');
        const DivLinkPayment = document.getElementById('linkpagamento');
        const DivMore = document.querySelectorAll('.ask-for-more-text-wrapper')[0];
        const DivPayment = document.querySelectorAll('.texto-pagamento-wrapper')[0];
        const DivButtonsWrapper = document.querySelectorAll('.buttons-wrapper')[0].childNodes;


        if(DivdAddress !== null) DivdAddress.disabled = false;
        DivPayment.disabled = false;
        DivLinkPayment.disabled = false;
        DivMore.disabled = false;

        DivButtonsWrapper[0].disabled = false;
        DivButtonsWrapper[1].disabled = false

        this.disablingUpdateProduct();
        this.disablingDeleteProduct();  
    }

    disablingUpdateProduct = () => {
        const DivUpdateProduct = document.querySelectorAll('.update-product');
        for(let index = 0; index < DivUpdateProduct.length; index++){
            DivUpdateProduct[index].disabled = false;
        }
    }

    disablingDeleteProduct = () => {
        const DivDeleteProduct = document.querySelectorAll('.delete-product');
        for(let index = 0; index < DivDeleteProduct.length; index++){
            DivDeleteProduct[index].disabled = false;
        }
    }

    disabledButton= () => {

        const DivdAddress = document.getElementById('address');
        const DivLinkPayment = document.getElementById('linkpagamento');
        const DivPayment = document.querySelectorAll('.texto-pagamento-wrapper')[0];
        const DivMore = document.querySelectorAll('.ask-for-more-text-wrapper')[0];
        const DivButtonsWrapper = document.querySelectorAll('.buttons-wrapper')[0].childNodes;

       
        if(DivdAddress !== null) DivdAddress.disabled = true;
        DivPayment.disabled = true;
        DivLinkPayment.disabled = true;
        DivMore.disabled = true;

        DivButtonsWrapper[0].disabled = true;
        DivButtonsWrapper[1].disabled = true

        this.disabledUpdateProduct();
        this.disabledDeleteProduct();
    }

    disabledUpdateProduct = () => {
        const DivUpdateProduct = document.querySelectorAll('.update-product');
        for(let index = 0; index < DivUpdateProduct.length; index++){
            DivUpdateProduct[index].disabled = true;
        }
    }

    disabledDeleteProduct = () => {
        const DivDeleteProduct = document.querySelectorAll('.delete-product');
        for(let index = 0; index < DivDeleteProduct.length; index++){
            DivDeleteProduct[index].disabled = true;
        }
    }

    render(){
        const{
                buttonDelivery,
                buttonWithdraw, 
                isLoadingPedido,
                disabled
            } = this.state;

        return(
            <>
                <BoxModal id={this.props.idd} onMouseDown={(event) => verifyMouse(event, this.fecharModal)}
                    onMouseUp={(event) => verifyMouse(event, this.fecharModal)}
                >
                    <BoxContainer  >
                        <BoxHeader >     
                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.fecharModalAdd()} /> 
                            <h1 onClick={() => this.disablingButton()}>Sacola</h1>
                        </BoxHeader>

                        <BoxAddProduto >
                            {
                                this.props.produtosSacola.length > 0 ? (
                                    this.props.produtosSacola.map((item, index) => { 
                                        
                                        return(
                                            <BoxSacolaProdutos  key={index}>
                                                <div className="fotoProduto">
                                                    <BoxQtdProdutosSacola>
                                                        {item.qtd}
                                                    </BoxQtdProdutosSacola>
                                                    {/* <BoxFoto  src={`${this.props.foto}/${item.foto_descricao}`}  /> */}

                                                    <BoxFoto>
                                                        <LazyLoadImage
                                                            width={'100%'} 
                                                            height={'100%'}
                                                            src={`${this.props.foto}${item.foto_descricao}`}
                                                            // src={`https://www.sistefood.com.br/img/19651715000132/${item.foto_descricao}`}
                                                            alt={item.foto_descricao}
                                                            effect={'blur'}
                                                        />

                                                    </BoxFoto>
                                                </div>

                                                <BoxInfoProdutos>
                                                    <div>
                                                        {item.nome }
                                                    </div>

                                                    <div className="total">
                                                        {parseFloat(item.total).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}
                                                    </div>
                                                </BoxInfoProdutos>

                                                <BoxAcoes>
                                                    <button className="update-product">
                                                        <BoxIcone src={Editar}   onClick={() => this.mostrarAddProdutos(item.link_web, item.id_produto_key)} />
                                                    </button>

                                                    <button className="delete-product">
                                                        <BoxIcone src={Exluir} onClick={() => this.removendoProdutosIndexDB(item.id_produto_key)}/>
                                                    </button>
                                                    
                                                </BoxAcoes>
                                            </BoxSacolaProdutos>
                                        )    
                                    })
                                )
                                :(
                                    null
                                )
                            }

                            <DeliveryOptionsWrapper>

                                <DeliveryAskForMore>
                                    <button className="ask-for-more-text-wrapper" onClick={() => this.fecharModalAdd()}>
                                        + Adicionar mais itens
                                    </button>
                                </DeliveryAskForMore>


                                <DeliveryOptionsHeader>
                                    <div className="end-order-text-wrapper">
                                        <p>Finalize seu pedido!</p> 
                                    </div>
                                </DeliveryOptionsHeader>

                                <div className="buttons-wrapper">
                                    <DeliveryOptionsButton activeButton={buttonDelivery} onClick={()=> this.buttonDelivery()}>
                                        <div className="icon-button">
                                            <img src={buttonDelivery ? iconButtonDeliveryActive : iconButtonDelivery } alt="imagem de um capacete simbolizando a entrega"/>
                                        </div>
                                        <div className="text-delivery-wrapper">
                                            <p className="text-delivery">Entrega</p>
                                        </div>
                                    </DeliveryOptionsButton>
                                    
                                    <DeliveryOptionsButton activeButton={buttonWithdraw} onClick={()=> this.buttonWithdraw()}>
                                        <div className="icon-button">
                                            <img src={buttonWithdraw ? iconButtonWithdrawActive: iconButtonWithdraw} alt="imagem de uma lojinha simbolizando a retirada na loja"/>
                                        </div>
                                        <div className="text-delivery-wrapper">
                                            <p className="text-withdraw">Retirada</p>
                                        </div>
                                    </DeliveryOptionsButton>
                                </div>
                            </DeliveryOptionsWrapper> 
                            <BoxEndereco>
                                {
                                    buttonWithdraw ?(
                                        <>
                                            <div className="em-construçao">
                                                {this.props.parametros.map(item => `${item.site_logradouro}, ${item.site_numero} - ${item.site_bairro}, ${item.site_cidade}`)}
                                            </div>
                                        </>
                                    )
                                    :(
                                        this.props.enderecoEscolhido.length > 0 ? (
                                            this.props.enderecoEscolhido.map(item => 
                                                <button key={item.id} id='address' onClick={() => this.mostrarModalEndereco()}>
                                                    
                                                    <div className="texto-endereço-wrapper"  >
                                                        <div id='textoendereco'>
                                                            {`${item.rua}, ${item.numero} - ${item.bairro}, ${item.cidade} `}
                                                        </div>
                                                    </div> 
                                                    
                                                    <div id='linkendereco'>
                                                        <p id='informar' >
                                                            {'>'}
                                                            
                                                        </p>
                                                    </div>  
                                                </button>
                                            )
                                        )
                                        :(
                                            <button  id='address' onClick={() => this.mostrarModalEndereco()}>
                                                <div className="texto-endereço-wrapper">
                                                    <div id='textoendereco'> 
                                                        <p>Endereço de entrega</p>
                                                    </div>
                                                    <div id='texto-observação-wrapper'> 
                                                        <p className="texto-observação">*Obrigatorio</p>
                                                    </div>
                                                </div>
    
                                                <div id='linkendereco'>
                                                
                                                    <p id='informar' >
                                                        informar
                                                    </p>
                                                </div>
                                            </button>
                                        )
                                    )
                                }
                            </BoxEndereco>
                            <WrapperBoxTotais>
                                <BoxTotais activeWithdraw={buttonWithdraw}>
                                    {
                                        <>
                                            <div id='subtotal'>
                                                <p>
                                                    Sub Total
                                                </p>

                                                <p>
                                                    {parseFloat(this.props.totalGeral).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}
                                                </p>
                                            </div>

                                            <div id='frete'>
                                                <p>
                                                    Taxa de entrega:
                                                </p>

                                                <p className="total-delivery">
                                                   
                                                    {buttonWithdraw ? "*Grátis" :  parseFloat(this.props.tx_entrega).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}
                                                </p>
                                            </div>

                                            <div id='total-text'>
                                                <p>
                                                    Total:
                                                </p>

                                                <p>
                                                    {parseFloat(`${buttonWithdraw === false ? this.props.tx_entrega + this.props.totalGeral  : this.props.totalGeral } `).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}
                                                </p>
                                            </div>
                                        </>
                                    }
                                </BoxTotais>
                            </WrapperBoxTotais>
                            <BoxFormaPagamento>
                                {
                                    this.props.NomeFormaPagamento !== '' && this.props.NomeFormaPagamento !== null ?(
                                        <>

                                            <button className="texto-pagamento-wrapper" onClick={() => this.props.mostrarModalFormaPagamento()}>
                                                <div className='textopagamento'> 
                                                    <p>{this.props.NomeFormaPagamento}</p>
                                                </div>
                                            
                                            </button>
            
                                            <button id='linkpagamento' onClick={() => this.props.mostrarModalFormaPagamento()}>
                                                <p id='informar' >
                                                    {'>'}
                                                </p>
                                            </button>
                                        </>
                                    ) 
                                    :(
                                        <>
                                            <button className="texto-pagamento-wrapper" onClick={() => this.props.mostrarModalFormaPagamento()}>
                                                <div id='textopagamento'> 
                                                    <p>Forma de pagamento</p>
                                                </div>
                                                <p className="texto-obrigatorio">*Obrigatorio</p>
                                            </button>
            
                                            <button id='linkpagamento'>
                                                <p id='informar' onClick={() => this.props.mostrarModalFormaPagamento()}>
                                                    informar
                                                </p>
                                            </button>
                                        </>
                                    )
                                }
                         
                            </BoxFormaPagamento>
                                
                            {
                                this.props.message !== '' ? (
                                    <BoxMessage>
                                        <this.props.Messagem message={this.props.message}/>
                                    </BoxMessage>
                                )
                                :(
                                    null
                                )
                            }
     
                            <BoxQtdProduto>
                                <BoxBotaototal naoEntregamosAi={disabled}>
                                    <button disabled={disabled}  onClick={() => this.finalizarPedido()} id='ButtonBag'>
                                        Finalizar Pedido

                                        {
                                            isLoadingPedido? (  
                                                <Spinner animation="border"/>
                                            )
                                            :(
                                                null
                                            )
                                       }
                                    </button>
                                </BoxBotaototal>
                            </BoxQtdProduto>
                        </BoxAddProduto>
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default Sacola
