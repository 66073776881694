import React from "react";
import 
{
    BoxLogin, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxModal,
    BoxImageHeader,
    BoxSuportInfo,
    Footer,
} from './style' 
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import imageHelp from '../../assets/ImageSupport/logoHelp.svg'
import imageFooter from '../../assets/ImageFooter/imageFooter.svg'
import { verifyMouse } from "../../functionsGlobal";

class SupportScreen extends React.Component {

    FecharModalLogin = (event) => {
        if (event.target.id === this.props.idd) {
            this.VoltarTelaAnterior();
        }
    }

    VoltarTelaAnterior = () => {
        this.props.fecharEstadoSuporte()
        this.props.location.history.goBack();    
    }

    render() {
        return(
            <>
                <BoxModal id={this.props.idd} onMouseDown={(e) => verifyMouse(e, this.FecharModalLogin)}
                    onMouseUp={(e) => verifyMouse(e, this.FecharModalLogin)}
                >
                    <BoxContainer>
                        <BoxHeader>
                            <BoxIconeVoltar src={IconeVoltar}  onClick={() => this.VoltarTelaAnterior()} />
                            <h1>Suporte</h1>
                        </BoxHeader> 

                        <BoxLogin>
                             <BoxImageHeader>
                                 <img src={imageHelp} alt="imagem do mascote da sistefood"/>
                             </BoxImageHeader>
                            <div className="login-text-wrapper">
                                <p>Em que posso ajudar?</p>
                            </div>
                            <BoxSuportInfo>
                                <div className="logo-image-wrapper">
                                    <img src={this.props.parametros[0].site_url_logo} alt="pequena imagem da logo da empresa"/>
                                </div>
                                <h4>Ligue para o número</h4>
                                <p>{this.props.parametros[0].site_telefone}</p>
                            </BoxSuportInfo>
                            <div className="info-wrapper">
                                <p>*Esse pedido será realizado e entregue por: <strong>{this.props.parametros[0].site_nome_empresa}</strong></p>
                            </div>
                            <Footer>
                                <img src={imageFooter} alt="imagem de rodape sistefood"/>
                            </Footer>
                        </BoxLogin>
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default SupportScreen;