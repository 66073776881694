import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.text.White_1};
  position: fixed;
  bottom: 75px;
  z-index: 100;
`;

export const InfoBox = styled.div`
  width: 100%;
  margin: 0 40px;
  height: 67px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${(props) => (props.totalItemsBag > 0 ? "pointer" : "Default")};
`;

export const InfoBagBoxWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  padding: 14px 20px;
  color: ${(props) => props.theme.text.White_1};
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  background-color: ${(props) => props.changeBackground};

  .qtd-items-bag {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const InfoBagBoxTextWrapper = styled.div`
  width: 100px;
  height: 28px;
  margin-left: 30px;
  display: flex;
  align-items: center;

  .wrapper-1 {
    width: 100%;
    height: 16px;
    display: flex;
    justify-content: center;

    .phrase-1,
    .phrase-2,
    .phrase-3 {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    .phrase-2 {
      text-decoration: underline;
      margin-right: 4px;
    }
  }
`;

export const InfoBagBoxTotalValuesItems = styled.div`
  width: 67px;
  height: 16px;
  display: flex;
  justify-content: right;
  align-items: center;

  .total-values-items {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
  }
`;

export const InfoBagBoxBubbleForm = styled.div`
  height: 11px;
  display: flex;
  align-items: initial;
`;

export const BoxQtdProdutosSacola = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${(props) => props.changeBackground};
  border-radius: 50%;

  .items-in-bag {
    font-weight: 700;
    font-size: 12px;
    line-height: 8px;
    color: ${(props) => props.theme.text.White_1};
  }
`;

export const BoxCountBagItems = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
