import styled from "styled-components";

export const BoxContainer = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    max-width: 520px;
    flex-grow: 0;
    margin-top: 2em;

`;

export const BoxMainProductComplement = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    flex-grow: 0;

    
`;

export const BoxProductComplementHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    width: 100%;    
    background: #E6E3E3;
    align-self: stretch;
    flex-grow: 0;

    @media (width: 280px){
        flex-wrap: wrap;
    }
`;

export const BoxComplementProductGroup= styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 200px;
    height: 36px;
    flex-grow: 0;
    font-style: normal;

    .nameGroup{
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #2F2F2F;
    }

    .nameGroupQtd{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: ${props => props.cor ? '#C51F2A' : '#2F2F2F'};
    } 
`;

export const BoxStatusGroup = styled.div`    
    display: flex;
    justify-content: center;    
    align-items: flex-start;
    padding: 6px 12px;
    gap: 10px;
    width: 85px;
    height: 26px;
    background-color:  ${props => props.cor === false? props.statusGroup === 'OB'? '#2F2F2F' : '#C2BCBD' : '#C51F2A'};
    border-radius: 8px;

    p{
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: ${props => props.statusGroup === 'OB'? '#FFFFFF' : '#2F2F2F'};
    }

`;
export const BoxWrapperStatusGroup = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0px;
    gap: 8px;
    width: 149px;
    height: 26px;
    flex-grow: 0;
`;

export const BoxOK = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    width: 20px;
    height: 20px;
`;

export const BoxGroupQtd = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 10px;
    width: 56px;
    height: 26px;
    background: #2F2F2F;
    background: ${props => props.cor === false? '#2F2F2F' : '#C51F2A'};
    border-radius: 8px;
    flex-grow: 0;

    p{
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #FFFFFF;
    }
`;

export const BoxContainerItems = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 24px;
    gap: 151px;
    max-width: 520px;
    height: 52px;
    background: #F8F5F5;
    align-self: stretch;
    flex-grow: 0;
`;

export const BoxWrapperItems = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    width: 355px;
    height: 52px;
    border-bottom: 1px solid #E6E3E3;
    align-self: stretch;
    flex-grow: 1;
`;


export const BoxWrapperItemsAdd = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px;
    gap: 24px;
    width: 112px;
    height: 24px;
    flex-grow: 0;
    
`;


export const itemsqtd = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    gap: 10px;
    width: 32px;
    height: 24px;
    flex-grow: 0;


    p{
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #2F2F2F;
    }

`;

export const BoxProductName = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: auto ;
    height: 16px;
    flex-grow: 0;


    p{
        
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #2F2F2F;
    }
`;

export const BoxProductmore = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    button{
        outline: none;
        border: none;
        background-color: transparent;
        width: 100%;
        height: 100%;
    }

`;

export const BoxProductRadio = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D9D9D9;
    cursor: pointer;
    border-radius: 100%;  
    border: ${props => props.off ? '7px solid red' : '7px solid #D9D9D9'};
    
    input{
        all:unset;
        border-radius: 100%;
        height: 5px;
        width: 5px;
        color: red;
    }
    
`;

export const BoxWrapperProductRadio = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: center;
    padding: 16px 0px;
    width: 100%;
    height: 100%;
    flex-grow: 0;
    cursor:${props => props.pointer? 'pointer' : 'auto'};
`;
