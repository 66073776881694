import baseApi from "./api";
import baseURL from "../configs/baseURL";

export class SearchGoogleMaps {
    constructor(){
        this.api         = baseApi(baseURL.API_GOOGLE_MAPS);
        this.apiDistance = baseApi(baseURL.API_GOOGLE_MAPS_DISTANCEMATRIX);
    }


    async getOneAddress (coord) {
        const result = await this.api.get(`/json?latlng=${coord.latitude},${coord.longitude}&key=${'AIzaSyD5DhPh8K357MLJUPf6kVmxIYvJ-woOM9U'}`);
        return result.data
    }

    async getOneDistace (origen, destination) {
        const result = await this.apiDistance.get(`/distancia/${origen}/${destination}`)
        return result.data
    }

}
