
import React from 'react'

import 
{
    BoxSemLogin, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxBotaoFotter,
    BoxModal,
    SignUpQuoteBox,
} from './style' 
import { Form, Row, Alert } from 'react-bootstrap';
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import UsuarioService from '../../service/cadastrarUsuario';
import { entrar, getToken, logout } from '../../service/auth';
import {VisiblePassword} from '../../components/VisiblePassword'
import { verifyMouse } from '../../functionsGlobal';

class ForgotPassword extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            nome: "",
            cpf_cnpj:"",
            email:"",
            telefone:"",
            senha:"",
            newPassword:"",
            error:"",
            backSpace:"vazio",
            mudandoCorBotao :false,
            PrimaryKeyWord: "",
            DisablingSubmitButton: true,
            password:false,
        }
    }
    
    async componentDidMount() {

    }

    HandleCadastrar = async(event) => {
        event.preventDefault();
        this.teste();
    }

    mudandoCorBotao = () => {
        // const  {email,newPassword,PrimaryKeyWord} = this.state;
    
        if (this.state.email === '' || this.state.newPassword === '' || this.state.PrimaryKeyWord === ''){
            this.setState({mudandoCorBotao: false});
        }
        else{
            this.setState({mudandoCorBotao: true});
            this.setState({DisablingSubmitButton: false})
        }        
    }

     teste = async () =>{
       
            try {
                const {email, newPassword, PrimaryKeyWord} = this.state;
    
                 if (!email || !newPassword || !PrimaryKeyWord) {
                    this.setState({error:"Preencha os campos email e senha para fazer a alteração!"});  
                }
                else{
                    const service = new UsuarioService();
                    const result = await service.ForgotPassword({
                        email: email.trim(),
                        banco:this.props.banco_cardapio
                    });
                    if ( result.status === 200 ) {
                        entrar('TOKEN', result.data.token);
                        const dbSecurityQuestionParams= result.data.telefone.slice(7)
                        if(dbSecurityQuestionParams === this.state.PrimaryKeyWord.toString()){
                            if(newPassword.length === 6){
                                const UpdatePassword = await service.updateOne({
                                    nome: result.data.nome,
                                    cpf_cnpj:result.data.cpf_cnpj,
                                    telefone: result.data.telefone,
                                    email,
                                    senha: newPassword,
                                    banco:this.props.banco_cardapio
                                }, result.data.idUsuario)                   
        
                                if(UpdatePassword.status === 200 ){
                                    this.setState({error:"Senha alterada com sucesso!"});
                                    logout('TOKEN');
                                    this.props.location.history.goBack()
                                }     
                            }else{
                                this.setState({error: 'Senha menor que seis caracteres'});
                            }
                        }else{
                            this.setState({error:"Resposta de segurança incorreta"});
                        }

                    }else{
                        this.setState({error: "Erro ao tentar alterar a senha"});
                    }
                }
    
            } catch (error) {
                if (error.response.status === 401 || error.response.status === 422) {
                    this.setState({error:"E-mail ou senha esta incorreto(s)"});
                }
                else if (error.response.status === 404 &&  error.response.data.success === false ){
                    this.setState({error: error.response.data.message});
                }
                // this.setState({isNotLoadingLogin: true});
            }
    }

    VoltarTelaAnterior = () => {
        this.props.FecharModalEsqueciSenha();
        console.log("ativou")
        this.props.location.history.goBack();
    }

    FecharModal = (event) => {
        if (event.target.id === this.props.idd) {
            this.VoltarTelaAnterior();
        }
    }

    Messagem = () => {
        const {error} = this.state;
        return(
            <Alert variant='warning'>
                {error}
            </Alert>
        )
    }

    ValidarEmail = (e) => {
        const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        return validEmail.test(e);
    }

    onChangeEmail = (e) => {
        let value = e.trim();
        const result = value !== '' ? this.ValidarEmail(value) : '';
        if (result === false) {
            this.setState({error: 'email inválido', email:value});
        }
        else{
            this.setState({error: ''});
            this.setState({email: value});
        }
        this.mudandoCorBotao();
    } 

    changeNome = (value) => {
        this.setState({nome: value});
        this.mudandoCorBotao();
    }

    changeCrieSenha = (value) => {
        this.setState({newPassword: (value).trim()});
        this.mudandoCorBotao();
    }

    changePrimaryKey = (e) => {
        let valor = e.trim()
        // valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor.replace('^[0-9]+$')

        if( isNaN(valor) ) {
            e= ''
            this.setState({PrimaryKeyWord:  e}) ;   
        }
        else {
            e= valor;
            this.setState({PrimaryKeyWord:  e}) ;
        }
        this.setState({PrimaryKeyWord: valor})
        if (isNaN(valor))  this.setState({PrimaryKeyWord: ''})
    
        this.setState({PrimaryKeyWord: e});
    }

    render(){
        const {error, mudandoCorBotao, DisablingSubmitButton, password } = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onMouseDown={(e) => verifyMouse(e, this.FecharModal)}
                    onMouseUp={(e) => verifyMouse(e, this.FecharModal)}
                >
                    <BoxContainer>
                            <BoxHeader>
                                <BoxIconeVoltar src={IconeVoltar} onClick={() => this.VoltarTelaAnterior()}  />
                                <h1>Esqueci a Senha</h1>
                            </BoxHeader>
                            <BoxSemLogin>
                                <SignUpQuoteBox>
                                    <div>
                                        <h1>Esqueceu a Senha?</h1>
                                    </div>
                                    <div>
                                        <p>Preencha os campos para alteração de senha ; {')'}</p>
                                    </div>
                                </SignUpQuoteBox>
                                <Row>
                                    <Form autoComplete={"off"} onSubmit={this.HandleCadastrar}>

                                    <p className="input-text-email">Digite seu E-mail*</p>
                                    <input
                                        type="text"
                                        autoCapitalize='none'
                                        maxLength={150}
                                        onChange={(e) => this.onChangeEmail(e.target.value)}
                                        value={this.state.email}
                                    />

                                    <p className="input-text-nome">Digitar os quatro ultimos números do celular</p>
                                    <input
                                        maxLength={4}
                                        inputmode="numeric"
                                        onChange={(e) => this.changePrimaryKey(e.target.value)}
                                        value={this.state.PrimaryKeyWord}
                                    />

                                    <p className="input-text-password">Insira a nova senha*</p>
                                    <div className="text-wrapper">
                                        <p className="password-text">Máximo de seis dígitos</p>
                                    </div>
                                    <div className='wrapper-passaword'>
                                        <input 
                                            className="senha"
                                            type={password ? 'text' : 'password'}
                                            maxLength={6}
                                            onChange={(e) => this.changeCrieSenha(e.target.value)}
                                            value={this.state.newPassword}
                                        />

                                        {
                                             this.props.visiblePassword && this.state.newPassword !=='' ? (
                                                <VisiblePassword genericFunction={() => this.setState({password:!password})}/>
                                            )
                                            :(
                                                null
                                            )
                                        }
                                    </div>
                                    {
                                        error !== '' ? (
                                            <Row id='error'>
                                                {<this.Messagem error={error} />}
                                            </Row>    
                                        )
                                        :(
                                            null
                                        )
                                    }
                                    <BoxBotaoFotter mudandoCorBotao={mudandoCorBotao}>
                                        <button type="submit" disabled={DisablingSubmitButton}>
                                            Cadastrar
                                        </button>
                                    </BoxBotaoFotter>
                                    </Form>
                                </Row>
                            </BoxSemLogin>
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default ForgotPassword