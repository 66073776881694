import { Row } from "react-bootstrap"


export const Category = (props) => {

    return(
        <div className="itens-categoria-wrapper" >
            <Row id='categoria'>
                {
                    props.category.map((item) => {
                        return(
                            
                            item.map((item) => {
                                return(

                                    <button key={item.id} id = {`buttons${item.id}`} className='button' 
                                        onClick={ () => props.getCoordenate(item) }
                                    >
                                        {item.nome}
                                    </button>

                                )
                            })
                        )
                    })
                } 

            </Row>

        </div>
    )
}