import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  background: ${(props) => props.theme.background.Primary};
  max-width: 520px;
  width: 100%;
  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background: ${(props) => props.theme.background.Primary};
  height: 52px;
  border-bottom: solid 1px ${(props) => props.theme.colors.Grey_2};

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${(props) => props.theme.text.Black_1};
  }
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxIconeVoltar = styled.img`
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;

export const SignUpQuoteBox = styled.div`
  width: 100%;
  height: 146px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 32px;
  margin-bottom: 24px; */
  margin:32px 0 24px 0;
  padding: 10px 32px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;

  h1 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  /* p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
  } */
`;

export const BoxSemLogin = styled.div`
  padding: 0px 32px;
  display: flex;
  overflow-x: auto;
  display: flex;
  width: 100%;
  max-width: 520px;
  flex-direction: column;
  padding-bottom: 1em;
  height: 100%;

  .wrapper-passaword{
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom:16px;
    margin-top:8px;
  }

  .password-text{
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: ${props=>props.theme.text.Primary};
    margin-right: 14px;
    margin-bottom: 8px;
  }

  #error {
    justify-content: center;
    text-align: center;
  }

  .email {
    background: ${(props) => props.theme.background.White_2};
  }

  .row {
    margin: 0;
    padding: 0;
    width: 100%;

    .input-text-password,
    .input-text-email,
    .input-text-nome {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: ${(props) => props.theme.text.Black_1};
    }

    form {
      width: 100%;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }

    input {
      color: ${(props) => props.theme.text.Black_1};
      height: 48px;
      width: 100%;
      border-radius: 10px;
      border: solid 1px ${(props) => props.theme.colors.Grey_3};
      font-size: 14px;
      line-height: 12px;
      font-weight: 400;
      background: ${(props) => props.theme.background.White_2};
      outline: none;
      margin-bottom:16px;
      margin-top:8px;
      padding: 15px 12px;
      :focus {
        border: 2px solid black;
      }
    }

    .senha{
      margin-bottom:0;
      margin-top:0;
    }
  }

  @media (min-width: 320px) and (max-width: 520px) {
    width: 100%;

    .row {
      input {
        font-size: 16px;
      }
    }
  }
`;

export const BoxBotaoFotter = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 48px;
  margin-top: 10px;
  flex-direction: column;
  margin-top: 26px;
  button {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    outline: none;
    border: none;
    box-shadow: 0 0 0 0;
    background: ${(props) =>
      props.mudandoCorBotao
        ? props.theme.background.Black_1
        : props.theme.background.Grey_2};
    color: ${(props) => props.theme.text.White_1};
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;
