import styled from "styled-components";

export const IconPassword = styled.img`
    cursor: pointer;
    width: 20px;
    height: 20px;

`;

export const Container = styled.div`
    display: flex;
    height: 100%;
    position: absolute;
    right: 12px;
    align-items: center;
    bottom: 0;
    top: 0;
    
`;