import React from "react";
import {Form} from 'react-bootstrap';

import 
{
    BoxAddProduto, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxFoto,
    BoxObs,
    BoxQtdProduto,
    BoxQtdMais,
    BoxQtdMenos,
    BoxInput,
    BoxQtdGeral,
    BoxAddSacola,
    BoxBotaototal,
    BoxModal

} from './style' 

import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import { ProductComplement } from "../../components/ProductComplement";
import { verifyMouse } from "../../functionsGlobal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css'

class AddProdutos extends React.Component{
    constructor(props){
        super(props)

        this.state ={
            produto:[],
            disabled: false,
            refactorComplement:[]
        }
    }


    componentDidMount (){
        this.CarregandoProdutoParaEdicao();
    }

    updateTotalProduct = (value) => {
        const {produto} = this.state;
  
        produto[0].totalComplemento = produto[0].totalComplemento  + (value) ;
        let resultComplement = produto[0].totalComplemento * produto[0].qtd
        let totalProduct =  produto[0].precoUnitario * produto[0].qtd;
        let totalChecked = produto[0].totalChecked * produto[0].qtd;
        totalProduct = totalProduct + resultComplement + totalChecked;
        produto[0].total = totalProduct;

        this.setState({produto: produto})
    }

    updateTotalProductRadio = () => {
        const {refactorComplement, produto} = this.state;

        const arrayTemp = [];
        const arrayTempItemsChecked = [];

        refactorComplement.filter((item) => {
            if(item.qtd === 1 && item.tipo === 'OB'){
                return arrayTemp.push(item);
            }
        })

        arrayTemp.filter((item) => 
            item.itens.filter((item) => {
                if (item.marcado === 'S') {
                   return arrayTempItemsChecked.push(item)
                }
            })
        )
    
        let totalChecked =  this.TotalRadio(arrayTempItemsChecked)
        produto[0].totalChecked = totalChecked;
        this.updateTotalProduct(0)
    }   
     
    TotalRadio = (array) => {
        if(array.length === 0) return 0.00;
        const result = array.map(item => item.preco).reduce((item1, item2) => (item1 + item2));
    
        return result;
    }

    verifyMandatoryComplement = (array) =>  {
        let id = array[0].id;
        const element = document.getElementById(`productComplement${id}`);
        const indexArray = element.tabIndex
        this.updateComplementProductColor(true ,indexArray)
        element.focus();
    }

    CarregandoProdutoParaEdicao =  () => {
        const location = this.props.location;
        const rota = location.match.path;
        const {produtoAdd} = this.props;

        if (rota === "/Sacola/AdicionarProduto/:id/:nome") {
            this.getProdutoIndexDB();
        }
        else{
            const arrayTemp = produtoAdd
            arrayTemp[0]["totalChecked"] = 0;
            if (arrayTemp[0].preco_venda > 0 && arrayTemp[0].oferta_web ==='S') {
                arrayTemp[0].precoUnitario = arrayTemp[0].preco_comparativo_web;
                arrayTemp[0].total = arrayTemp[0].preco_comparativo_web * arrayTemp[0].qtd; 
            } 
            else{
                arrayTemp[0].precoUnitario = arrayTemp[0].preco_venda;
                arrayTemp[0].total = arrayTemp[0].preco_venda * arrayTemp[0].qtd;
            }

            this.setState({produto: arrayTemp, refactorComplement:arrayTemp[0].complemento});
        }
    }

    getProdutoIndexDB = () => {
        const {params:{id}} = this.props.location.match; 

        let db ;
        
        const request = indexedDB.open('cardapio', 2 );  

        request.onsuccess =  () => {
            db = request.result;
            const transaction = db.transaction(["Produtos"], "readwrite")
            const objectStore = transaction.objectStore("Produtos")
            const result =  objectStore.get(parseInt(id))
      
            result.onsuccess =  () => {
                this.setState({
                    produto: [result.result],
                    refactorComplement:result.result.complemento
                })
            }
        }
    }

    changeQtdMenos = (value)  => {

        const{produto} = this.state;
        const arrayTemp = [...produto]

        if (arrayTemp.length > 0) {
            let valor = value - 1;
            
            let total =  ((arrayTemp[0].precoUnitario + arrayTemp[0].totalComplemento + arrayTemp[0].totalChecked) * valor) ;


            if (valor < 1) {
                valor = 1
                total = ((arrayTemp[0].precoUnitario + arrayTemp[0].totalComplemento + arrayTemp[0].totalChecked) * valor) 
            }
            arrayTemp[0].qtd = valor;
            arrayTemp[0].total = total;

            this.setState({
                produto: arrayTemp,
            })

        }
    }

    changeQtdMais = (value)  => {
        const{produto} = this.state;
        const arrayTemp = [...produto]
        if (arrayTemp.length > 0) {

            let valor = value + 1;
            let total =  ((arrayTemp[0].precoUnitario + arrayTemp[0].totalComplemento + arrayTemp[0].totalChecked) * valor) ;

            arrayTemp[0].qtd = valor;
            arrayTemp[0].total = total;         
            

            if (valor <= 100) {
                this.setState({

                    produto: arrayTemp,
                })
            }
        }
 
    }

    gravarBancoOuLocal = async() => {
        const {produto} = this.state;
        let statusLoja = this.props.parametro.map(item => item.status_cardapio);

        if (statusLoja.toString() === 'S') {
            if (produto[0].total > 0 ) {
                this.gravandoIndexDB();
            }
        }
        else{
            alert('Estabelecimento fechado!')
        }
   
    }

    gravandoIndexDB = () => {  
        const {refactorComplement} = this.state;
        const location = this.props.location ;

        const rota = location.match.path;
        const validateProductComplement = refactorComplement.filter(item => item.qtd2 < item.qtd && item.tipo === 'OB' ? item.id : 0 );

        if (validateProductComplement.length === 0) {
            if (rota === "/Sacola/AdicionarProduto/:id/:nome") {
                this.atualizandoValor();
            }   
            else{
                this.addBancoLocal();

            }
            this.props.totalSacola();
            this.voltarProdutos() ;
        }
        else{
            this.verifyMandatoryComplement(validateProductComplement);
        }
    }

    addBancoLocal = () => {
        let db;
        const request = indexedDB.open('cardapio', 2 );  
        const {produto} = this.state;

        request.onsuccess =  () => {

            db = request.result;   

            const transaction = db.transaction(["Produtos"], "readwrite");
            const objectStore =  transaction.objectStore("Produtos")
            produto.map(item => objectStore.add(item))     
            db.close();
        }

    }
    
    verificandoSeExiste = () => {
        let db;
        let request = indexedDB.open('cardapio', 2 );  
        const {produto} = this.state;

        request.onsuccess =  () => {
            db = request.result;   
            const transaction = db.transaction(["Produtos"], "readwrite");
            const objectStore =  transaction.objectStore("Produtos")
            produto.map(item => objectStore.put(item))           

            db.close();
        }
    }

    atualizandoValor = () => {
        this.verificandoSeExiste()
    }

    fecharModalAdd = (e) => {
        
        if (this.props.idd === e.target.id) {
            this.props.FecharModal();
        }
    }

    voltarProdutos = () => {
        this.props.FecharModal();
    }

    handleObs = (event) => {    
        const {produto} = this.state;
        produto[0].obs = event;
        this.setState({produto:produto})
    }

    updateComplementProductColor = (value, index) => {

        const {refactorComplement} = this.state;
        const arrayTemp = [...refactorComplement]
        arrayTemp[index].cor = value;
        this.setState({refactorComplement : arrayTemp})
    }

    updateComplementProductItemsRadio = (value, index, itemIndex) => {
        const {refactorComplement} = this.state;
        
        const arrayTemp = [...refactorComplement]

        for (let index = 0; index < arrayTemp[itemIndex].itens.length; index++) {
            arrayTemp[itemIndex].itens[index].marcado =  'N';
        }
        arrayTemp[itemIndex].qtd2 = value === 'S' ?  +1 : 0;
        arrayTemp[itemIndex].itens[index].marcado = value ;  
        arrayTemp[itemIndex].itens[index].qtd_escolhida = arrayTemp[itemIndex].qtd2

        
        this.setState({refactorComplement: arrayTemp})

      
        this.updateComplementProductColor(false ,itemIndex)   
    }

    updateComplementProductItemsQtsMore = (value, index, itemIndex) => {
        const {refactorComplement} = this.state;

        const arrayTemp = [...refactorComplement]
        if (arrayTemp[itemIndex].qtd > parseInt(arrayTemp[itemIndex].qtd2)) {

            arrayTemp[itemIndex].itens[index].qtd_escolhida = value + 1;
            arrayTemp[itemIndex].qtd2 = parseInt(arrayTemp[itemIndex].qtd2)  + 1;   
            this.updateComplementProductColor(false ,itemIndex)   

            this.setState({refactorComplement: arrayTemp})
        }
    }

    updateComplementProductItemsQtsAnyLess = (value, index, itemIndex) => {
        const {refactorComplement} = this.state;
        const arrayTemp = [...refactorComplement]

        if (arrayTemp[itemIndex].itens[index].qtd_escolhida > 0) {
            arrayTemp[itemIndex].itens[index].qtd_escolhida = value - 1;
            arrayTemp[itemIndex].qtd2 = arrayTemp[itemIndex].qtd2 > 0 ? arrayTemp[itemIndex].qtd2 - 1 : 0;
            this.setState({refactorComplement: arrayTemp})
        }
    }

    render() {
        const {produto, disabled, refactorComplement} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onMouseDown={(e) => verifyMouse(e ,this.fecharModalAdd) } 
                    onMouseUp={(e) => verifyMouse(e, this.fecharModalAdd)}
                >
                        <BoxContainer >
                            <BoxHeader>
                                <BoxIconeVoltar src={IconeVoltar}  onClick={() => this.voltarProdutos()}/>
                                <h1>Adicionar Item</h1>
                            </BoxHeader>

                            <BoxAddProduto>
                                      
                                <div className="image-wrapper">  
                                    {                           
                                        produto.length > 0 ? (         
                        
                                            <div id='container-image'>
                                                {/* <BoxFoto src={`${this.props.foto}${produto.map(item => item.foto_descricao)} `} alt={produto.map(item => item.foto_descricao)}/>                                            */}

                                                <BoxFoto>
                                                    <LazyLoadImage
                                                        width={'100%'} 
                                                        height={'100%'}
                                                        src={`${this.props.foto}${produto.map(item => item.foto_descricao)}`}
                                                        // src={`https://www.sistefood.com.br/img/19651715000132/${produto.map(item => item.foto_descricao)}`}
                                                        alt={produto.map(item => item.foto_descricao)}
                                                        effect={'blur'}
                                                    />

                                                </BoxFoto>
                                            </div>
                                                        
                                        )
                                        :(
                                            null
                                        )
                                    }
                                </div> 
                           
                                <div id='containerNome'>
                                    <p id='nome'>
                                        {
                                            produto.length > 0 ? (
                                                produto[0].nome
                                            ):(
                                                null
                                            )
                                            
                                        }
                                    </p>

                                    <p id='infoproduto'>
                                
                                        {
                                            produto.length > 0 ? (
                                                produto[0].descricao_web 
                                            )
                                            :(
                                                'null'
                                            )
                                        }
                                        
                                    </p>
                                </div>

                                {
                                    refactorComplement.length> 0?(
                                        <ProductComplement 
                                            complementProduct={refactorComplement}
                                            updateComplementProduct={this.updateComplementProductItemsQtsMore}
                                            updateComplementProductItemsQtsAnyLess={this.updateComplementProductItemsQtsAnyLess}
                                            updateTotalProduct= {this.updateTotalProduct}
                                            updateComplementProductItemsRadio={this.updateComplementProductItemsRadio}
                                            updateTotalProductRadio={this.updateTotalProductRadio}
                                        />
                                    )
                                    :(
                                        null
                                    )
                                }
                                        
                                {
                                    this.props.portfolio === 'N'? (
                                        <>
                                            <div id='obs'>               
                                                <p>Alguma observação?</p>
                                            </div>

                                            <BoxObs
                                                placeholder="Ex: Sem molho"
                                                onChange={(e) => this.handleObs(e.target.value)}                         
                                                value={produto.length > 0 ?  produto[0].obs : ''}   
                                            >
                                            </BoxObs>
                                        </>

                                    )
                                    :(
                                        null    
                                    )
                                }
                            </BoxAddProduto>

                            {
                                this.props.portfolio === 'N'? (
                                    <BoxQtdProduto>

                                        <BoxQtdGeral>
                                            <BoxQtdMenos
                                                onClick={() => this.changeQtdMenos(produto[0].qtd)}
                                            >
                                                -
                                            </BoxQtdMenos>    

                                                <BoxInput>
                                                    <Form.Control 
                                                        name={'qtd'}
                                                        readOnly={true}
                                                        maxLength={3}
                                                        value={produto.length > 0 ? produto[0].qtd : 1}
                                                    >

                                                    </Form.Control>
                                    
                                                </BoxInput>

                                            <BoxQtdMais
                                                onClick={() => this.changeQtdMais(produto[0].qtd)}
                                            >
                                                +
                                            </BoxQtdMais> 
                                        </BoxQtdGeral>

                                        <BoxBotaototal>
                                            <button onClick={() => this.gravarBancoOuLocal()} disabled={disabled}>
                                                <BoxAddSacola>
                                                    <span>Adicionar

                                                        {
                                                            produto.length > 0 ?  (
                                                                <p>
                                                                    {parseFloat(produto[0].total).toLocaleString('pt-br', {style:'currency', currency:'BRL'}) } 
                                                                </p>
                                                            )
                                                            :(  
                                                                null
                                                            )
                                                        }
                        
                                                    </span>
                                                
                                                </BoxAddSacola>
                                            </button>
                                        </BoxBotaototal>
                                    </BoxQtdProduto>            

                                )
                                :(
                                    null
                                )
                            }
                        </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default AddProdutos
