import React from "react";
import {
    // esse é o responsavel pelas Rotas do react
    BrowserRouter as Router,
    // serve para renderizar o componente recebido pela url
    Switch,
    // serve para controlar as rotas
    // Route,
    // pego od dados que preciso para fazer algo. Exemplo( para pegar um caminho para renderizar um componente especifico )
    // useRouteMatch,
} from 'react-router-dom'

import Headers from "../pages/Home";
import RouterDynamic from './routes-wrapper'
import NotFound from "../pages/NotFound/index";
import  GlobalStyle  from "../GlobalStyles";
import { ThemeProvider } from "styled-components";
import {theme} from '../theme/index.js'
export default function Routes(){
    return(
        <Router>
            <ThemeProvider theme={theme}>
            <GlobalStyle/>
            <Switch>
                <RouterDynamic exact path={'/'} component={Headers} />
                <RouterDynamic exact path={'/Sacola'} component={Headers}/>
                <RouterDynamic exact path={'/Sacola/Login'} component={Headers}/>
                <RouterDynamic exact path={'/Endereco'} component={Headers}/> 
                <RouterDynamic exact path={'/Informar/Endereco/Finalizar'} component={Headers}/> 
                <RouterDynamic exact path={'/Informar/Endereco/Finalizar/FormaPagamento'} component={Headers}/> 
                <RouterDynamic exact path={'/UsuarioLogado/ListarEnderecos/Endereco'} component={Headers}/> 
                <RouterDynamic exact path={'/Sacola/ListarEnderecos/Endereco'} component={Headers}/> 
                <RouterDynamic exact path={'/SemEndereco'} component={Headers}/>    
                <RouterDynamic exact path={'/:id/Endereco'} component={Headers}/> 
                <RouterDynamic exact path={'/UsuarioLogado/ListarEnderecos/:id/Endereco'} component={Headers}/> 
                <RouterDynamic exact path={'/Sacola/ListarEnderecos/:id/Endereco'} component={Headers}/> 

                <RouterDynamic exact path={'/EscolhaSeuEndereco/Endereco'} component={Headers}/> 
                <RouterDynamic exact path={'/EscolhaSeuEndereco/:id/Endereco'} component={Headers}/> 

                <RouterDynamic exact path={'/Login'} component={Headers}/> 
                <RouterDynamic exact path={'/Login/Cadastrar'} component={Headers}/> 
                <RouterDynamic exact path={'/Sacola/Login/Cadastrar'} component={Headers}/> 
                <RouterDynamic exact path={'/Login/EsqueciSenha'} component={Headers}/> 
                <RouterDynamic exact path={'/Sacola/ListarEnderecos'} component={Headers}/> 
                <RouterDynamic exact path={'/Sacola/Endereco'} component={Headers}/> 
                <RouterDynamic exact path={'/EscolhaSeuEndereco'} component={Headers}/> 
                <RouterDynamic exact path={'/FormaPagamento'} component={Headers}/> 
                <RouterDynamic exact path={'/Sacola/FormaPagamento'} component={Headers}/> 
                <RouterDynamic exact path={'/EscolhaSeuEndereco/FormaPagamento'} component={Headers}/> 
                <RouterDynamic exact path={'/UsuarioLogado'} component={Headers}/> 
                <RouterDynamic exact path={'/UsuarioLogado/ListarEnderecos'} component={Headers}/> 
                <RouterDynamic exact path={'/UsuarioLogado/MeusDados'} component={Headers}/> 
                <RouterDynamic exact path={'/Sobre'} component={Headers}/> 
                <RouterDynamic exact path={'/Pedidos'} component={Headers}/> 
                <RouterDynamic exact path={'/Pedidos/CancelarPedido'} component={Headers}/> 
                <RouterDynamic exact path={'/Pedidos/CancelarPedido/Suporte'} component={Headers}/> 
                <RouterDynamic exact path={'/DetalhesPedido'} component={Headers}/> 
                <RouterDynamic exact path={'/AdicionarProduto/:id/:nome'} component={Headers}/> 
                <RouterDynamic exact path={'/Sacola/AdicionarProduto/:id/:nome'} component={Headers}/>     
                {/* aqui eu mostro o erro 404 caso a rota não exista */}
                <RouterDynamic exact path={'*'} component={NotFound}/>
            </Switch>
            </ThemeProvider>
        </Router>
    )   
}
