import React from "react";
import { BoxInicial, BoxInfoPedidos, BoxIconNavBar, Boxcategory } from './style';
import { Row, Spinner, Alert} from 'react-bootstrap'
import ServiceCardapio from '../../service/cardapio'
import ServiceProdutos from '../../service/produto'
import {createDb} from '../../db/index'
import AddProdutos from "../AddProduct";
import SacolaProdutos from "../Bag";
import Cadastrar from "../Unlogged";
import Endereco from "../Address";
import ListarEnderecos from '../AddressList'
import Promotion from '../../assets/NavBar/iconPromotion.svg'
import Pesquisar from '../../assets/NavBar/iconSearch.svg'
import Pedido from '../../assets/NavBar/iconOrder.svg'
import Login from '../Login'
import Usuario from '../../assets/NavBar/iconUser.svg'
import {Header} from "../../components/Header"
import { entrar, getToken, logout } from "../../service/auth";
import { ItemsBagButton } from "../../components/ItemsBagButton";
import { InfoBagBox } from "../../components/InfoBagBox";
import {UserLoggedOptionsScreen}  from "../UserLoggedOptions";
import MeusPedido from "../Order";
import ServiceEndereco from "../../service/enderecoApiNode";
import UserLoggedInfoScreen  from "../UserInfo";
import FormaPagamento from "../PaymentMethod";
import ParametroServices from "../../service/parametro";
import AboutScreen from "../About";
import * as S from "../../components/SearchBox/style";
import IconSearch from "../../assets/SearchBox/searchBoxIcon.svg";
import IconClearSearch from "../../assets/SearchBox/iconClearSearch.svg";
import DetalhesPedido from "../OrderDetails";
import CancelOrderScreen from "../CancelOrder";
import SupportScreen from "../Support";
import FreteService from "../../service/frete";
import ForgotPassword from "../ForgotPassword";
import ServiceCnpjCardapio from "../../service/cnpjCardapio";
import 'react-lazy-load-image-component/src/effects/blur.css'
import { SearchGoogleMaps } from "../../service/googleMaps";
import { Searchitems } from "../../components/SearchItems";
import { Category } from "../../components/Category";
import { ProductPromotion } from "../../components/ProductPromotion";
import { ListingProduct } from "../../components/ListingProduct";

let clearTimerScroll = 0;
let produtoAdd = [];
let urlImg = ''
let foto = '';
let entrega = true;
let tx_entrega = 0
let resetTx_entrega = 0;
let banco_cardapio = '';
let elTopoOld = 0;
let lastPositionScroll = 0
let distancia = 0; //distância absoluta do elemento ao topo
let currentId =  0;

class Headers extends React.Component {
    
    constructor(props){
        super(props)
        
        this.state = {
            cnpj: '',
            categoria: [],
            produtos:[],
            produtosSacola:[],
            mostrarModalAdd: true,
            mostrarModalSacola: true,
            mostrarModalLogin: true,
            mostrarModalCadastrar: true,
            mostrarModalEsqueciSenha: false,
            nome:'',
            searchActive:false,
            userLogged: false,
            mostrarModalPedido: true,
            isLoadingEndereco: true,
            enderecos:[],
            enderecoEscolhido:[],
            mostrarModalEndereco:true,
            userLoggedInfo: false,
            mostrarModalPagamento:true,
            NomeFormaPagamento:'',
            parametros:[],
            mostrarModalSobre: false,
            mostrarModalDetalhesPedido: true,
            arrayMeusPedidos: [],
            mostrarModalCancelarPedido: false,
            mostrarModalSuporte:false,
            naoEntregamosAi: false,
            revalidadorNaoEntregamosAi:false,
            message:'',
            promotion: false,
            productCategory: [],
            lastProductCategory:0,
            countButton:1,
            arrowLeft: false,
            arrowRight: true,
            banner:[],
            portfolio:'',
            sistefood:'',
            totalSacola:0,
            qtdItemSacola:0,
        }

        this.ProductName = [];
        this.arraOfSearch = [];

    }


    async componentDidMount () {
        createDb();
        await this.EncapsulandoRequisicoes();
        this.veriryScrollPromoxion();
        this.updateVisibleArrows();

        this.getPositionElementItensCategoryWrapper();

        let root = document.getElementById('root')
        root.addEventListener('scroll', this.scroll );
        root.addEventListener('scroll', this.verifyDistanceTopWrapperCategory);

        this.removeScroll = () => {
            clearInterval(clearTimerScroll);
            root.removeEventListener('scroll', this.scroll);
            document.removeEventListener('scroll', this.verifyDistanceTopWrapperCategory);
        };    
    }

    getPositionElementItensCategoryWrapper = () => {
        const element = document.querySelectorAll('.itens-categoria-wrapper')[0];
        elTopoOld = element.offsetTop;
    }
    
    verifyDistanceTopWrapperCategory = () => {
        const element = document.querySelectorAll('.itens-categoria-wrapper')[0];
        if (element !== null && element !== undefined) {
            let root = document.getElementById('root');
            
            let elTopo = element.offsetTop; // distância relativa do elemento ao topo
            let scrollTopo = root.scrollTop; // scroll da janela
    
            if(elTopo <= scrollTopo){
                element.style.position = 'fixed';
                element.style.top = '0';
            }
    
            if (elTopoOld >= scrollTopo) {
                element.style.position = ''
            }

        }
    }

    handleBackButton = () => {
        const url = this.props.match.path;

        if (url === '/') {
            this.setState({
                mostrarModalLogin: true,
                mostrarModalAdd: true, 
                mostrarModalSacola: true,
                mostrarModalPedido: true,
                mostrarModalSobre : false,
                isLoadingEndereco : true,
                mostrarModalCadastrar: true,
                userLogged : false,
                mostrarModalPagamento: true,
                mostrarModalCancelarPedido: false,
                mostrarModalSuporte: false,
                mostrarModalEndereco : true,
                userLoggedInfo: false,
                mostrarModalEsqueciSenha : false

            });
        }
        else if (url === '/AdicionarProduto/:id/:nome' ||url === '/Sacola/AdicionarProduto/:id/:nome' ) {
            this.setState({mostrarModalAdd: false});
            this.MostrarModalAdd();
        }
        else if (url === '/UsuarioLogado') {
            this.setState({userLogged: true, userLoggedInfo: false, isLoadingEndereco: true});
        }
        else if (url ==='/UsuarioLogado/MeusDados') {
            this.setState({userLogged: true, userLoggedInfo: true});
        }
        else if (url ==='/Sacola') {
            this.setState({
                mostrarModalSacola : false, 
                isLoadingEndereco: true,
                mostrarModalPagamento: true, 
                mostrarModalAdd:true,
                mostrarModalLogin: true,
                mostrarModalEndereco: true
            });
        }
        else if (url === '/Sacola/Endereco') {
            this.setState({mostrarModalEndereco:false, mostrarModalSacola:false});
        }
        else if (url ==='/UsuarioLogado/ListarEnderecos') {
            this.setState({isLoadingEndereco: false, mostrarModalEndereco: true});
        }
        else if (url ==='/ListarEnderecos' || url === '/EscolhaSeuEndereco') {
            this.setState({isLoadingEndereco: false, mostrarModalEndereco: true});
        }
        else if (url === '/Informar/Endereco/Finalizar/FormaPagamento') {
            this.setState({mostrarModalPagamento: false, mostrarModalEndereco: true});
        }
        else if (url === '/Endereco' ||  url ==='/:id/Endereco' || url === '/UsuarioLogado/ListarEnderecos/Endereco' || url === '/UsuarioLogado/ListarEnderecos/:id/Endereco' || url === '/Informar/Endereco/Finalizar' || url === '/EscolhaSeuEndereco/Endereco' || url === '/EscolhaSeuEndereco/:id/Endereco'
        ) {
            this.setState({mostrarModalEndereco: false, isLoadingEndereco:false});
        }
        else if (url === '/Sacola/ListarEnderecos/Endereco' || url === '/Sacola/ListarEnderecos/:id/Endereco') {
            this.setState({isLoadingEndereco: false, mostrarModalEndereco :false });   
        }
        else if (url === '/Sacola/ListarEnderecos') {
            this.setState({isLoadingEndereco: false, mostrarModalSacola: false, mostrarModalEndereco: true});  
        }
        else if (url === '/FormaPagamento' || url === '/Sacola/FormaPagamento') {
            this.setState({mostrarModalPagamento:false });
        }
        else if (url === '/Login'){
            this.setState({mostrarModalLogin: false, mostrarModalCadastrar: true, mostrarModalEsqueciSenha: false});
        }
        else if (url === '/Sacola/Login') {
            this.setState({mostrarModalLogin: false, mostrarModalCadastrar: true});
        }
        else if (url === '/Login/Cadastrar' || url === '/Sacola/Login/Cadastrar') {
            this.setState({mostrarModalCadastrar: false, mostrarModalLogin: false});   
        }
        else if (url === '/Sobre') {
            this.setState({mostrarModalSobre: true});   
        }
        else if (url === '/Pedidos') {
            this.setState({mostrarModalPedido: false, mostrarModalCancelarPedido: false, mostrarModalSuporte: false, mostrarModalDetalhesPedido: true});   
        }
        else if (url === '/Pedidos/CancelarPedido') {
            this.setState({mostrarModalCancelarPedido: true});   
        }
        else if (url === '/Pedidos/CancelarPedido/Suporte') {
            this.setState({mostrarModalSuporte: true});   
            
        }
        else if (url === '/Login/EsqueciSenha') {
            this.setState({mostrarModalEsqueciSenha:true, mostrarModalLogin: false})
        }
        else if (url === '/EscolhaSeuEndereco/FormaPagamento') {
            this.setState({mostrarModalEndereco: false});
            this.mostrarModalFormaPagamento(); 
        }
    }
    
    componentDidUpdate (prevProps, prevState, snapshot) {
        window.onpopstate  = e => {
            this.handleBackButton();
        }
    }

    veryisMobile = () => {

        if ( (window.innerWidth < 640 || window.innerHeight < 480) || (window.innerWidth < 1024 || window.innerHeight < 768) ) {
            return true;
        } else {
            return false;
        }   
    }

    getOneDistance = async () => {
        try {
            let resultArray = JSON.parse(getToken('ARRAYADDRESSUSER'));
            let AddressDestinations = this.state.parametros.map(item => `${item.site_logradouro}, ${item.site_numero} - ${item.site_bairro}, ${item.site_cidade}`);
            let formatAddressOrigens = `${resultArray[1].long_name}, ${resultArray[0].long_name} - ${resultArray[2].long_name}, ${resultArray[4].short_name}`

            const service = new SearchGoogleMaps();
            const result  = await service.getOneDistace(formatAddressOrigens, AddressDestinations); 

            if (result.ok === true){
                // aqui eu vou salvar os quilômetros de distancia;
                entrar('DISTANCIA', result.data.elements[0].distance.text);
            }


        } catch (error) {
            console.log(error)
        }
    }

    getAddressApiGoogleUser = () => {
        if ("geolocation" in navigator) {
            console.log("localização ativa");

            const options = {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge:0
            };

            const success = async(pos) =>  {
                const crd = pos.coords;

                // console.log("Your current position is:");
                console.log(`Latitude : ${crd.latitude}`);
                console.log(`Longitude: ${crd.longitude}`);
                console.log(`More or less ${crd.accuracy} meters.`);

                let userLatitude  = JSON.parse(getToken('USERLATITUDE'));
                let userLongitude = JSON.parse(getToken('USERLONGITUDE'));

                if (userLatitude !== crd.latitude || userLongitude !== crd.longitude) {

                    const service  = new SearchGoogleMaps();
                    const result  = await service.getOneAddress(crd);
                    
                    entrar('USERLATITUDE', crd.latitude);
                    entrar('USERLONGITUDE', crd.longitude);
                    entrar('ARRAYADDRESSUSER', JSON.stringify(result.results[0].address_components));
                    logout('COUNTATIVARGPS');
                    this.getOneDistance();
                }
            }
              
            const error = (err) =>  {
                let result = this.veryisMobile();

                if (result === true && err.code === 1){
                    if (err.message  !== 'User denied Geolocation'){
                        if(JSON.parse(getToken('COUNTATIVARGPS')) <= 3){
                            entrar('COUNTATIVARGPS', 1 + JSON.parse(getToken('COUNTATIVARGPS')));
                            alert('Ative o GPS do dispositivo e recarregue a pagína!');
                        }

                    }
                    
                }
              
                console.warn(`ERROR(${err.code}): ${err.message}`);
            }
            
            navigator.geolocation.getCurrentPosition(success, error, options);

        } else {
            console.log("localização não ativa");
        }

    }

    getAddressApiGoogleClient = () => {
        if ("geolocation" in navigator) {
            // console.log("localização ativa");

            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge:0
            };
              
            const success = async(pos) =>  {
                const crd = pos.coords;

                // console.log("Your current position is:");
                // console.log(`Latitude : ${crd.latitude}`);
                // console.log(`Longitude: ${crd.longitude}`);
                // console.log(`More or less ${crd.accuracy} meters.`);
                
                let clientLatitude  = JSON.parse(getToken('CLIENTLATITUDE'));
                let clientLongitude = JSON.parse(getToken('CLIENTLONGITUDE'));
 
                if (clientLatitude !== crd.latitude || clientLongitude !== crd.longitude) {

                    const service  = new SearchGoogleMaps();
                    const result  = await service.getOneAddress(crd);
                    entrar('CLIENTLATITUDE', crd.latitude);
                    entrar('CLIENTLONGITUDE', crd.longitude);
                    entrar('ARRAYADDRESSCLIENT', JSON.stringify(result.results[0].address_components));
                        
 
                }
            }
              

            const error = (err) =>  {
                console.warn(`ERROR(${err.code}): ${err.message}`);
            }
            
            navigator.geolocation.getCurrentPosition(success, error, options);

        } else {
            console.log("localização não ativa");
        }

    }

    getBanner = async() => {
        try{
            const {cnpj} = this.state;

            const service = new ParametroServices();
            const result  = await service.getBanner({cnpj});

            if (result.success === true) {
                this.setState({banner: result.data})
            }



        }catch(error){
            console.log(error)
        }
    }

    timer = () => {
        let root = document.getElementById('root')
        clearTimerScroll = setTimeout(()=> {
            root.addEventListener('scroll', this.scroll);
        },1000)
    }

    scroll = () => {    
        const options = {
            lista: '',
            root: '',
            scrollTopo: 0, // scroll da janela
            altJanela: 0,// altura da janela
            elTopo: 0,
            index:0
        }
            
        options.lista = document.querySelectorAll('.categoriaitens');
        options.root = document.getElementById('root');
        options.scrollTopo = options.root.scrollTop
        options.altJanela = options.root.clientHeight;

        let positionScroll = options.scrollTopo;

        if(positionScroll >= lastPositionScroll ){
            for (let index = 0; index < options.lista.length; index++) {
                options.elTopo = options.lista[index].offsetTop
                options.index = index
                this.scrollingDown(options);
            }
        }
        else if (positionScroll <= lastPositionScroll) {
            this.scrollGoingUp(options);
        }

        lastPositionScroll = options.scrollTopo;
    }

    scrollingDown = (options) => {
        distancia = (options.elTopo - options.scrollTopo) + (options.altJanela - 150);
        if (distancia <= options.altJanela) {
            if (options.elTopo >= options.scrollTopo) {
                this.scrollCategory(options.lista[options.index].id.substring(8));
            }
        }
        return options.elTopo
    }

    scrollGoingUp = (options) => {
        let index = 0;
        do {
            index++;
            if (options.lista[index] !== undefined) {
                options.elTopo = options.lista[index].offsetTop;
                if( options.scrollTopo >= options.elTopo){
                    
                    if(options.scrollTopo + 300 <= options.lista[index + 1].offsetTop ){
                        this.scrollCategory(options.lista[index].id.substring(8));
                    }
                
                }
            }

        } while (index < options.lista.length);
        return options.lista;
    }

    updateVisibleArrows = () => {
       
        window.onresize  =  e => {
            if (this.state.productCategory.length  > 0) {            
                const BoxPromotion = document.getElementById(`boxcontainerpromotion`);
                if(BoxPromotion === null) return false;
                
                if (BoxPromotion.scrollWidth >  BoxPromotion.offsetWidth) {
                    this.setState({arrowRight:  true});
                }
                else if (BoxPromotion.scrollWidth ===  BoxPromotion.offsetWidth ){
                    this.setState({arrowRight:   false});
        
                }
            }
        }
    }

    veriryScrollPromoxion = () => {
        const BoxPromotion = document.getElementById(`boxcontainerpromotion`);
        if (this.state.productCategory.length === 0) return null;

        if (BoxPromotion.scrollWidth >  BoxPromotion.offsetWidth &&  this.state.productCategory.length > 0 ) {
            this.setState({arrowRight:  true});
        }
        else if (BoxPromotion.scrollWidth === BoxPromotion.offsetWidth){
            this.setState({arrowRight:   false});

        } 
    }

    alterarEntrega = (value) => {
        entrega = value;
    }

    pegandoCnpjCliente = async () => {
        try {
            let url = window.location.hostname;
            logout('CNPJ')
            if (url === 'localhost') {
                url = 'localhost'
            }
    
            const service = new ServiceCnpjCardapio();
            const result= await service.getCnpj({
                cnpj: '20798514000141',
                url
            })

            if (result.success === true) {
                banco_cardapio = result.data[0].banco_cardapio;
                this.setState({
                    cnpj: result.data[0].cnpj, 
                    // banco_cardapio:result.data[0].banco_cardapio,
                    sistefood: result.data[0].sistefood,
                    portfolio: result.data[0].portfolio
                })
                entrar('CNPJ', result.data[0].cnpj)
            }
                    
        } catch (error) {
            
        }
    }

    Messagem = () => {
        const {message} = this.state;
        return(
            <Alert variant='warning'>
                {message}
            </Alert>
        )
    }

    taxaEntregaPorCep = async () => {
        try {
            const {enderecoEscolhido, cnpj} = this.state;

            const token = getToken('TOKEN');
            if (token !== null) {

                const service = new FreteService();
                let result;
                if (enderecoEscolhido.length > 0){
                    result = await service.getOne({
                        cnpj: cnpj,
                        cep: enderecoEscolhido[0].cep,
                        bairro: enderecoEscolhido[0].bairro,
                        cidade: enderecoEscolhido[0].cidade
                    })

                    if (result.data.valor <= 0) {
    
                        this.setState({
                            naoEntregamosAi: true, 
                            message:'Infelizmente não entregamos para sua localidade!', 
                            revalidadorNaoEntregamosAi: true
                        })
    
                    }
                    else{
            
                        this.setState({
                            naoEntregamosAi: false, 
                            message: '', 
                            revalidadorNaoEntregamosAi: false
                        })
    
                    }
                    tx_entrega = result.data.valor;
                }
                else{
                    tx_entrega = 0;
                }
                
                return tx_entrega
            }

    
        } catch (error) {
            console.log(error)
        }
    }

    alteraNaoEntregamosAi = (entrega, message) => {
        this.setState({ naoEntregamosAi: entrega, message: message})
    }

    taxaEntrega = async() => {
        try {
            const {cnpj} = this.state;
            const service = new ParametroServices();
            const result = await service.getAll({cnpj});

            if (result.success === true) {
    
                urlImg = result.data[0].site_url_img
                this.setState({parametros: result.data})
                if(result.data[0].site_frete <= 0) {
                    const result = await this.taxaEntregaPorCep();
                    return result 
                }
                else{
                    tx_entrega = result.data[0].site_frete;
                    resetTx_entrega =  result.data[0].site_frete
                    this.setState({
                        naoEntregamosAi: false, 
                        message: '',
                        revalidadorNaoEntregamosAi: false,
                    })

                    return tx_entrega
                }

            }
        } catch (error) {
            console.log(error)
        }

    }

    verificandoUrl = () => {
        try {
            const url = this.props.match.path ;
            if (url === '/') {
                this.setState({
                    mostrarModalLogin: true,
                    mostrarModalAdd: true, 
                    mostrarModalSacola: true,
                    mostrarModalPedido: true,
                    mostrarModalSobre : false,
                    isLoadingEndereco : true,
                    mostrarModalCadastrar: true,
                    userLogged : false,
                    mostrarModalPagamento: true,
                    mostrarModalCancelarPedido: false,
                    mostrarModalSuporte: false,
                    mostrarModalEndereco : true,
                    userLoggedInfo: false,
                    mostrarModalEsqueciSenha : false

                });
            }
            else{

                if (url === '/AdicionarProduto/:id/:nome' || url === '/Sacola/AdicionarProduto/:id/:nome') {     
                    this.setState({mostrarModalAdd: false});
                }
                else if(url === '/Sacola'){
                    this.setState({mostrarModalSacola: false, mostrarModalAdd: true, mostrarModalEndereco:true});
                }
                else if (url === '/Sacola/Endereco') {
                    this.setState({mostrarModalEndereco:false, mostrarModalSacola:false});
                }
                else if (url === '/FormaPagamento'  || url === '/Sacola/FormaPagamento') {
                    this.setState({mostrarModalSacola: false});
                    this.mostrarModalFormaPagamento();
                }
                else if (url === '/EscolhaSeuEndereco/FormaPagamento') {
                    this.setState({mostrarModalEndereco: false});
                    this.mostrarModalFormaPagamento(); 
                }
                else if (url === '/Login' ){
                    this.setState({mostrarModalLogin: false});    
                }
                else if (url === '/Sacola/Login') {
                    this.setState({mostrarModalLogin: false, mostrarModalSacola: false,  mostrarModalCadastrar: true});     
                }
                else if (url === '/Login/Cadastrar') {
                    this.setState({mostrarModalCadastrar: false, mostrarModalLogin: false});   
                }
                else if (url === '/Sacola/Login/Cadastrar') {
                    this.setState({mostrarModalCadastrar: false, mostrarModalLogin: false, mostrarModalSacola: false}); 
                }
                else if (url === '/UsuarioLogado/MeusDados') {
                    this.setState({userLoggedInfo: true, userLogged: true});
                }
                else if(url === '/UsuarioLogado') {
                    this.setState({userLogged: true});   
                }
                else if (url === '/Pedidos' || url === '/DetalhesPedido'  || url === '/Pedidos/CancelarPedido/Suporte' || url === '/Pedidos/CancelarPedido') {                  
                    this.setState({mostrarModalPedido: false});   
                }
                else if (url === '/ListarEnderecos' || url  ==='/UsuarioLogado/ListarEnderecos' || url === '/Sacola/ListarEnderecos' || url === '/EscolhaSeuEndereco') {
                    this.setState({isLoadingEndereco: false});   
                }
                else if (url === '/Informar/Endereco/Finalizar/FormaPagamento') {
                    this.setState({mostrarModalPagamento: false, mostrarModalEndereco: true});
                }
                else if (url === '/Endereco' ||  url ==='/:id/Endereco' || url === '/UsuarioLogado/ListarEnderecos/Endereco' || url === '/UsuarioLogado/ListarEnderecos/:id/Endereco' || url === '/Informar/Endereco/Finalizar' || url === '/EscolhaSeuEndereco/Endereco' || url === '/EscolhaSeuEndereco/:id/Endereco'

                ) {
                    this.setState({mostrarModalEndereco: false});   
                }
                else if (url === '/Sacola/ListarEnderecos/Endereco' || url === '/Sacola/ListarEnderecos/:id/Endereco') {
                    this.setState({isLoadingEndereco: false, mostrarModalEndereco :false });   
                }
    
                else if (url === '/Sobre') {
                    this.setState({mostrarModalSobre: true});   
                }
                else if (url === '/Login/EsqueciSenha') {
                    this.setState({mostrarModalEsqueciSenha:true, mostrarModalLogin: false})
                }
                else if (url === '/Sacola/Login') {
                    this.setState({mostrarModalSacola: false, mostrarModalLogin: false});     
                }
            }
     
        } catch (error) {
            
        }
    }
 
    ActiveSearchs = (a)=>{    
        this.setState({searchActive:  a})
    }

    EncapsulandoRequisicoes = async() => {
        await this.pegandoCnpjCliente();
        await this.getBanner();

        if (getToken('TOKEN') !== null ) {
            await this.getAllEndereco();
        }

        await this.buscarCategorias();
        await this.buscarProdutos();
        await this.taxaEntrega();
        this.getProductIndexDb();
        this.verificandoUrl();
        this.selecionandoProdutosAdd();


        // this.getAddressApiGoogleUser();
        // this.getAddressApiGoogleClient();
    }

    clearLocalStorage = () => {
        logout('TOKEN');
        logout('IDUSUARIO');
        logout('CPF_CNPJ');
        logout('CLIENTE');
        logout('NUMERO_WEB');
        logout('VALORTROCO');
        logout('FORMAPAGAMENTO');
        logout('NOMEFORMAPAGAMENTO');
    }

    selecionandoProdutosAdd =  () => {
        let db;
        const request = indexedDB.open('cardapio', 2 );  

        request.onsuccess =  () => {
            db = request.result;   
            const transaction = db.transaction(["Produtos"], "readwrite");
            const objectStore =  transaction.objectStore("Produtos");
            const result =  objectStore.getAll();

            result.onsuccess = () => {
                let resultado  =  result;
                this.alterandoEstadoProdutos(resultado.result);
                this.verificandoQtdSacola();
            }
                db.close();
        }
    }

    getProductIndexDb = () => {
        const {id} = this.props.match.params;
        const {path} = this.props.match;
        const {produtos} = this.state;
        if(produtos.length > 0) {
            if(parseInt(id)){
                if(path === '/AdicionarProduto/:id/:nome' ){
    
                    Object.entries(produtos).map((item) => {
                        item[1].itens.filter((el) => {
    
                            if(el.id === parseInt(id) ) {
                                produtoAdd.push(el);
                            }
                            
                        })
                    }) 
                }
            }
        }
    }

    alterandoEstadoProdutos = (array) => {
        if ( array.length > 0 ) {    
            foto = array[0].foto_descricao  ;    
            this.setState({  produtosSacola:  array});
        }
        else{
            this.setState({produtosSacola: array, totalSacola:0.00})
        }  
    }
    
    buscarProdutos = async () => {
        try {
            const {cnpj, productCategory} = this.state;
            const service = new ServiceProdutos();
        
            const result = await service.getAllProdutosCardapio({
                cnpj,
                ordem:'0'
            })

            if ( result.data.length > 0 ) {
                this.setState({
                    produtos: result.data,
                });

                let length = 0;
                Object.entries(result.data).map((el) => 
            
                    el[1].itens.map((item, index) => {

                        if(item.oferta_web === 'S') {
                            length = length + 1;

                            this.setState({promotion: true})
                            productCategory.push(item);
                
                        }

                    })
                )

                this.setState({productCategory: this.state.productCategory, lastProductCategory: length -1 });

                for (let index = 0; index < result.data.length; index++) {
                    this.arraOfSearch.push(...result.data[index].itens);
                }
            
            }   

        } catch (error) {
            
        }
    }

    buscarCategorias = async () =>{
        try{
            const {cnpj} = this.state;
            const service = new ServiceCardapio();

            const result = await service.getAllCategorias({
                cnpj,
                ordem:'0'
            })
            
            if (result.success === true) {
                this.setState({
                    categoria: result.data
                })
            }

        }catch(error){

        }
    }

    scrollCategory = (id) => {
        const elementId = document.getElementById(`category${id}`);
        const searchId  = parseInt(elementId.id.substring(8));
        this.addClass(searchId);
        let  element  = document.getElementById(`buttons${searchId}`);

        if(element !== null){
            let coord = element.getBoundingClientRect();

            if( currentId!== searchId){
                this.focusScroll(searchId, coord);   
                currentId = searchId;
     
            }
        }
    }

    getCoordenate = (item) =>{
        this.scrollSuaveDivRoot(item.id)
        this.removeScroll();
        this.timer();
        this.addClass(item.id);
        this.scrollCategory(item.id)
    }

    scrollSuaveDivRoot = (id) => {

        let root = document.getElementById('root')
        let elementScroll = document.getElementById(`category${id}`)
        let position;

        if (elementScroll === null){
            let elementScrollPromotion = document.getElementById(`promotion`);
            if (elementScrollPromotion !== null) position = elementScrollPromotion.getBoundingClientRect();


        }else{

            if (elementScroll !== null) position = elementScroll.getBoundingClientRect();

        }
        
        root.scrollBy(0, position.y - 80)

    }

    removeClassButton = (id) => {
        const {categoria} = this.state;
        let  element;

        categoria.map((item) => {
      
            if (item[0].id !== id && id > 0) {
       
                element = document.getElementById(`buttons${item[0].id}`);
               
                element.classList.remove('focusbutton');
        
                element.classList.add('button');  
            }
            else if(id === 0){
    
                element = document.getElementById(`buttons${item[0].id}`);
            
                element.classList.remove('focusbutton');
        
                element.classList.add('button');  
            
            }
        })
    }

    addClass = (id) => {
        let  element = document.getElementById(`buttons${id}`);
        if(element !== null ){
            this.removeClassButton(id);
            element.classList.add('focusbutton');
        }
    }

    focusScroll = (itemId, coord) =>{
        
        let b = document.getElementById('categoria')
        let indexCategoria = this.pegandoIndexCategoria(itemId)
        
        if (indexCategoria === 0 ) return  b.scrollBy(0,0);
        
        return b.scrollBy((coord.x) - 100 ,0);    
    }
    
    pegandoIndexCategoria = (id) => {
        const  {categoria} = this.state;
        let indexItemCategoria =  categoria.map((item, index) => item.filter((item) => {if (item.id === id) return index}))
        return indexItemCategoria
    }

    FecharModalAdd = () => {
        this.props.history.goBack();     
        this.setState({mostrarModalAdd: true});
    }

    FecharModalCadastrar = () => {
        this.setState({mostrarModalCadastrar: true});
    }

    selectingPromotion = () => {
        const divRoot = document.getElementById('root');
        const idElement = document.getElementById('promotion');

        if (idElement !== null) {
            this.removeScroll();
            this.timer();
            const {y} = idElement.getBoundingClientRect();
            divRoot.scrollBy(0, (y-55))

        }
        
    }

    FecharModalSacola = () => {
        this.setState({mostrarModalSacola: true});
    }

    FecharModalSobre = () => {                
        this.setState({mostrarModalSobre: false});
    }

    MostrarModalSobre = () => {                
        this.setState({mostrarModalSobre: true});
        this.props.history.push('/Sobre');
    }

    RotaInicial = ()=> {
        this.props.history.push('/');
    }
    
    MostrarModalAdd = () => {
        const {portfolio, parametros} = this.state;
       
        return(
            <AddProdutos 
                FecharModal={() => this.FecharModalAdd()} 
                idd={'AdicionarProduto'}
                totalSacola = {this.selecionandoProdutosAdd}
                location = {this.props}
                produtoAdd = {produtoAdd} 
                foto={urlImg}
                parametro={parametros}
                portfolio={portfolio}
            />   
        )
    }

    MostrarModalLogin = () => {
        const {
            parametros,
            cnpj, enderecos, enderecoEscolhido, NomeFormaPagamento, 
            mostrarModalEndereco, totalSacola,
            mostrarModalSacola, produtosSacola} = this.state;
        return(
            <Login 
                FecharModalLogin={() => this.FecharModalLogin()} 
                taxaEntrega = {() => this.taxaEntrega()}
                mostrarModalEndereco={mostrarModalEndereco}
                banco_cardapio={banco_cardapio}   
                idd = {'Login'}                     
                mostrarModalPedido={this.carregarPedidos}
                enderecoEscolhido={enderecoEscolhido}
                location = {this.props}
                MudandoEstadoSemLogin = {() => this.MudandoEstadoSemLogin()}
                enderecos={enderecos}
                stateSacola = {mostrarModalSacola}
                parametros={parametros}
                totalGeral={totalSacola}
                tx_entrega={tx_entrega}
                NomeFormaPagamento={NomeFormaPagamento}
                getAllEndereco={this.getAllEndereco}
                selecionadoEndereco={this.selecionadoEndereco}
                cnpj={cnpj}
                produtosSacola={produtosSacola}
                FecharModalSacola ={() => this.setState({mostrarModalSacola: true})}
                mostrarModalEnderecos = {() => this.setState({isLoadingEndereco: false})}
                mostrarModalFormaPagamento={this.mostrarModalFormaPagamento}
                selecionandoProdutosAdd={this.selecionandoProdutosAdd}

            />   
        )
    }

    MostrarModalEsqueciSenha = () => {
        return(
            <ForgotPassword
            FecharModalEsqueciSenha={() => this.FecharModalEsqueciSenha()}
            banco_cardapio={banco_cardapio}
            RotaLogin={() => this.RotaLogin()}
            idd={'EsqueciSenha'} 
            location = {this.props}
            />   
        )
    }

    FecharModalLogin = () => {
        this.setState({mostrarModalLogin: true});
    }

    MudandoEstadoModalAdd = () => { 
        this.setState({mostrarModalAdd:false});
    }

    MudandoEstadoCadastrar = () => {
        this.props.history.push('/UsuarioLogado/MeusDados');
        this.setState({userLoggedInfo: true});
    }

    MudandoEstadoSemLogin = () => {
        this.setState({mostrarModalCadastrar: false});
    }

    FecharModalEsqueciSenha = () => {
        this.setState({mostrarModalEsqueciSenha: false});
    }

    fechandoEstadoCadastrarInfo = () => {
        this.setState({userLoggedInfo: false});
    }

    MudandoEstadoCadastrar2 = () => {
        this.setState({isLoadingEndereco: false});
    }

    mostrarModalCadastrar = () => {
        const {enderecos, parametros, produtosSacola, totalSacola} = this.state;
        return(
            <Cadastrar
                FecharModalCadastrar={() => this.FecharModalCadastrar()}
                RotaLogin = {() => this.RotaLogin()}
                idd={'Cadastrar'} 
                banco_cardapio={banco_cardapio}
                location = { this.props}
                enderecos={enderecos}
                parametros={parametros}
                getAllEndereco={this.getAllEndereco}
                mostrarModalEndereco = {() => this.setState({mostrarModalEndereco: false})}
                fecharModal = {() => this.setState({mostrarModalCadastrar: true})}
                totalGeral = {totalSacola}
                fecharModalLogin={() => this.setState({mostrarModalLogin: true})}
                FecharModalSacola = {() => this.setState({mostrarModalSacola : true})}
                mostrarModalPedido={() => this.setState({mostrarModalPedido: false})}
                produtosSacola={produtosSacola}
                selecionandoProdutosAdd={this.selecionandoProdutosAdd}
            />
        )
    }

    mostrarModalSobre = () => {
        return(
            <AboutScreen
                FecharModalSobre={() => this.FecharModalSobre()}
                idd={'Sobre'} 
                location = { this.props}
                About = {this.state.parametros.map(item => item.site_sobre)}
                Address = {this.state.parametros.map(item => `${item.site_logradouro}, ${item.site_numero} - ${item.site_bairro}, ${item.site_cidade}`)}
                phone = {this.state.parametros.map(item => item.site_telefone)}
            />
        )
    }

    mostrarModalSacola = () => {
        const {
            cnpj, parametros, sistefood, 
            enderecoEscolhido,
            enderecos,
            message, naoEntregamosAi, 
            revalidadorNaoEntregamosAi,
            produtosSacola,
            NomeFormaPagamento,
            totalSacola
        } = this.state
        return(
            <SacolaProdutos 
                Messagem ={ () => this.Messagem()}
                banco_cardapio ={banco_cardapio}
                alteraNaoEntregamosAi = {this.alteraNaoEntregamosAi}
                message = {message}
                FecharModal={() => this.FecharModalSacola()} 
                naoEntregamosAi = {naoEntregamosAi}
                revalidadorNaoEntregamosAi={revalidadorNaoEntregamosAi}
                idd={'Sacola'} 
                foto={urlImg}
                cnpj={cnpj}
                tx_entrega={tx_entrega}
                MudandoEstadoModalAdd={() => this.MudandoEstadoModalAdd()}
                produtosSacola={produtosSacola}
                totalGeral={totalSacola}
                location = {this.props}
                alterandoQtdSacola= {() => this.alterandoQtdSacola()}
                alterandoTotalSacola= {() => this.alterandoTotalSacola()}
                verificandoQtdSacola = {() => this.verificandoQtdSacola()}
                carregarPedidos={() => this.carregarPedidos()}
                getAllEndereco ={() => this.getAllEndereco()}
                enderecoEscolhido={enderecoEscolhido}
                enderecos={enderecos}
                alterandoEstadoEndereco={() => this.alterandoEstadoEndereco()}
                mostrarModalEnd={() => this.mostrarModalEnd()}
                mostrarModalFormaPagamento={() => this.mostrarModalFormaPagamento()}
                NomeFormaPagamento={NomeFormaPagamento}
                alterarEstadoNomeFormaPagamento = {() => this.alterarEstadoNomeFormaPagamento()}
                fecharModalPagamento = {() => this.fecharModalPagamento()}
                parametros={parametros}
                alterarEntrega= {this.alterarEntrega} 
                mudandoEstadoEndereco = {() => this.mudandoEstadoEndereco()}
                alterarEstadoEnderecos={() => this.alterarEstadoEnderecos()}
                mudandoEstadoEnderecoEscolhido ={this.mudandoEstadoEnderecoEscolhido}
                MostrarModalLogin = {() => this.setState({mostrarModalLogin: false})}
                sistefood={sistefood}
                selecionandoProdutosAdd={this.selecionandoProdutosAdd}
            />
        )
    }

    alterandoQtdSacola = ()  => {
        this.setState({qtdItemSacola: 0})
    }

    alterandoTotalSacola = ()  => {
        this.setState({totalSacola:0});
    }

    carregarPedidos = async() => {
        try {
            let token = getToken('TOKEN') ;
            if (token !== '' && token !== null) {
                this.setState({mostrarModalPedido:false});
                this.props.history.push('/Pedidos');
            }
            else{
                this.RotaLogin();
            }

        } catch (error) {
            if (error.response.status === 401) {
                this.RotaLogin();
                this.clearLocalStorage();
                this.alterandoQtdSacola();
                this.alterandoTotalSacola();
            }
        }

    }

    fecharModalPedidos = () => {
        this.setState({mostrarModalPedido: true});
        this.props.history.push('/');
    }
  
    abrirModalPedido =  () => {
        const {cnpj} = this.state;
        return(
            <MeusPedido
                fecharModalPedidos={() =>this.fecharModalPedidos()}
                alterandoEstadoMostrarMeusPedidos = {this.alterandoEstadoMostrarMeusPedidos}
                mostrarCancelarPedido = {() => this.mostrarCancelarPedido()}
                mostrarSuporte = {() => this.mostrarSuporte()}
                idd={'Pedidos'}
                cnpj= {cnpj}
                location= {this.props}
                parametros={this.state.parametros}
            />
        )
    }

    carregarProdutosSacola = () => {

        if (produtoAdd.length > 0) {
            this.setState({mostrarModalSacola:false});
            this.props.history.push('/Sacola');    
        }
        else{
            this.props.history.push('/');  
        }

    }
    
    verificandoQtdSacola =  () => {

        let db ;   
        const request = indexedDB.open('cardapio', 2 ); 
        request.onsuccess =   () => {
            db = request.result;
            const transaction = db.transaction(["Produtos"], "readwrite");
            const objectStore =  transaction.objectStore("Produtos");
            const result =  objectStore.getAll();

            result.onsuccess =  () => {

                let resultado  =  result

                if ( resultado.result.length > 0 ) {
                    this.setState({produtosSacola: resultado.result, qtdItemSacola: resultado.result.length});
                    this.totalSacola();
                }
                else{
                    this.setState({produtosSacola:[] ,qtdItemSacola:0, totalSacola: 0.00}) 
                
                }

            }
        }
    }

    calcularTotalSacola = (array) => {
        if (array.length > 0 ) {     
            const total = array.map(item => parseFloat(item.total)).reduce((item1, item2) => (item1 + item2));
            return total
        }
        return 0.00;
    }

    totalSacola = () => {
        const {produtosSacola} = this.state;
        const total = this.calcularTotalSacola(produtosSacola);
        this.setState({totalSacola: total});
    }

    RotaLogin = () => {
        this.setState({mostrarModalLogin: false});
        this.props.history.push('/Login');        
    }

    RouteUserLogged=()=>{
        this.props.history.push('/UsuarioLogado');
    }

    closeUserLoggedModal=()=>{
        this.setState({userLogged:false});
    }

    mostrarModalUser =() => {
        return(
            <UserLoggedOptionsScreen 
                closeUserLoggedModal={this.closeUserLoggedModal} 
                location = {this.props}
                alteraNaoEntregamosAi={this.alteraNaoEntregamosAi}
                mudandoParaRota = {()=> this.MudandoEstadoCadastrar()} 
                mudandoParaRota2 ={()=> this.MudandoEstadoCadastrar2()}
                updateToEmptyAddresStandard={() => this.setState({enderecoEscolhido:[]})}
                selecionandoProdutosAdd={this.selecionandoProdutosAdd}
                resetTx_entrega={() =>  tx_entrega = resetTx_entrega }
                resetMessage={() => this.setState({naoEntregamosAi: false, revalidadorNaoEntregamosAi:false})}
                resetAddress = {() => this.setState({enderecos: []})}
            />
        )

    }

    mostrarModalUserInfo =() => {
        
        return(
            <UserLoggedInfoScreen 
                closeUserLoggedModal={this.closeUserLoggedModal} 
                location = {this.props}
                banco_cardapio={banco_cardapio}
                idd={'MeusDados'}
                alterandoQtdSacola = {() => this.alterandoQtdSacola()}
                alterandoTotalSacola = {() => this.alterandoTotalSacola()}
                mudandoParaRota = {()=> this.MudandoEstadoCadastrar()} 
                mudandoParaRota2 ={()=> this.MudandoEstadoCadastrar2()}
                fechandoEstadoCadastrarInfo = {() => this.fechandoEstadoCadastrarInfo()}
                mostrarLogin = {() => this.RotaLogin()}
                />
        )

    }

    verifyLoginState=()=>{
        this.verificandoTokenUsuario();
    }

    verificandoTokenUsuario = async() => {
        try {
            let token = getToken('TOKEN') ;
            if (token !== '' && token !== null) {
                this.alterandoRouteUserLogged();
            } else this.RotaLogin();


        } catch (error) {
            if (error.response.status === 401 || error.response.status === 404 ) {
                this.clearLocalStorage();
                this.alterandoQtdSacola();
                this.alterandoTotalSacola();
                this.RotaLogin();

            }
        }
    }

    alterandoRouteUserLogged = () => {
        this.setState({userLogged:true});
        this.RouteUserLogged();
    }

    alterarNome = (value) => {
        this.setState({nome: value}) 
    }

    selecionadoEndereco = async(item) => {
        const result = await this.alterarEndereco(item);
        this.mudandoEstadoEnderecoEscolhido([result]);
        return [result];

    }

    alterarEndereco = async (array) => {
        try {
    
            let cep              = array.cep
            let rua              = array.rua
            let bairro           = array.bairro
            let cidade           = array.cidade
            let numero           = array.numero
            let uf               = array.uf
            let complemento      = array.complemento
            let ponto_referencia = array.ponto_referencia
            let selecionado      = 'S'
            const service = new ServiceEndereco();
            const result  = await service.updateEnderecos(array.id,{
                cep,             
                rua,            
                bairro,          
                cidade,          
                numero,          
                uf,              
                complemento,    
                ponto_referencia,
                selecionado,
                banco: banco_cardapio
            });

            if(result.status === 200) {
                return result.data
            }
            else{
                this.setState({error: result.statusText});
            }

        } catch (errors) {
            console.log(errors) 
        }
    }

    getEnderecoSelecionado = async () => {
        try {

            const token = getToken('TOKEN')
            if(token !== null){
                const service = new ServiceEndereco();
                const result = await service.getEnderecoSelecionado({banco : banco_cardapio});

                if (result.status === 200) {    
                    if (result.data.length > 0 ) {
                        this.setState({enderecoEscolhido:result.data}); 
                    }
                    else{
                        this.setState({enderecoEscolhido:[]});
                    }
            
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    getAllEndereco = async () => {
        try {
            const service = new ServiceEndereco();
            const result  = await service.getAllEnderecos({banco : banco_cardapio});
 
            if (result.status === 200 ){
                await this.getEnderecoSelecionado();
                const AddState = result.data.map(item => {return {...item, isLoadingSpinner: false}})
                this.setState({enderecos: AddState});            
            }

            return result.data.length

        } catch (error) {
            this.setState({enderecos: [], enderecoEscolhido:[] });   

            if (error.response.status === 401) {
                this.props.history.push('/Login');
                this.setState({isLoadingEndereco: true, mostrarModalLogin:false});

            }


        }
    }

    fecharModalListagemEnderecos = () => {
        if ( this.props.location.pathname ==='/UsuarioLogado/ListarEnderecos') {
            this.setState({userLogged: true, mostrarModalSacola:true}); 
            this.props.history.push('/UsuarioLogado');
        }
        else{
            this.props.history.push('/Sacola');
            this.setState({mostrarModalSacola:false}); 
        }

        this.setState({isLoadingEndereco: true});

    }

    alterandoEstadoEndereco = () => {
        const {enderecos, isLoadingEndereco, userLogged} = this.state;

        if (userLogged){
            this.props.history.push('/UsuarioLogado/ListarEnderecos') ;  
        }
        else{
       
            if(isLoadingEndereco === true && enderecos.length > 0 ){
                this.setState({isLoadingEndereco: false});
                this.props.history.push('/Sacola/ListarEnderecos');
            }
            else if (enderecos.length === 0) {
                this.props.history.push('/Sacola/Endereco');
                this.mudandoEstadoEndereco();
            }
            else{
                this.setState({mostrarModalSacola:false})
                this.props.history.push('/Sacola'); 
            }
   
        }
 
    }

    mudandoEstadoEndereco = () => {
        this.setState({mostrarModalEndereco: false});    
   
    }

    alterarEstadoEnderecos = () => {
        this.setState({enderecos:[]});
    }

    mudandoEstadoEnderecoEscolhido =  (array) => {
        this.setState({enderecoEscolhido: array});
        this.updateStateAddress(array)
    }

    removeSpecials = (texto) => {
        // eliminando acentuação
        texto = texto.replace(/[ÀÁÂÃÄÅ]/,"A");
        texto = texto.replace(/[àáâãäå]/,"a");
        texto = texto.replace(/[ÈÉÊË]/,"E");
        texto = texto.replace(/[Ç]/,"C");
        texto = texto.replace(/[ç]/,"c");
        return texto.replace(/[^a-z0-9]/gi,' '); 
    }

    carregarProdutoNome =  (value) => {
        const {arraOfSearch} = this;
        this.setState({nome: value})
        const resultSpacials = this.removeSpecials(value);
       
        this.ProductName = value.length > 0 ? (arraOfSearch.filter((item) =>{
            const result = this.removeSpecials(item.nome);
            return  result.toLowerCase().includes(resultSpacials.toLowerCase());
        })): [];
    }

    updateEnderecos = (newArray, id) => {
        const {enderecos} = this.state;
        const arrayTemp = enderecos.filter(item => item.id === id)

        if (newArray.length === 0 || arrayTemp.length > 0) this.setState({enderecoEscolhido: []});
        this.setState({enderecos: newArray})
    
        
    }

    ListarEnderecos = () =>{
        const {
            produtosSacola, 
            enderecoEscolhido, enderecos, parametros, totalSacola,
            NomeFormaPagamento, revalidadorNaoEntregamosAi, message
        } = this.state;
        return(
            <ListarEnderecos
                fecharModalListagemEnderecos={() => this.fecharModalListagemEnderecos()}
                banco_cardapio= {banco_cardapio}
                taxaEntrega = {() => this.taxaEntrega()}
                idd={'ListagemEnderecos'}
                mudandoEstadoEndereco = {() => this.mudandoEstadoEndereco()}
                location = {this.props}
                enderecos={enderecos}
                getAllEndereco={() => this.getAllEndereco()}
                selecionadoEndereco={this.selecionadoEndereco}
                totalGeral={totalSacola}
                produtosSacola={produtosSacola}
                parametros={parametros}
                NomeFormaPagamento={NomeFormaPagamento}
                mostrarModalPedido={this.carregarPedidos}
                FecharModalSacola = {() => this.setState({mostrarModalSacola: true}) }
                FecharModalEnderecos={() => this.setState({isLoadingEndereco: true})}
                mostrarModalFormaPagamento={() => this.mostrarModalFormaPagamento()}
                enderecoEscolhido={enderecoEscolhido}
                revalidadorNaoEntregamosAi={revalidadorNaoEntregamosAi}
                message={message}
                selecionandoProdutosAdd={this.selecionandoProdutosAdd}
                alteraNaoEntregamosAi={() => this.setState({message:'', revalidadorNaoEntregamosAi:false, naoEntregamosAi: false})}
                updateEnderecos={this.updateEnderecos}
                updateStateAddress={this.updateStateAddress}
            />
        )
    }

    updateStateAddress = (array) => {
        const {enderecos} = this.state;

        enderecos.map((item) =>  {
            if(item.id === array[0].id){
                item.bairro = array[0].bairro;
                item.cep = array[0].cep;
                item.cidade = array[0].cidade;
                item.complemento = array[0].complemento;
                item.rua = array[0].rua;
                item.uf = array[0].uf;
                item.numero = array[0].numero;
                item.selecionado = array[0].selecionado;
                item.ponto_referencia = array[0].ponto_referencia;
       
            }
        });

        this.setState({enderecos: enderecos});
    }

    mostrarModalEnd = () => {
        const{
                NomeFormaPagamento, enderecos, produtosSacola, parametros,revalidadorNaoEntregamosAi, message, totalSacola
            } = this.state;

        return(
            <Endereco
                FecharModal={() => this.setState({mostrarModalEndereco:true})} 
                banco_cardapio= {banco_cardapio}
                idd={'Endereco'}
                FecharModalSacola = {() => this.setState({mostrarModalSacola: true}) }
                mostrarModalPedido={this.carregarPedidos}
                location = {this.props}
                getAllEndereco={() => this.getAllEndereco()}
                totalGeral={totalSacola}
                NomeFormaPagamento={NomeFormaPagamento}
                enderecos={enderecos}
                produtosSacola={produtosSacola}
                mostrarModalFormaPagamento={() => this.mostrarModalFormaPagamento()}
                selecionadoEndereco={this.selecionadoEndereco}
                FecharModalUnlogged = {() => this.setState({mostrarModalCadastrar: true})}
                taxaEntrega={this.taxaEntrega}
                parametros={parametros}
                selecionandoProdutosAdd={this.selecionandoProdutosAdd}
                fecharModalPagamento={() => this.fecharModalPagamento()}
                updateStateAddress={this.updateStateAddress}
                revalidadorNaoEntregamosAi={revalidadorNaoEntregamosAi}
                message={message}
                mudandoEstadoEnderecoEscolhido={this.mudandoEstadoEnderecoEscolhido}
           />

        )
    }

    mostrarMeusPedidos = () => {
        this.setState({mostrarModalDetalhesPedido:false});
    }   

    alterandoEstadoMostrarMeusPedidos = (item) => {

        this.setState({mostrarModalDetalhesPedido: false, arrayMeusPedidos:item});
        this.props.history.push('/DetalhesPedido');
    }

    fecharEstadoMostrarMeusPedidos = () => {
        this.setState({mostrarModalDetalhesPedido: true});
        this.props.history.goBack();
    }

    mostrarModalMeusPedidos = () => {
        const {arrayMeusPedidos, parametros} = this.state;
        return(
            <DetalhesPedido
                fecharEstadoMostrarMeusPedidos = {() => this.fecharEstadoMostrarMeusPedidos()}
                location={this.props.location}
                idd={'MeusPedidos'}
                arrayMeusPedidos={[arrayMeusPedidos]}
                cnpj={this.props.cnpj}
                parametros={parametros}           
            />
        )
    }

    fecharEstadoCancelarPedidos = () => {
        this.setState({ mostrarModalCancelarPedido: false});
    }

    mostrarCancelarPedido = () => {
        this.setState({mostrarModalCancelarPedido:true});
        this.props.history.push('/Pedidos/CancelarPedido');
    }
    
    mostrarModalCancelarPedido = () => {
        return(
            <CancelOrderScreen
                fecharEstadoCancelarPedidos = {() => this.fecharEstadoCancelarPedidos()}
                location={this.props.history}
                idd={'CancelarPedido'}
                parametros={this.state.parametros}
            />
        )
    }

    mostrarModalFormaPagamento = () => {
        this.setState({mostrarModalPagamento:false});
        const url = this.props.match.path;

        if (url === '/FormaPagamento') return this.props.history.push('/FormaPagamento');

        if (url === '/Sacola') return this.props.history.push('/Sacola/FormaPagamento');

        if(url === '/EscolhaSeuEndereco') return this.props.history.push('/EscolhaSeuEndereco/FormaPagamento');
    }

    alterarEstadoNomeFormaPagamento = () => {
        let NomeFormaPagamento = getToken('NOMEFORMAPAGAMENTO');
        this.setState({NomeFormaPagamento}); 
    } 

    fecharModalPagamento = () => {
        this.setState({mostrarModalPagamento: true});
        const url = this.props.match.path;
        if(url === '/EscolhaSeuEndereco/FormaPagamento') return this.props.history.push('/EscolhaSeuEndereco');
        this.props.history.push('/Sacola');
    }
    
    fecharEstadoSuporte = () => {
        this.setState({mostrarModalSuporte: false});
    }

    mostrarSuporte = () => {
        this.setState({mostrarModalSuporte:true});
        this.props.history.push('/Pedidos/CancelarPedido/Suporte');
    }

    mostrarModalSuporte = () => {
        return(
            <SupportScreen
                fecharEstadoSuporte = {() => this.fecharEstadoSuporte()}
                location={this.props}
                idd={'Suporte'}
                parametros={this.state.parametros}
            />

        )
    }

    mostrarModalPagamento = () => {
        const {NomeFormaPagamento, produtosSacola, parametros, enderecoEscolhido, totalSacola} = this.state;
        return(
            <FormaPagamento
                idd={'FormaPagamento'}
                totalGeral={totalSacola}
                produtosSacola={produtosSacola}
                enderecoEscolhido={enderecoEscolhido}
                parametros={parametros}
                fecharModalPagamento={() => this.fecharModalPagamento()}
                alterarEstadoNomeFormaPagamento = {() => this.alterarEstadoNomeFormaPagamento()}
                mostrarModalFormaPagamento = {() => this.mostrarModalFormaPagamento()}
                NomeFormaPagamento={NomeFormaPagamento}
                mostrarModalPedido={this.carregarPedidos}
                parametro ={tx_entrega}
                entrega={entrega}
                alterarEntrega={() => this.alterarEntrega()}
                router={this.props}
                FecharModalSacola = {() => this.setState({mostrarModalSacola: true}) }
                FecharModalEnderecos={() => this.setState({isLoadingEndereco: true})}
                selecionandoProdutosAdd={this.selecionandoProdutosAdd}
                FecharModalEndereco={() => this.setState({mostrarModalEndereco:true})} 
            />
        )
    }

    focusInputSearch = () => {
        const DivRoot = document.getElementById('root');
        const DivInputSearch = document.getElementById('search');
        const {y} = DivInputSearch.getBoundingClientRect();
        document.getElementById('input-search').focus();
        DivRoot.scrollBy(0, y)
    }

    updateStatePromotion = () => {
        this.setState({promotion: true})
    }

    left = async() => {
        try {
            const {countButton, arrowRight} = this.state;

            this.setState({countButton:countButton - 1 })
   
            const BoxPromotion = document.getElementById(`boxcontainerpromotion`);
            const element = document.getElementById(`boxpromotion${countButton - 1}`);

            if(element !== null) {
                const {x} = element.getBoundingClientRect();
    
                if (element.tabIndex  > 0 ) {
                    if(!arrowRight) this.setState({arrowRight: true});

                    BoxPromotion.scrollBy((x) -45 , 0) ;
                }
                else {
                    this.setState({arrowLeft: false, arrowRight: true});
                    BoxPromotion.scroll(0, 0) ;
                }
            }
        } catch (error) {
            
        }


    }

    right = async () => {
        try {
            const{lastProductCategory, countButton, arrowLeft} = this.state;
            this.setState({countButton: countButton < lastProductCategory ? countButton + 1 : lastProductCategory });
   
            const BoxPromotion = document.getElementById(`boxcontainerpromotion`);
            const element = document.getElementById(`boxpromotion${countButton + 1}`);

            const {x} = element.getBoundingClientRect();
            if(!arrowLeft) this.setState({arrowLeft: true});   
            BoxPromotion.scrollBy(x - 45, 0) ; 

        

        } catch (error) {
            console.log(error)
        }          
    }

    verifyScroll = () => {
        const BoxPromotion = document.getElementById(`boxcontainerpromotion`);
        if(BoxPromotion === null) return false
        if (BoxPromotion.scrollWidth <= BoxPromotion.offsetWidth +BoxPromotion.scrollLeft) {
            this.setState({arrowRight: false})
        }   
    }

    routerProductAdd = (item) => {    
        this.clearColumnProducts(item);

        this.setState({mostrarModalAdd: false})
    }

    clearColumnProducts = (item) => {
        produtoAdd = []

        // limpando os campos porque o react atualiza este estado mesmo ele sendo passado como props para outro componente por conta da referência;
        item.obs = '';
        item.precoUnitario = 0.00;
        item.totalComplemento = 0.00;
        item.total = 0.00;
        item.qtd = 1;

        if(item.complemento.length > 0) {
            item.complemento.map((item) => {
                item.cor = false;
                item.qtd2 = '0';
    
                item.itens.map((el) => {
    
                    el.fixo = 'N';
                    el.ischeck = true;
                    el.marcado = 'N';
                    el.qtd_escolhida = 0;
                    return el
                })

                return item
            });
        }

        

        produtoAdd.push(item);

        return produtoAdd
        
    }

    render () {
        const { categoria, 
                produtos, 
                mostrarModalAdd, 
                mostrarModalSacola, 
                mostrarModalLogin,
                mostrarModalCadastrar,
                searchActive,
                userLogged,
                mostrarModalPedido,
                isLoadingEndereco,
                mostrarModalEndereco,
                userLoggedInfo,
                mostrarModalPagamento,
                parametros,
                mostrarModalSobre,
                mostrarModalDetalhesPedido,
                mostrarModalCancelarPedido,
                mostrarModalSuporte,
                mostrarModalEsqueciSenha,
                arrowRight,
                arrowLeft,  
                promotion,
                banner,
                portfolio,
                productCategory,
                nome,
                totalSacola,
                qtdItemSacola
              } = this.state;

        return(
            <>
                <BoxInicial >
                    {
                        urlImg !== '' ? (
                            <Header 
                                parametro={parametros} 
                                urlImg={urlImg}
                                MostrarModalSobre={() => this.MostrarModalSobre()}
                                banner={banner}
                            />
                        )   
                        :(
                            null
                        )
                    }
                    
                    <S.Container isSearchButtonActived={searchActive} id='search' tabIndex={3}>
                        <S.SearchIcon>
                            <img src={IconSearch} alt="icone de uma lupa para busca" />
                        </S.SearchIcon>
                        <S.InputBox
                            id="input-search"
                            autocomplete="off"
                            placeholder="O que deseja?"
                            onClick={() => this.ActiveSearchs(true)}
                            onChange={(e) => this.carregarProdutoNome(e.target.value)}
                            value={nome}
                        />

                        <S.ClearSearchIcon
                            isSearchButtonActived={searchActive}
                            onClick={()=> (this.ActiveSearchs(false), this.setState({nome:''}), this.ProductName = [] )}
                        >
                            <img
                                src={IconClearSearch}
                                alt="icone de um x para limpar a barra de pesquisa"
                            />
                        </S.ClearSearchIcon>
                    </S.Container>


                    {/* {console.log(this.ProductName)} */}
                    {
                        this.ProductName.length > 0 ?(
                            <Searchitems
                                product={this.ProductName}
                                routerProductAdd={this.routerProductAdd}
                                urlImg={urlImg}
                            />
                        ) 
                        :(
                            <>
                                <Category
                                    category={categoria}
                                    getCoordenate={this.getCoordenate}
                                />

                                {
                                    promotion ? (
                                        <Boxcategory id='promotion'>
                                            <h2 >PROMOÇÃO</h2>
                                        </Boxcategory>
                                    )
                                    :(
                                        null
                                    )
                                }

                                {
                                    productCategory.length > 0 ? (
                                        <ProductPromotion
                                            arrowLeft={arrowLeft}
                                            arrowRight={arrowRight}
                                            left={this.left}
                                            right={this.right}
                                            verifyScroll={this.verifyScroll}
                                            routerProductAdd={this.routerProductAdd}
                                            productCategory={productCategory}
                                            urlImg={urlImg}
                                        />
                                    )
                                    :(
                                        null
                                    )
                                }
                                {
                                    produtos.length === 0 ? 
                                    (
                                        <Row className='spinner'>
                                            <Spinner animation="border" variant="primary" />
                                        </Row>
                                    )
                                    :(
                             
                                        <ListingProduct
                                            product={produtos}
                                            routerProductAdd={this.routerProductAdd}
                                            urlImg={urlImg}
                                        />
                                    )
                                }
                            </>
                        )
                    }

                    {
                        mostrarModalAdd === false ? (
                            <this.MostrarModalAdd/>
                        )
                        :(
                            null
                        )
                    }

                    {

                        mostrarModalSacola === false ? (
                            <this.mostrarModalSacola />
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalLogin === false ? (
                            <this.MostrarModalLogin/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalCadastrar === false ? (
                            <this.mostrarModalCadastrar/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        userLogged === true? (
                            <this.mostrarModalUser/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        userLoggedInfo === true? (
                            <this.mostrarModalUserInfo/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalPedido === false? (
                            <this.abrirModalPedido/>
                        ):(
                            null
                        )
                    }

                    {
                        isLoadingEndereco === false ? (
                            <this.ListarEnderecos/>
                        ) 
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalEndereco === false ? (
                            <this.mostrarModalEnd/>
                        )
                        :(
                            null
                        )
                    }

                    {   
                        mostrarModalPagamento === false ? (
                            <this.mostrarModalPagamento/>
                        )
                        :(
                            null
                        )
                            
                    }

                    {   
                        mostrarModalSobre === true ? (
                            <this.mostrarModalSobre/>
                        )
                        :(
                            null
                        )
                            
                    }

                    {
                        mostrarModalDetalhesPedido === false ? (
                            <this.mostrarModalMeusPedidos />
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalCancelarPedido === true ? (
                            <this.mostrarModalCancelarPedido/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalSuporte === true ? (
                            <this.mostrarModalSuporte/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalEsqueciSenha === true ? (
                            <this.MostrarModalEsqueciSenha/>
                        )
                        :(
                            null
                        )
                    }

                </BoxInicial>

                {
                    portfolio === 'N' ? (

                        <InfoBagBox totalPurchases={totalSacola} totalItemsBag={qtdItemSacola} openBag={()=> this.setState({mostrarModalSacola : false})} /> 
                    )
                    :(
                        null
                    )
                }
                    
                {
                    portfolio === 'N' ? (
                        <BoxInfoPedidos>
                            <BoxIconNavBar src={Pesquisar} alt={'ícone de pedidos'} onClick={() => (this.setState({searchActive:true}), this.focusInputSearch())}/>
                            <BoxIconNavBar src={Pedido} onClick={() => this.carregarPedidos()} alt='ícone de pedidos'/>
                            <ItemsBagButton totalItemsBag={qtdItemSacola} openBag={()=> this.setState({mostrarModalSacola : false})}/>
                            {
                                promotion ? (
                
                                    <BoxIconNavBar src={Promotion} alt={'ícone de promoção'} onClick={() => (this.removeClassButton(0), this.selectingPromotion())} /> 
                                )
                                :(
                                    <BoxIconNavBar src={Promotion} alt={'ícone de promoção'} onClick={() => alert('Ops, Estamos sem promoção no momento')} />  
                                )
                            }

                            <BoxIconNavBar src={Usuario} alt={'ícone de usuário'} onClick={() => getToken('TOKEN') === null? this.RotaLogin() : this.verifyLoginState()}/>
                        </BoxInfoPedidos> 
                    )
                    :(
                        null
                    )
                }
        </>
        )
    }
}
export default Headers
