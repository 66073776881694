import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const BoxSecaoProduto = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 60px;

  .containergrupoprodutos {
    justify-content: center;
    display: flex;
    width: 50%;
  }

  @media (min-width: 280px) and (max-width: 500px) {
    .containergrupoprodutos {
      width: 100%;
    }
  }
`;

export const BoxCard = styled.div`
  display: flex;
  width: 100%;
  height: 182px;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 9.22018px;

  
  margin: 0.5em 0.5em 0.5em 0.5em;
  overflow-x: hidden;
  background-color: ${props=>props.theme.background.White_2};
  
  .link {
    display: flex;
    height: 100%;
    width: 100%;
    text-decoration: none;
    padding: 1em;
    color: ${props=>props.theme.text.Black_1};
    
  }

  .row {
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    /* height: 7rem; */
    width: 7rem;
    margin-right: 18px;
  }

  span > img{
    border: 1px solid transparent;
    border-radius: 10px;
    width: 7rem;
    height: 7rem;
  }

  @media (min-width: 280px) and (max-width: 500px) {
    width: 100%;
  }
`;

export const BoxCardinternoItemsPromotion = styled.div`

  display: flex;
  height: 100%;
  width: 80%;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  
  .valuespromotion{
    display: flex;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    flex-wrap: wrap;
  }

  .iconpromotion{
    display: flex;
    justify-content: space-between;
  }

  .valuepromotion {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #C51F2A;
  }

  .valuenotpromotion{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: line-through;
    color: #999999;
  }

  .nomeproduto,
  .infoitem {
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .infoitem {
    color: rgb(113, 113, 113);
    font-weight: 300;
  }

`;


export const BoxCardinterno = styled.div`
  display: flex;
  height: 100%;
  width: 80%;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;


  .nomeproduto {
    font-size: 14px;
    line-height: 24px;
  }

  .valoritem {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }

  .nomeproduto,
  .infoitem {
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .infoitem {
    color: rgb(113, 113, 113);
    font-weight: 300;
  }

`;
