import React from "react";
import {Form, Row, Alert} from 'react-bootstrap';
import 
{
    BoxLogin, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxBotaoFotter,
    BoxModal,
    BoxImageHeader,
    FooterLogin,
} from './style' 

import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import imgFooterLogin from '../../assets/Login/imgFooterLogin.svg';
import imageLoginHeader from '../../assets/Login/ImageLoginHeader.svg'
import {getToken, logout} from '../../service/auth'

import { Spinner} from 'react-bootstrap'
import { login } from "../../Class/login";
import { FinishDelivery } from "../../Class/finishDelivery";
import { verifyMouse } from "../../functionsGlobal";



class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email:"",
            senha:"123456",
            error:"",
            newPassword:"",
            isNotLoadingLogin:true,
            disabled : false,
            password:false
        }
    }

    redirectUnlogged = () => {
        this.props.location.history.push('/Login/Cadastrar')
        this.props.MudandoEstadoSemLogin();
    }

    Logar = async() => {
        try {
            let statusLoja = this.props.parametros.map(item => item.status_cardapio);
 
            const {email, senha} = this.state;
            const Finish = new login();

            Finish.email          = email;
            Finish.senha          = senha;
            Finish.banco_cardapio = this.props.banco_cardapio;
            Finish.parametros     = this.props.parametros;
            Finish.totalGeral     = this.props.totalGeral;
            Finish.produtos       = this.props.produtosSacola;

            Finish.state ={
                getAllEndereco: this.props.getAllEndereco  
            }

            if (Finish.email.length < 14) return this.setState({error: 'Informe o telefone'});

            const validatePhoneNumber = Finish.ValidarTelefone(Finish.email);
            
            
            if (validatePhoneNumber === false) return this.setState({error: 'Telefone inválido'});


            this.setState({isNotLoadingLogin: false, disabled: true});
            // finalizar
            let resultLogin = await Finish.Logar();
       
            if( resultLogin.success === true ){      
                Finish.getProduto();
                await Finish.state.getAllEndereco();
                await this.props.taxaEntrega();

                this.setState({isNotLoadingLogin: true}); 
                const buttonWithdraw = getToken('WITHDRAW')
                const link = this.props.location.match.path;

                if(this.props.produtosSacola.length > 0 && link === '/Sacola/Login') {

                    if (statusLoja.toString() === 'N') {
                        return false;
                    } 

                    if (buttonWithdraw === 'false') {
                        this.props.mostrarModalEnderecos();
                        this.props.location.history.push('/EscolhaSeuEndereco');
                    }
                    else{
                        await this.finalizar();
                        Finish.removendoProdutosIndexDB();
                        this.props.selecionandoProdutosAdd();
                    }
                }
                else  this.props.location.history.push('/');
                

                this.props.FecharModalLogin();
            }
            else if  (resultLogin.newUser){
                this.pushRouter();
                this.motrarModalSemLogin()
                this.setState({isNotLoadingLogin: true,  disabled: false, error:resultLogin.message});
            }
            else {

                this.setState({isNotLoadingLogin: true,  disabled: false, error:resultLogin.message});
            }


        } catch (error) {
            console.log(error)
            this.setState({isNotLoadingLogin: true, disabled: false});
            // if ( error.response.status === 422 || error === 404 || error.response.data.success === false) {
            //     this.pushRouter();
            //     this.motrarModalSemLogin()
            // }
      
        }

    }

    finalizar = async() => {
        try {
            const token = getToken('TOKEN');
            const cnpj = this.props.cnpj;
            const hoje = new Date();
            const dia = hoje.getDate().toString().padStart(2,'0');
            const mes = String(hoje.getMonth() + 1).padStart(2,'0');
            const ano = hoje.getFullYear();
            const data = `${dia}/${mes}/${ano}`;    
            const hora = hoje.toLocaleTimeString();

            let troco_para = getToken('VALORTROCO');
            const formapag = getToken('FORMAPAGAMENTO');
            const cliente = getToken('CLIENTE');
            const numero_web = getToken('NUMERO_WEB');
            const CPF_CNPJ = getToken('CPF_CNPJ');
            const buttonWithdraw = getToken('WITHDRAW')

            const FinishDelivery1 = new FinishDelivery();

            if( token !== null) {  
                FinishDelivery1.cnpj = cnpj;
                FinishDelivery1.data = data;
                FinishDelivery1.hora = hora;
                FinishDelivery1.produtos = this.props.produtosSacola;
                FinishDelivery1.RETIRAR_LOJA = buttonWithdraw ==='true' ? 'S' :'N';
                FinishDelivery1.tx_entrega = buttonWithdraw === 'false' ? this.props.tx_entrega : 0.00;
                FinishDelivery1.numero_web = numero_web;
                FinishDelivery1.CPF_CNPJ = CPF_CNPJ;
                FinishDelivery1.subtotal = this.props.totalGeral;
                FinishDelivery1.total = buttonWithdraw === 'false' ? (this.props.totalGeral + this.props.tx_entrega) : this.props.totalGeral;
                FinishDelivery1.cliente = cliente;
                FinishDelivery1.formapag = formapag;
                FinishDelivery1.status = 'Aguardando o Estabelecimento Aceitar';
                FinishDelivery1.troco_para = troco_para;
                FinishDelivery1.pedidoMinimo = this.props.parametros[0].pedido_minimo;
                FinishDelivery1.enderecoEscolhido = this.props.enderecos;
                FinishDelivery1.NomeFormaPagamento = this.props.NomeFormaPagamento;
                FinishDelivery1.enderedoEntrega = this.props.parametros;
            
                const verificarPedidoMinimo = FinishDelivery1.verificarPedidoMinimo();
                

                if ( this.props.NomeFormaPagamento === '' || this.props.NomeFormaPagamento === null){
                    this.props.mostrarModalFormaPagamento();
                } 
                else if (verificarPedidoMinimo.success === false) {

                    alert(verificarPedidoMinimo.message);
                    this.props.location.history.push('/Sacola')
                    return {result: false, message: verificarPedidoMinimo.message};

                }else if  (this.props.totalGeral > 0){
                    

                    const result = await FinishDelivery1.salvarPedido1();

                    if (result.success === true ) {
                        
                        logout('WITHDRAW');
                        logout('FORMAPAGAMENTO');
                        logout('VALORTROCO');
                        logout('NOMEFORMAPAGAMENTO');
                        this.props.FecharModalSacola()  
                        this.props.mostrarModalPedido();
                        this.props.location.history.push('/Pedidos');
                    }
                } 

            }

        }catch(error){
            console.log(error)
        }
        
    }

    Messagem = () => {
        const {error} = this.state;
        return(
            <Alert variant='warning'>
                {error}
            </Alert>
        )
    }

    HandleLogin = async(event) => {
        event.preventDefault();
        this.Logar();
    }

    FecharModalLogin = (event) => {
        if (event.target.id === this.props.idd) {
            this.VoltarTelaAnterior();
        }

    }

    VoltarTelaAnterior = () => {
        this.props.FecharModalLogin();

        // refatorar colocando rotas adequadas
        if (this.props.stateSacola === false && this.props.enderecoEscolhido.length === 0 ) {
            this.props.location.history.push('/Sacola');   

        }
        else if(this.props.stateSacola === false){

            this.props.location.history.push('/Sacola');
        }
        else{
            this.props.location.history.push('/');   
        }
 
    }

    motrarModalSemLogin = () => {
        this.props.MudandoEstadoSemLogin();   
    }

    onChangeTelefone = (e) => {
        let valor = e

        valor = parseInt(valor.replace(/[\D]+/g, ''));

        if( isNaN(valor) ) {
            return ''
        }
        else {
            let resultMask = this.mascaraTelefone(valor.toString());

            if (!resultMask) {
                this.setState({error: resultMask.error})
            }
            else this.setState({error: resultMask.error})

            return  resultMask.valor
        }

    }

    ValidarTelefone = (e) => {
        const validTelefone = new RegExp('^\\(((1[1-9])|([2-9][0-9]))\\)((3[0-9]{3}-[0-9]{4})|(9[0-9]{3}-[0-9]{5}))$');
        return validTelefone.test(e)
    }

    mascaraTelefone = (e) => {
        let valor = e;
        if (valor) {
            valor = valor
            .replace(/\D/g, "")
            .replace(/(\d{2})(\d)/, "($1) $2")
            .replace(/(\d{4})(\d)/, "$1-$2")
            .replace(/(-\d{5})(\d+?)$/, "$1");
            valor = valor.replace(' ', '');
            let resultTelefone = this.ValidarTelefone(valor)
            if (resultTelefone) return {valor: valor, error:''}
            if (resultTelefone === false) return {valor: valor, error:'Telefone inválido'};  
        
        }  

    }

    removendoMascaraTelefone = () => {
        const {email} = this.state;
        let retirarMascara = email;

        retirarMascara = retirarMascara.replace('(', '')
        retirarMascara = retirarMascara.replace(')', '')
        retirarMascara = retirarMascara.replace('-', '')
        retirarMascara = retirarMascara.replace(' ', '')
        return retirarMascara;
    }

    pushRouter = () => {
        const url = this.props.location.location.pathname

        if (url ==='/Login') return this.props.location.history.push('/Login/Cadastrar')
        if (url ==='/Sacola/Login') return  this.props.location.history.push('/Sacola/Login/Cadastrar')
 
    }

    render() {
        const {error, isNotLoadingLogin, disabled} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onMouseDown={(event) => 
                    verifyMouse(event, this.FecharModalLogin)}  onMouseUp={(event) => verifyMouse(event, this.FecharModalLogin)}>
                    <BoxContainer id='container-login'>
                        <BoxHeader>
                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.VoltarTelaAnterior()} />
                            <h1>Informe seu número de telefone</h1>
                        </BoxHeader> 

                        <BoxLogin >
                            <BoxImageHeader>
                                <img src={imageLoginHeader} alt="imagem no topo com a logo do sistefood"/>
                            </BoxImageHeader>

                            <div className="login-text-wrapper">
                                <p>Informe seu número de telefone</p>
                            </div>

                            <Row>
                                <Form onSubmit={this.HandleLogin}>
                                    <p className="input-text-email">Telefone</p>
                                    <input 
                                        onChange={(e) => this.setState({email: this.onChangeTelefone(e.target.value)})}
                                        type={'tel'}
                                        placeholder={'(xx)xxxx-xxxxx'}
                                        id='numberPhoneLogin'
                                        maxLength='14'
                                        value={this.state.email}
                                        autoCapitalize='none'
                                        inputMode="numeric"
                                    />

                                    {
                                        error !== '' ? (
                                            <this.Messagem/>
                                        )
                                        :(
                                            null
                                        )   
                                    }

                                    <BoxBotaoFotter>
                                        <button type="submit" disabled={disabled}>
                                            Concluir
                                            {
                                                isNotLoadingLogin === false ? (
                                                  
                                                    <Spinner animation="border" variant="primary"   id='spinner'/>
                                                )
                                                :(
                                                    null
                                                )
                                            }
                                        </button>
                                    </BoxBotaoFotter>
                                    <FooterLogin>
                                        <p className="footer-text">Ambiente seguro</p>
                                        <img src={imgFooterLogin} alt="imagem de rodape sisteinfo"/>
                                    </FooterLogin>
                                </Form>
                            </Row>
                        </BoxLogin>            
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default Login;