import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import * as StylesGlobal from '../../shared/styles/styles';
import * as Styles from './styles';
import iconPromotion from '../../assets/iconPromotion.svg';
import { Row } from "react-bootstrap";

export const ListingProduct = (props) => {

    return(
        props.product.map(item => {
            return(
                <StylesGlobal.BoxContainer  key={item.id}className="categoriaitens" id={`category${item.id}`}>
                    <div   >
                        <h2 id={item.nome}>{item.nome}</h2>
                    </div>
                    <StylesGlobal.BoxSecaoProduto   >
                        {
                            item.itens.map((item) =>{
                                return(
                                    <div  className="containergrupoprodutos" key={item.id}>                    
                                        <StylesGlobal.BoxCard> 
                                            <Link className='link'  
                                                to={`/AdicionarProduto/${item.id}/${item.link_web}`}
                                                onClick={() => props.routerProductAdd(item)} 
                                            >
                                                <Row>
                                                    <LazyLoadImage
                                                        width={'100%'} 
                                                        src={`${props.urlImg}${item.foto_descricao}`}
                                                        // src={`https://www.sistefood.com.br/img/19651715000132/${item.foto_descricao}`}
                                                        alt={item.foto_descricao}
                                                        effect={'blur'}

                                                    />                                         
                                                </Row>

                                                {
                                                    item.oferta_web ==='S' ?(
                                                        <>
                                                            <StylesGlobal.BoxCardinternoItemsPromotion>
                                                                <p className="nomeproduto">{item.nome}</p>
                                                                <p className="infoitem">{item.descricao_web}</p> 
                                                                <div className="valuespromotion">
                                                                    
                                                                    <p className="valuepromotion">{`${parseFloat(item.preco_comparativo_web).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p> 
                                                                    <p className="valuenotpromotion">{`${parseFloat(item.preco_venda).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p> 
                                                                </div>
                                                            </StylesGlobal.BoxCardinternoItemsPromotion>
                                                            <div className="iconpromotion">
                                                                <Styles.IconPromotion src={iconPromotion} alt={'ícone de promoção'}/>
                                                            </div>    
                                                        </>
                                                    
                                                    )
                                                    :(
                                                        <StylesGlobal.BoxCardinterno>
                                                            <p className="nomeproduto">{item.nome}</p>
                                                            <p className="infoitem">{item.descricao_web}</p> 
                                                            <p className="valoritem">{`${parseFloat(item.preco_venda).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p>
                                                        </StylesGlobal.BoxCardinterno>
                                                    )
                                                }
                                                
                            
                                
                                            </Link> 
                                        </StylesGlobal.BoxCard> 
                                    </div>   
                                )
                            })
                        }
                    </StylesGlobal.BoxSecaoProduto>

                </StylesGlobal.BoxContainer>
            )
        })

    )
}