import {createGlobalStyle} from "styled-components"

const GlobalStyle = createGlobalStyle`
   
   * {
        padding: 0;
        margin:0;

        ::-webkit-scrollbar {
            width: 12px;               /* largura do scroll */
        }
        ::-webkit-scrollbar-track {
            background: #E6E3E3;        /* cor da area total do scroll */
        }

        ::-webkit-scrollbar-thumb {
            background-color: rgb(143, 133, 133);    /* cor do scroll*/
            border-radius: 20px;   
            border: 3px solid #E6E3E3;  /* criando bordas no scroll*/
        }
     }

    p{
        margin:0;
    } 

    html,body {
        font-family: 'Sora', sans-serif;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    #root{
        overflow: auto;
        width: 100%;
        height: 100%;
        scroll-behavior: smooth;
    }

`;

export default GlobalStyle;

    
