
export const createDb = async () => {

    try {
        const dbName = "cardapio";
        let db;
        
        // o primeiro pârametro do metodo open é o nome do banco e o segundo e a versão do banco
        let request = indexedDB.open(dbName, 2 );
        
        // request.onsuccess é executado caso não haja nenhum erro 
        request.onsuccess = function() {
            db = request.result;   
        }
        
        request.onupgradeneeded = async function(event)  {
            db = event.target.result;
        
            // Cria um objectStore para conter a informação sobre nossos clientes. Nós vamos
            // usar "ssn" como key path porque sabemos que é único;
            const  objectStoreProdutos = await db.createObjectStore("Produtos", {keyPath:"id_produto_key", autoIncrement:true});
            
          // Cria um índice para buscar clientes pelo nome. Podemos ter nomes
          // duplicados, então não podemos usar como índice único.
            objectStoreProdutos.createIndex("id_produto_key","id_produto_key", {unique: true});
        
        }

        
        // request.onerror é executado caso haja algum erro 
        request.onerror = (event) => {
            console.log('ocorreu um erro ' + event.target)
        }

        
    
    } catch (error) {
      console.log(error)  
    }


}

