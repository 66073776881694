import styled from "styled-components";

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  background: ${props=>props.theme.background.Primary};
  width: 100%;
  max-width: 520px;
  align-items: center;

  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background: ${props=>props.theme.background.Primary};
  height: 52px;
  border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
  }
`;

export const BoxIconeVoltar = styled.img`
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;

export const BoxIconAdvance = styled.img`
  width: 8px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 44px;
`;

export const ProfileWrapper = styled.div`
  width: 336px;
  height: 118px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
  margin-top: 23px;
`;

export const ProfilePicture = styled.div`
  width: 82px;
  height: 82px;
  border-radius: 50%;
  border: solid 2px ${props=>props.theme.colors.Grey_2};

`;

export const SectionsUserOptions = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: 1px solid ${props=>props.theme.colors.Grey_2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  
  .icon-text-wrapper {
    display: flex;
    color: ${props=>props.theme.text.Black_1};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    margin-left: 30px;
  }

  .icon-Sections-User {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
  }
`;
