import baseApi from './api'
import baseUrls from '../configs/baseURL'


class UsuarioService {
    constructor (){
        this.api = baseApi(baseUrls.API_USUARIO)
    }


    async getOne(modalUsuario) {
        const result = await this.api.get(`usuario/${modalUsuario.usuarioId}/${modalUsuario.banco}`);

        return result.data
    }

    async add(usuarioModel){
        const result = await this.api.post('usuario', usuarioModel)

        return result
    }

    async updateOne(usuarioModel, id){
        const result = await this.api.patch(`/usuario/${id}`, usuarioModel)

        return result
    }

    async login(usuarioModel){
        const result = await this.api.post('usuario/login', usuarioModel)
        return result
    }

    async ForgotPassword(usuarioModel){
        const result = await this.api.post('usuario/ForgotPassword', usuarioModel)
        return result
    }
}

export default UsuarioService;