import React from "react";
import { Spinner } from "react-bootstrap";
import {LogoClientFrame, Container,ContainerWrapper, LogoBannerClient, ContainerLogoBannerClient, Isloading, IsloadingLogoClientFrame } from "./style"

export const LogoClient = ({parametro, banner, urlImg}) =>{
    return (
    <Container>      
        {
           banner.length > 0 ? (
                <ContainerLogoBannerClient>
                    <LogoBannerClient  src={banner.map(item => `${urlImg}${item.foto_descricao}`)} alt ='Banner empresa' />
                </ContainerLogoBannerClient>
           )
           :(
                <ContainerLogoBannerClient>
                    <Isloading>
                        <Spinner animation="border" variant="primary" />
                    </Isloading>
                </ContainerLogoBannerClient>
           ) 
        }
     
        {
            parametro.length > 0 ? (
                <ContainerWrapper>
                    <LogoClientFrame  src={parametro.map(item => (`${urlImg}${item.site_url_logo}`))} alt ='Logo empresa' />  
                </ContainerWrapper>
            )
            :(
                <ContainerWrapper>
                    <IsloadingLogoClientFrame>
                        <Spinner animation="border" variant="primary" />
                    </IsloadingLogoClientFrame>
                </ContainerWrapper>
            )
        }

    </Container>
    )
}