import * as Style from  './styles'
import IconAddComplement from '../../../src/assets/AddProdutos/addComplement/IconAddComplement.svg'
import IconAddComplementDisabled from '../../../src/assets/AddProdutos/addComplement/IconAddComplementDisabled.svg'
import IconOK from '../../../src/assets/AddProdutos/addComplement/IconOK.svg'
import IconAnyLess from '../../../src/assets/AddProdutos/addComplement/IconAnyLess.svg'
import React from "react";

export const ProductComplement = ({complementProduct, updateComplementProduct, updateComplementProductItemsQtsAnyLess, updateTotalProduct, updateComplementProductItemsRadio, updateTotalProductRadio}) => {

    return(
        <Style.BoxContainer >
            {
                complementProduct.length > 0 ? (

                    complementProduct.map((item, itemIndex) => 
                        <Style.BoxMainProductComplement key={item.id} >
                            <Style.BoxProductComplementHeader id={`productComplement${item.id}`} tabIndex={itemIndex}>
                                <Style.BoxComplementProductGroup cor={item.cor}>
                                    <p className='nameGroup'>{item.nome}</p>
                                    <p className='nameGroupQtd' 
                                    >
                                        {`Escolha até ${item.qtd} ${item.qtd > 1 ? 'opções' : 'opção'}`}
                                    </p>
                                </Style.BoxComplementProductGroup>

                                <Style.BoxWrapperStatusGroup>
                                    {
                                        item.qtd === item.qtd2 ? (
                                            <Style.BoxOK>
                                                <img src={IconOK} alt='ícone de um sinal de V'/>
                                            </Style.BoxOK>
                                        )
                                        :(
                                            <>
                                                <Style.BoxStatusGroup statusGroup={item.tipo} cor={item.cor}>
                                                <p>{`${item.tipo ==='OB'? 'Obrigatório' : 'Opcional' }`}</p>
                                                </Style.BoxStatusGroup>
            
                                                <Style.BoxGroupQtd cor={item.cor}>
                                                    <p> {`${item.qtd > 0 ? item.qtd2 : 0 } / ${item.qtd}`}</p>
                                                
                                                </Style.BoxGroupQtd>
                                            </>
                                        )
                                    }
                                 
                                </Style.BoxWrapperStatusGroup>

                            </Style.BoxProductComplementHeader>  

                            {
                                item.itens.map((el, index) =>
                                    
                                    <Style.BoxContainerItems key={el.id}>
                                        <Style.BoxWrapperItems>

                                            {
                                                item.qtd && item.qtd === 1 ? (
                                                    <Style.BoxWrapperProductRadio  onClick={() => (updateComplementProductItemsRadio(el.marcado === 'N' ? 'S' : 'N', index, itemIndex), updateTotalProductRadio(el))}
                                                    
                                                        pointer={item.tipo === 'OB' && item.qtd === 1 ? true : false}
                                                    >
                                                        <Style.BoxProductName>
                                                            <p>{`${el.nome}  ${ el.preco > 0 ? ' + ' + parseFloat(el.preco).toLocaleString('pt-br', {style:'currency', currency:'BRL'}) : ''}`} </p>
                                                        </Style.BoxProductName>
                                                        
                                                        <Style.BoxProductRadio                         
                                                            off = {complementProduct[itemIndex].itens[index].marcado === 'S' ? true : false} 
                                                        >
                                                            <input type={'radio'} name={'radio'} />   
                                                            
                                                        </Style.BoxProductRadio>
                                                    </Style.BoxWrapperProductRadio>
                                                )
                                                :(
                                                    <>
                                                        <Style.BoxProductName>
                                                            <p>{`${el.nome}  ${ el.preco > 0 ? ' + ' + parseFloat(el.preco).toLocaleString('pt-br', {style:'currency', currency:'BRL'}) : ''}`} </p>
                                                        </Style.BoxProductName>
        

                                                        <Style.BoxWrapperItemsAdd>

                                                            {
                
                                                                el.qtd_escolhida > 0 ?(
                                                                    <>
                                                                        <Style.BoxProductmore onClick={() => (updateComplementProductItemsQtsAnyLess(el.qtd_escolhida, index, itemIndex), updateTotalProduct(- el.preco))}>
                                                                            <img src={IconAnyLess} alt='ícone de um sinal de menos'/>
                                            
                                                                        </Style.BoxProductmore>

                                                                        <Style.itemsqtd>
                                                                            
                                                                            {complementProduct[itemIndex].itens[index].qtd_escolhida }
                                                                        </Style.itemsqtd>
                                                                    </>
                                                                )
                                                                :(
                                                                    null
                                                                )
                                                            }
                                                
                                                            
                                                            <Style.BoxProductmore>
                                                                <button  disabled={item.qtd === item.qtd2 ? true : false} onClick={() => (updateComplementProduct(el.qtd_escolhida, index, itemIndex), updateTotalProduct(+ el.preco))}>

                                                                    {
                                                                        item.qtd === item.qtd2 ? (
                                                                            <img src={IconAddComplementDisabled} alt='ícone de um sinal de V'/>
                                                                        )  
                                                                        :(
                                                                            <img src={IconAddComplement} alt='ícone de um sinal de mais'/>
                                                                        )
                                                                        
                                                                    }
                                                            
                                                                </button>
            
                                                            </Style.BoxProductmore>

                                                        </Style.BoxWrapperItemsAdd>
                                                    </>
                                                )
                                            }

                                        </Style.BoxWrapperItems>
                                    </Style.BoxContainerItems>
                                )
                            }
                        </Style.BoxMainProductComplement>    
                    )
            )
                :(
                     null
                )
            }
        </Style.BoxContainer>
    )


}