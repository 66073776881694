import { FormatCurrency } from "../functionsGlobal"
import salvarPedido from '../service/produto'

export  class FinishDelivery {
    constructor(){
        this.cnpj = '';
        this.data = '';
        this.hora = '';
        this.produtos = [];
        this.pedido_produto = [];
        this.RETIRAR_LOJA = '';
        this.tx_entrega = '';
        this.numero_web = '';
        this.CPF_CNPJ = '';
        this.subtotal = 0.00;
        this.total = 0.00;
        this.logradouro = '';
        this.cliente = '';
        this.formapag = '';
        this.status = '';
        this.troco_para = '';
        this.pedidoMinimo = 0.00;
        this.NomeFormaPagamento = '';
        this.enderecoEscolhido = [];
        this.enderedoEntrega = []
        this.sistefood ='';
    }

    formatandoProdutosParaEnviarPedido = () => {
        this.produtos.map((item) => {
            let valor = item.precoUnitario;
            let total = item.total;
            total = total.toString().replace('.', ',');
            valor = valor.toString().replace('.', ',');
            let array = this.refatorandoArray(item);    
                this.pedido_produto.push({
                id_produto: item.id,
                qtd: item.qtd,
                valor,
                obs: item.obs,
                nome_web: item.nome,
                descricao_web: item.descricao_web,
                total,
                itens:array
            })

        })

    }

    refatorandoArray = (value) => {
        const array = [];
        let tipo

        if (value.complemento[0] !== undefined){
            value.complemento.map(item => {
   
                tipo = item.tipo
                delete item.id
                delete item.nome
                delete item.qtd
                delete item.qtd2
                delete item.stipo
                delete item.cor
                delete item.tipo
    
       
                item.itens.map(item => {
                    // console.log(item)
                    delete  item.id
                    delete  item.fixo
                    delete  item.marcado
                    delete  item.id
                    // delete  item.id_produto_acompanhamento
                    delete  item.id_produto_grupo_acompanhamento
                    delete  item.nome
                    delete  item.descricao
                    delete  item.pedidoId
                    delete  item.updatedAt
                    delete  item.createdAt
                    delete  item.usuarioId  
    
                    
                    if (item.qtd_escolhida > 0 &&  (tipo === 'OB' || tipo === 'OP') ){
                        array.push(item)

                    } 
                })
    
            })
            
            return array
        }
        else{
            return array 
        }
    }

    verificarPedidoMinimo = () => {
        if (this.subtotal < this.pedidoMinimo ) return {success: false, message:`o pedido mínimo é de ${FormatCurrency(this.pedidoMinimo)}`}
        else return {success: true, message:'ok'}
    }

    salvarPedido1 = async () => {
        try {

            this.formatandoProdutosParaEnviarPedido()
            const service = new salvarPedido();
            const result  = await service.salvarPedido({
                cnpj: this.cnpj,
                data: this.data,
                hora: this.hora,
                RETIRAR_LOJA:this.RETIRAR_LOJA,
                tx_entrega: this.tx_entrega,
                numero_web: this.numero_web,
                CPF_CNPJ: this.CPF_CNPJ,
                subtotal: this.subtotal.toString().replace('.', ','),
                total: this.total.toString().replace('.', ','),
                cliente: this.cliente,
                formapag: this.formapag,
                status: this.status,
                troco_para: this.troco_para,
                pedidoMinimo: this.pedidoMinimo,
                NomeFormaPagamento: this.NomeFormaPagamento,
                logradouro: this.RETIRAR_LOJA ==='S' ? this.enderedoEntrega[0].site_logradouro : this.enderecoEscolhido[0].rua,
                cep: this.RETIRAR_LOJA ==='S' ? this.enderedoEntrega[0].site_cep : this.enderecoEscolhido[0].cep,
                referencia: this.RETIRAR_LOJA ==='S' ? this.enderedoEntrega[0].site_referencia : this.enderecoEscolhido[0].ponto_referencia, 
                numero_endereco: this.RETIRAR_LOJA ==='S' ? this.enderedoEntrega[0].site_numero_endereco : this.enderecoEscolhido[0].numero,
                complemento: this.RETIRAR_LOJA ==='S' ? this.enderedoEntrega[0].site_complemento : this.enderecoEscolhido[0].complemento,
                bairro: this.RETIRAR_LOJA ==='S' ? this.enderedoEntrega[0].site_bairro : this.enderecoEscolhido[0].bairro,
                cidade: this.RETIRAR_LOJA ==='S' ? this.enderedoEntrega[0].site_cidade : this.enderecoEscolhido[0].cidade,
                sistefood:this.sistefood,
                pedido_produto: this.pedido_produto
            });

            if (result.success === true) {
                return {success: true, message: 'ok'}
            }
            else return {success: false, message: result.message}
            

        } catch (error) {
            console.log(error)
        }
    }

}