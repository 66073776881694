
import React from 'react'

import 
{
    BoxSemLogin, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxBotaoFotter,
    BoxModal,
    SignUpQuoteBox,
} from './style' 
import { Form, Row, Alert } from 'react-bootstrap'
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import UsuarioService from '../../service/cadastrarUsuario';
import { cpf } from 'cpf-cnpj-validator';
import { login } from '../../Class/login';
import { getToken } from '../../service/auth';
import { verifyMouse } from '../../functionsGlobal';

class SemLogin extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            nome: "",
            cpf_cnpj:"",
            email:"",
            telefone:"",
            senha:"123456",
            repetirSenha:"",
            error:"",
            backSpace:"vazio",
            mudandoCorBotao :false,
            repeatPassword:false,
            // password:false,
            disabled:true

        }
    }
    
    componentDidMount() {
        this.selectInputPhoneLogin();
    }

    selectInputPhoneLogin = () => {
        this.setState({disabled: false});
        const numberPhoneLogin = document.getElementById('numberPhoneLogin');
        this.setState({telefone: numberPhoneLogin.value})
    }

    visiblePasswordRepeatPassword = () => {
        this.setState({repeatPassword: !this.state.repeatPassword})
    }

    // visiblePassword= () => {
    //     this.setState({password: !this.state.password})
    // }

    HandleCadastrar = async(event) => {
        event.preventDefault();
        this.Cadastra();
    }

    mudandoCorBotao = () => {
    
        if ( this.state.nome === '' && this.state.telefone ===''){
            this.setState({mudandoCorBotao: false});
        }
        else{
            this.setState({mudandoCorBotao: true})
        }
    
        
    }

    removendoMascaraTelefone = () => {
        const {telefone} = this.state;
        let retirarMascara = telefone;

        retirarMascara = retirarMascara.replace('(', '')
        retirarMascara = retirarMascara.replace(')', '')
        retirarMascara = retirarMascara.replace('-', '')
        retirarMascara = retirarMascara.replace(' ', '')
        return retirarMascara;
    }

    Cadastra = async () => {
        try {
            const {senha, cpf_cnpj, nome, telefone} = this.state;
            if (!nome || !telefone) {
                this.setState({error:"Nome Completo e Número de telefone são obrigatórios"})
            } else {
                let validarTelefone =  this.ValidarTelefone(telefone);
                if (!validarTelefone) return this.setState({error:'Telefone inválido'});
                this.setState({disabled: true});

                const service = new UsuarioService();
                const result = await service.add({
                    email : this.removendoMascaraTelefone(),
                    senha,
                    nome,
                    telefone: this.removendoMascaraTelefone(),
                    cpf_cnpj,
                    banco:this.props.banco_cardapio
                })

                if (result.status === 201) {
                    const url = this.props.location.match.path;

                    const Finish = new login();
                    Finish.email = telefone;
                    Finish.senha = senha;
                    Finish.banco_cardapio = this.props.banco_cardapio;
                    Finish.parametros = this.props.parametros;
                    Finish.totalGeral = this.props.totalGeral;
                    Finish.produtos   = this.props.produtosSacola;

                    if (url === '/Sacola/Login/Cadastrar'){
                       
                        const resultLogin = await Finish.Logar();

                        if (resultLogin.success === true ) {    
                            const buttonWithdraw = getToken('WITHDRAW')
            
                            if (buttonWithdraw === 'false'){
                                this.props.mostrarModalEndereco();
                                this.props.location.history.push('/Informar/Endereco/Finalizar');
                            }
                            else{
                                await Finish.FinishDeliveryLogin();
                                Finish.removendoProdutosIndexDB();
                                this.props.selecionandoProdutosAdd();
                                this.props.FecharModalSacola();
                                this.props.mostrarModalPedido();
                                this.props.location.history.push('/Pedidos');
                            }

                            this.props.fecharModalLogin();
                            this.props.fecharModal();
                        }
                        else{
                            this.setState({disabled: false});
                        }
                    }
                    else{
                        const resultLogin = await Finish.Logar();

                        if (resultLogin.success === true ){
                            this.props.fecharModalLogin();
                            this.props.fecharModal();
                            this.props.location.history.push('/');
                        }  
                    }
                }
                else{
                    alert(result.data.message)
                }
            }            
        } catch (error) {
            this.setState({disabled: false});
            console.log(error)
        }
    }

    VoltarTelaAnterior = () => {
        this.props.FecharModalCadastrar();
        this.props.location.history.goBack();
    }

    FecharModal = (event) => {
        if (event.target.id === this.props.idd) {
            this.VoltarTelaAnterior();
        }
    }

    Messagem = () => {
        const {error} = this.state;
        return(
            <Alert variant='warning'>
                {error}
            </Alert>
        )
    }

    ValidarEmail = (e) => {
        const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        return validEmail.test(e)
    }

    ValidarTelefone = (e) => {
        const validTelefone = new RegExp('^\\(((1[1-9])|([2-9][0-9]))\\)((3[0-9]{3}-[0-9]{4})|(9[0-9]{3}-[0-9]{5}))$');
        return validTelefone.test(e)
    }

    onChangeEmail = (e) => {
        const result = this.ValidarEmail(e.target.value);
        if (result === false) {
            this.setState({error: 'email inválido', email:(e.target.value).trim()});
        }
        else{
            this.setState({error: ''})
            this.setState({email: (e.target.value).trim()});
        }
        this.mudandoCorBotao();

    }

    ValidarCPF = (valor) => {
        if (valor !== ''){
            let validar = valor;
            return cpf.isValid(validar)
        }
        return ''
    }

    onChangeCPF = (e) => {
        this.setState({backSpace: e.keyCode})

        if (parseInt(e.target.value)) {
            let result = '';
            if (e.target.value !== '') {
                result = this.ValidarCPF(e.target.value)
            }
            if (result === false) {
                this.setState({cpf_cnpj: e.target.value,  error: 'CPF inválido'})
            }
            else{   
                this.setState({error: ''})
                this.setState({cpf_cnpj: e.target.value})
            }
    
            
        }else{
            this.setState({error: ''})
            this.setState({cpf_cnpj: ''})
        }
        
        this.mudandoCorBotao();
    }   

    mascaraTelefone = (e) => {
        let valor = e;
        if (valor) {
            valor = valor
            .replace(/\D/g, "")
            .replace(/(\d{2})(\d)/, "($1) $2")
            .replace(/(\d{4})(\d)/, "$1-$2")
            .replace(/(-\d{5})(\d+?)$/, "$1");
            valor = valor.replace(' ', '');
            let resultTelefone = this.ValidarTelefone(valor)
            if (resultTelefone) this.setState({telefone: valor, error:''});  
            if (resultTelefone === false) this.setState({telefone: valor, error:'Telefone inválido'});  
        
        }  

        this.mudandoCorBotao();
    }

    onChangeTelefone = (e) => {
        let valor = e.target.value
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        if( isNaN(valor) ) {
            e.target.value= ''
            this.setState({telefone:  e.target.value}) ; 
            this.mudandoCorBotao();  
        }
        else {
            e.target.value= valor
            this.setState({telefone:  e.target.value}) ;
            this.mudandoCorBotao();
        }
        this.setState({telefone: valor})
        if (isNaN(valor)) this.setState({telefone: ''})


        if (valor.toString().length === 11)  this.mascaraTelefone(valor.toString())
        this.mudandoCorBotao();

    } 

    changeNome = (value) => {
        this.setState({nome: value})
        this.mudandoCorBotao();
    }

    changeCrieSenha = (value) => {
        this.setState({senha: (value).trim()})
        this.mudandoCorBotao();
    }

    render(){
        const {error, mudandoCorBotao, disabled} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onMouseDown={(e) => verifyMouse(e, this.FecharModal)}
                    onMouseUp={(e) => verifyMouse(e, this.FecharModal)}
                >
                    <BoxContainer>
                            <BoxHeader>
                                <BoxIconeVoltar src={IconeVoltar} onClick={() => this.VoltarTelaAnterior()}  />
                                <h1>Informações de contato</h1>
                            </BoxHeader>
                            <BoxSemLogin>
                                <SignUpQuoteBox>
                                    <div>
                                        <h1>Novo por aqui?</h1>
                                    </div>
                                    <div>
                                        <p>Preencha essas informações para prosseguir ; {')'}</p>
                                    </div>
                                </SignUpQuoteBox>
                                <Row>
                                    <Form onSubmit={this.HandleCadastrar}>
                                    
                                    <p className="input-text-nome">Nome Completo*</p>
                                    <input
                                        type="text"
                                        maxLength={50}
                                        onChange={(e) => this.changeNome(e.target.value) }
                                        value={this.state.nome}
                                    />

                                    <p className="input-text-nome">Número de telefone*</p>
                                    <input
                                        type="text"
                                        inputMode="numeric"
                                        maxLength={14}
                                        onChange={(e) => this.onChangeTelefone(e)}
                                        onBlur={(e)=> this.mascaraTelefone( e.target.value)}
                                        value={this.state.telefone}
                                    />    

                                    {
                                        error !== '' ? (
                                            <Row id='error'>
                                                {<this.Messagem error={error} />}
                                            </Row>    
                                        )
                                        :(
                                            null
                                        )
                                    }
                                    
                                    <BoxBotaoFotter mudandoCorBotao={mudandoCorBotao}>
                                        <button type="submit" disabled={disabled}>
                                            Cadastrar
                                        </button>
                                    </BoxBotaoFotter>
                                    </Form>
                                </Row>
                            </BoxSemLogin>
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default SemLogin