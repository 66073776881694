import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  background: ${props=>props.theme.background.Primary};
  width: 100%;
  max-width: 520px;
  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxIconCoin = styled.img`
  width: 24px;
  height: 17px;
  margin-right: 10px;
`;

export const BoxIconCard = styled.img`
  width: 24px;
  height: 17px;
  margin-right: 16px;
`;

export const BoxIconMoney = styled(BoxIconCard)`
  /* width: 24px;
  height: 17px;
  margin-right: 16px; */
`;

export const BoxNotIconCoin = styled(BoxIconCoin)`
  height: 24px;
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxCartao = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 32px;
  margin: 0 20px 0 20px;
  min-height: 88px;
  border: 1px solid ${(props) => props.cartao ? props.theme.colors.Red :  props.theme.colors.Grey_3};
  border-radius: 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  
    .forma-pagamento {
      display: flex;
      align-items: center;
      color: ${(props) => props.cartao ? props.theme.text.Red_1 : props.theme.text.Grey_1};
    }
`;

export const BoxDinheiro = styled(BoxCartao)`
  border: 1px solid ${(props) => props.dinheiro ? props.theme.colors.Red : props.theme.colors.Grey_3};

  .container-check {

    .forma-pagamento {
      color: ${(props) => props.dinheiro ? props.theme.text.Red_1 : props.theme.text.Grey_1};

    }
  }
`;

export const BoxComTroco = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  width: 163px;
  height: 44px;
  border: 1px solid ${(props) =>props.comTroco ? props.theme.colors.Red : props.theme.colors.Grey_3};
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: ${(props) => props.comTroco ? props.theme.text.Red_1 : props.theme.text.Grey_1};

  @media (width: 320px) and (max-width: 914px) {
    font-size: 11px;
  }

  /* p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${(props) => props.comTroco ? props.theme.text.Red_1 : props.theme.text.Grey_1};
  } */

`;

export const BoxSemTroco = styled(BoxComTroco)`
  border: 1px solid ${(props) => props.semTroco ? props.theme.colors.Red : props.theme.colors.Grey_3};
  color: ${(props) => props.semTroco ? props.theme.text.Red_1 : props.theme.text.Grey_1};
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 163px;
  height: 44px; */
  /* border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer; */

  /* p { */
    /* font-weight: 400;
    font-size: 14px;
    line-height: 18px; */
  /* } */

  /* @media (width: 320px) and (max-width: 914px) {
    font-size: 11px;
  } */
`;

export const WrapperBoxTotais = styled.div`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxTotais = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 520px;
  width: 454px;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #2f2f2f;
  border-top: 1px solid ${props=>props.theme.colors.Grey_2 };
  padding: 24px;
  background: ${props=>props.theme.background.Grey_3};

  #total-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 14px;
    border-top: 2px dashed ${props=>props.theme.colors.Grey_7};
    margin-top: 8px;
    padding-top: 16px;
  }

  #subtotal,
  #frete {
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  .total-delivery {
    color: ${(props) => props.activeWithdraw ? props.theme.text.Red_1 : props.theme.text.Black_1 };
  }

  @media (min-width: 280px) and (max-width: 914px) {
    width: 100%;
    margin: 0 12px 0 12px;
  }
`;

export const BoxTroco = styled.div`
  width: 100%;
  height: 74px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.comTroco ? props.theme.text.Black_1 : props.theme.text.Grey_1};
  /* color: ${props=> props.theme.text.Grey_1}; */


  /* p { */
    /* font-weight: 400; */
    /* font-size: 14px; */
    /* line-height: 18px; */
    /* color: ${(props) => props.comTroco ? props.theme.text.Black_1 : props.theme.text.Grey_1}; */
    /* margin-bottom: 8px; */
  /* } */

  input {
    margin-top: 8px;
    padding-left: 10px;
    height: 48px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid ${(props) => props.comTroco ? props.theme.colors.Grey_5 : props.theme.colors.Grey_3};
    color: ${(props) => props.comTroco ? props.theme.text.Black_1 : props.theme.text.Grey_1};
    background-color: ${(props) => props.comTroco ? props.theme.background.White_2 : props.theme.background.Primary};

    :focus {
      border: 1px solid ${(props) => props.comTroco ? props.theme.colors.Grey_5 : props.theme.colors.Grey_3};
      outline: none;
    }
  }
`;

export const BoxContainerTroco = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0;
  margin: 24px 0 24px;
  width: 100%;
  height: 44px;
`;

export const BoxPagueEntrega = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 34px 32px;
  width: 406px;
  height: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: black;
  
  /* p {
    padding: 0;
    margin: 0;
    color: black;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  } */
`;

export const BoxEntrega = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px 32px;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${props=>props.theme.colors.Grey_2};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  #pagamento-entrega {
    display: flex;
    align-items: flex-start;
    padding: 16px;
    height: 50px;
    border-bottom: 1px solid ${props=>props.theme.colors.Red};
    text-align: center; 
    color: ${props=>props.theme.text.Red_1}; 
    
    /* p {
      text-align: center;
      padding: 0;
      margin: 0;
      color: ${props=>props.theme.text.Red_1}; 
      font-weight: 400;
      font-size: 14px;
       line-height: 18px; 
    } */
  }
`;

export const BoxIconeVoltar = styled.img`
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background: ${props=>props.theme.background.Primary};
  height: 52px;
  border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};

  h1 {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
    /* margin: 0; */
    /* padding: 0; */
    /* justify-content: center; */
    /* align-items: center; */
  }
`;

export const BoxFormaPagamento = styled.div`
  padding: 0 12px 12px;
  margin-bottom: 12px;
  display: flex;
  overflow-x: auto;
  display: flex;
  width: 520px;
  flex-direction: column;
  padding-bottom: 1em;
  

  #linha-meio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 24px 32px 24px 32px;
    border-top: 1px solid ${props=>props.theme.colors.Grey_2};
  }

  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;

    input{
        font-size: 16px;
    } 

  }
`;

export const BoxBotaototal = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 73px;
  padding: 0.5em; 
  text-align: center;


  
  button {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.25rem;
    outline: none;
    border: none;
    box-shadow: 0 0 0 0;
    background: ${props=>props.theme.background.Red_1};
    color: ${props=>props.theme.text.White_1};
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;

  }

  #spinner{
    color:#FFF !important;
  }
`;

export const BoxQtdProduto = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  bottom: 0;
  width: 100%;
  max-width: 520px;
  justify-content: space-between;
  height: 73px;
  background: ${props=>props.theme.background.White_2};
  right: 0;
  padding: 1em;
  border-top: solid 1px ${props=>props.theme.colors.Grey_2};

  @media (min-width: 320px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

`;

export const Mark = styled.span`
  display: inline-block;
  position: relative;
  border: 1px solid ${props=>props.theme.colors.Grey_2};

  width: 14px;
  height: 14px;
  left: 0;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
  &::after {
    content: "";

    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: ${props=>props.theme.background.Red_1};
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }

`;

export const Input = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;

  &:checked + ${Mark} {
    border: 1px solid ${props=>props.theme.background.Red_1};
    
    &::after {
      width: 10px;
      height: 10px;
      opacity: 1;
      left: 10%;
      top: 10%;
    }
  }
`;

export const Label = styled.label`
  display: flex;
  cursor: pointer;
  padding: 5px 10px 5px 0;
  position: relative;
  margin: 0;
  border-radius: 50%;

`;