
let mousedown = '';
let mouseup   = '';

export const formatCompare = (value) => {

    let floatValue = value.toString();
    floatValue = floatValue.replace(/\D/g, '');// remove tudo que não é dígito, fica então o valor do value
    let money =   parseFloat(floatValue)/100; 

    return money
}


export function FormatCurrency  (value)  {
    let valor = value;
    
    let valorFormatado = valor.toLocaleString('pt-BR', { style: 'currency', currency:'BRL'});
    return valorFormatado
}


export const  removeCifrao = (value) => {

    let result = value.toString();
    result = result.replace('R$ ', '');
    // result = result.replace('R$', '');

    return result
}



export const verifyMouse = (event, functions) => {
    if(event.type === 'mousedown'){
        mousedown = event.target.id
    }

    if(event.type === 'mouseup'){
        mouseup = event.target.id;
        if (mousedown === mouseup){
            functions(event)
        }
    }

}   

export  {mousedown, mouseup}