export default {
    VIACEP: 'https://viacep.com.br/ws/',

    // SITE: 'http://192.168.1.74:9000/site/',   
    // CARDAPIO:'http://192.168.1.74:9000/cardapio/',
    // API_CNPJ_CARDAPIO: 'http://192.168.1.74:9000/app/',
    // API_USUARIO: 'http://192.168.1.74:4001/',  
    // // API_PRODUTOS: 'http://192.168.1.74:4003/', 
    // API_ENDERECOS: 'http://192.168.1.74:4002/', 
    // API_GOOGLE_MAPS:'http://maps.googleapis.com/maps/api/geocode/',
    // // API_GOOGLE_MAPS_DISTANCEMATRIX: 'http://192.168.1.74:4007/'


    ///@@ site
    SITE: 'https://api.sistefood.com.br:443/site/',
    CARDAPIO:'https://api.sistefood.com.br:443/cardapio/',   
    API_CNPJ_CARDAPIO: 'https://api.sistefood.com.br:443/app/',
    API_USUARIO: 'https://api.sistefood.com.br:4004/',  
    // API_PRODUTOS: 'https://api.sistefood.com.br:4005/', 
    API_GOOGLE_MAPS:'https://maps.googleapis.com/maps/api/geocode/',
    API_ENDERECOS: 'https://api.sistefood.com.br:4006/',
    // API_GOOGLE_MAPS_DISTANCEMATRIX: 'https://api.sistefood.com.br:4007/'


}   
 