import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  background: ${props=>props.theme.background.Primary};
  width: 100%;
  max-width: 520px;

  #nodelivery{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
  }

  #wrapper-container {
    display: flex;
    flex-direction: column;
    max-width: 520px;
    overflow-y: auto;
    height: 100%;
  }

  #spinner{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 320px) and (max-width: 520px) {
    left: 0;
    width: 100%;
  }
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background: ${props=>props.theme.background.Primary};
  height: 52px;
  border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
  }
`;

export const BoxTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 520px;
  justify-content: space-between;
  height: 100%;

  #meus-pedidos {
    padding: 32px 32px 16px;
    display: flex;
    height: 100%;
    max-width: 520px;
    align-items: center;
    flex-wrap: wrap;

    h1 {
      margin: 0;
      color: ${props=>props.theme.text.Black_1};
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
  }

  #info {
    display: flex;
    max-width: 520px;
    flex-wrap: wrap;
    height: 100%;
    flex-wrap: wrap;
    padding: 0 32px;

    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: ${props=>props.theme.text.Primary};
    }
  }

  @media (min-width: 320px) and (max-width: 520px) {
    height: 100%;
    #meus-pedidos,
    #info {
      width: 100%;
    }
  }
`;

export const BoxIconeVoltar = styled.img`
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;

export const BoxIconeNext = styled.img`
  width: 8px;
  height: 14px;
  display: flex;
  border: none;
  cursor: pointer;
`;

export const BoxInfoPedidos = styled.div`
  display: flex;
  max-width: 520px;
  flex-direction: column;
  height: 108px;
  padding-bottom: 24px;
`;

export const BoxContainerButton = styled.div`
  display: flex;
  align-items: center;
  max-width: 520px;
  margin: 16px 32px 0 32px;
  justify-content: center;
  height: 68px;
  border-bottom: 1px solid ${props=>props.theme.colors.Grey_2};
  padding-bottom: 16px;

  #separar {
    display: flex;
    width: 16px;
  }
`;

export const BoxContainerMostDateils = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 100%;
  height: 38px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: ${props=>props.theme.text.Red_1};
    cursor: pointer;
  }
`;

export const DeliveryOptionsButton = styled.div`
  width: 163px;
  height: 44px;
  border: 1px solid ${(props) => props.emAndamento || props.todosPedidos? props.theme.text.Red_1 : props.theme.text.Grey_1};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  cursor: pointer;

  .text-delivery {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${(props) => props.emAndamento || props.todosPedidos ? props.theme.text.Red_1 : props.theme.text.Grey_1 };
  }

  @media (min-width: 320px) and (max-width: 520px) {
    .text-delivery {
      font-size: 10px;
    }
  }
`;

export const BoxWrapperPedido = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  width: 100%;
  height: 100px;


  #status-pedido-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100%;
    gap: 4px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
  
    #status-pedido {
      font-weight: 400;
    }

    .wrapper-tempo-medio{
      display: flex; 
      width :100% ;
      height: auto;
      justify-content: center;
      flex-wrap: nowrap;
      text-align: center;

    }

    #tempo-medio-pedido {
      font-weight: 400;
      font-weight: 700;
      padding-left: 2px;
    }

    #numero-pedido {
      font-weight: 700;
      line-height: 17px;
    }
  }
`;

export const BoxStatusPedido = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  gap: 8px;
  background: ${(props)=> props.statusOrder};
  border-radius: 15px;
`;

export const TextWrapper = styled.p`
   color: ${(props)=> props.statusColorText === props.theme.text.Yellow_2 ? props.theme.text.Yellow_1: props.theme.text.White_1};
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
`;

export const BoxWrapperTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 0px 12px;
  margin-top: 12px;
  width: 100%;
  border-bottom: 1px solid ${props=>props.theme.colors.Grey_2};
`;

export const BoxWrapperTimerHour = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 10px;
  gap: 4px;
  width: 155px;

  .date-hour {
    display: flex;
    width: 100%;
    flex-direction: column;

    .text-date {
      color: ${props=>props.theme.text.Primary};
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    .text-date-hour {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const BoxWrapperInfoDelivery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 10px 12px;
  gap: 4px;
  width: 100%;
  margin-top: 12px;

  .deliverin {
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 5px;
    width: 74px;
    margin-bottom: 4px;
    color: ${props=>props.theme.text.Primary};

    .text-deliverin {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .text-deliverin-container {
    display: flex;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    gap: 10px;

    .text-deliverin-wrapper {
      display: flex;
      align-items: center;
      padding: 0px;
      gap: 5px;

      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: ${props=>props.theme.text.Black_1};

      }
    }
  }

  @media (min-width: 320px) and (max-width: 520px) {
    .text-deliverin-container {
      .text-deliverin-wrapper {
        width: 290px;
      }
    }
  }
`;

export const BoxContainerWrapperHelp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: auto;
  cursor: pointer;

  #container-cancel-delivery {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: 64.94px;
    border-width: 1px 0px;
    border-style: solid;
    border-color:${props=>props.theme.colors.Grey_2};

    #wrapper-cancel-delivery {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0px;
      gap: 68.7px;
      width: 100%;
      height: 64.94px;

      #icon-cancel-delivery {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10.4691px;
        gap: 6.54px;
        width: 31.41px;
        height: 32.94px;
        border-radius: 9.81481px;
      }

      #text-cancel-delivery {
        display: flex;
        align-items: center;
        padding: 0px;
        gap: 7.85px;
        width: 100%;
        height: 18px;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: ${props=>props.theme.text.Primary};

        }
      }
    }
  }
`;

export const BoxContainerHelp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: 227.81px;
  padding: 0 32px 0 32px;

  #text-help {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};

  }
`;

export const BoxWrapperHelp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
`;

export const BoxMostDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  height: 37px;
  width: 100%;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: ${props=>props.theme.text.Red_1};
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

export const BoxWrapperTotalPedido = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 10px;
  gap: 4px;
  width: 85px;
  height: 37px;

  .total-request {
    display: flex;
    width: 100%;
    flex-direction: column;

    .text-total {
      color: ${props=>props.theme.text.Primary};
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    .text-total-request {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const BoxContainerHistory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 20px;
  max-width: 520px;

  #text-history {
    display: flex;
    width: 100%;

    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: ${props=>props.theme.text.Black_1};
    }
  }
`;

export const BoxHistoryInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;
  width: 100%;
  height: 153px;
  border: 1px solid ${props=>props.theme.colors.Grey_2};
  border-radius: 10px;

  .wrapper-info-history {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 12px;
    gap: 4px;
    width: 100%;
    height: 71px;
    border-bottom: 1px solid ${props=>props.theme.colors.Grey_2};

    .info-header-history {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px;
      gap: 10px;
      width: 100%;
      height: 18px;

      .text-info-datahour {
        display: flex;
        align-items: center;
        padding: 0px;
        gap: 5px;
        width: 100%;
        height: 18px;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: ${props=>props.theme.text.Black_1};
        }

        .datahour-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .wrapper-total-text {
            display: flex;
            p {
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              color: ${props=>props.theme.text.Black_1};
            }
          }
        }
      }
    }
  }
`;

export const BoxDeliveryStatusContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100%;
`;

export const BoxDeliveryStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;
  width: 120px;
  height: 37px;

  .info-text {
    display: flex;
    width: 100%;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: ${props=>props.theme.text.Primary};
    }
  }

  .text-number-delivery {
    display: flex;
    width: 100%;
    
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: ${props=>props.theme.text.Primary};
    }
  }
`;

export const BoxContainerStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin: 24px 32px 32px;
  height: 295px;
  border: 1px solid ${props=>props.theme.colors.Grey_2};
  border-radius: 10px;
`;
