import baseURL from "../configs/baseURL";
import baseApi from "./api";

class ServiceProduto {
    constructor(){
        this.api = baseApi(baseURL.SITE);
        this.apiprodutoAcompanhamento = baseApi(baseURL.CARDAPIO);
    }

    async getAllProdutosCardapio(modelProduto){
        const result = await this.api.post('produtoCardapio', modelProduto)
        
        return result.data;
    }

    async getAllProdutos(modelProduto){
        const result = await this.api.post('produto', modelProduto)
        
        return result.data;
    }

    async salvarPedido(modelProduto){
        const result = await this.api.post('salvarPedido', modelProduto)
        
        return result.data;
    }

    async historicoPedido(modalProduto){
        const result = await this.api.post('historicoPedido', modalProduto);

        return result.data;
    }

    async produtoAcompanhamento(produtoAcompanhamento){
        const result = await this.apiprodutoAcompanhamento.post('produtoAcompanhamento', produtoAcompanhamento);

        return result.data;
    }
}


export default ServiceProduto;