import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  background: ${props=>props.theme.background.White_2};

  max-width: 520px;
  width: 100%;

  @media (min-width: 320px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background: ${props=>props.theme.background.Primary};
  height: 52px;
  border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
  }
`;

export const FooterLogin = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 42px;
  align-items: center;
  .footer-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${props=>props.theme.text.Primary};
    margin-right: 12px;
  }
`;

export const BoxImageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: flex;
    width: 100%;
  }
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxLogin = styled.div`
  padding: 0px 32px 32px;
  display: flex;
  overflow-x: auto;
  display: flex;
  align-items: center;
  max-width: 520px;
  width: 100%;
  flex-direction: column;
  padding-bottom: 1em;
  justify-content: start;
  
  .wrapper-passaword{
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 8px;
  }


  .login-text-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: ${props=>props.theme.text.Black_1};
    margin: 26px 0px;
  }

  .row {
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: center;

    .forgot-password-text-wrapper {
      display: flex;
      justify-content: end;
    }

    .input-text-password,
    .input-text-email {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: ${props=>props.theme.text.Black_1};
    }

    .forget-password-text {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: ${props=>props.theme.text.Primary};
      margin-right: 14px;
      text-decoration: underline;
    }

    form {
      width: 100%;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }

    input {
      color: #2f2f2f;
      height: 48px;
      width: 100%;
      border-radius: 10px;
      border: 1px solid ${props=>props.theme.colors.Grey_3};;
      font-size: 14px;
      line-height: 12px;
      font-weight: 400;
      background: ${props=>props.theme.background.White_2};
      outline: none;
      padding: 15px 12px;
      margin-bottom: 16px;
      margin-top: 8px;

      :focus {
        border: 2px solid black;
      }
    }

    .senha {
      margin-bottom: 8px;
    }

    .signup-wrapper {
      margin-top: 26px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      padding-top: 32px;
      border-top: 1px solid ${props=>props.theme.colors.Grey_3};

      .signup-link-text {
        text-decoration: underline;
        margin-left: 6px;
      }
    }

    #link {
      cursor: pointer;
      color: ${props=>props.theme.text.Red_1};
    }

    .senha{
      margin-bottom:0;
      margin-top:0;
    }
  }

  @media (min-width: 320px) and (max-width: 520px) {
    width: 100%;

    .row{
      input{
        font-size: 16px;
      }
    }
  }
`;

export const BoxIconeVoltar = styled.img`
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;

export const BoxBotaoFotter = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 48px;
  margin-top: 25px;
  flex-direction: column;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    outline: none;
    border: none;
    box-shadow: 0 0 0 0;
    background: ${props=>props.theme.background.Black_1};
    color: ${props=>props.theme.text.White_1};
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    #spinner{
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${props=>props.theme.text.White_1} !important;
    }
  }

  #cadatrar {
    width: 100%;
    background: red;
  }
`;
