import { entrar, getToken } from "../service/auth";
import UsuarioService from "../service/cadastrarUsuario";
import ServiceProdutoApiNode from '../service/produtosApiNode'
import { FinishDelivery } from "./finishDelivery";

export class login {
    constructor(){
        this.email = "";
        this.senha = "123456";
        this.newPassword = "";
        this.password = false;
        this.banco_cardapio ='';
        this.productComplement = [];
        this.produtos=[];
        this.totalGeral = 0.00;
        this.parametros=[]

        this.state = {

        }
    }

    ValidarTelefone = (value) => {
        const validTelefone = new RegExp('^\\(((1[1-9])|([2-9][0-9]))\\)((3[0-9]{3}-[0-9]{4})|(9[0-9]{3}-[0-9]{5}))$');
        return validTelefone.test(value)
    }

    removendoMascaraTelefone = () => {
        let retirarMascara = this.email;

        retirarMascara = retirarMascara.replace('(', '')
        retirarMascara = retirarMascara.replace(')', '')
        retirarMascara = retirarMascara.replace('-', '')
        retirarMascara = retirarMascara.replace(' ', '')
        return retirarMascara;
    }

    getProduto =  () => {
        let db ; 
        const request = indexedDB.open('cardapio', 2 );  
            request.onsuccess = () => {
            db = request.result;
            const transaction = db.transaction(["Produtos"], "readonly")
            const objectStore = transaction.objectStore("Produtos")
            const result = objectStore.getAll();
            
            result.onsuccess =  () => {
                let resultadoArray = result.result
                db.close();                        
            }
        } 
        
    }

    removendoProdutosIndexDB = () => {

        let db ; 
        const request = indexedDB.open('cardapio', 2 );  

        request.onsuccess = () => {
            db = request.result;
            const transaction = db.transaction(["Produtos"], 'readwrite')
            const objectStore = transaction.objectStore("Produtos")
            const result = objectStore.getAll();
        
            result.onsuccess = () => {

                for (let index = 0; index < result.result.length; index++) {
                    objectStore.delete(result.result[index].id_produto_key)

                }

            }
        }
        

    }

    Logar =  async () => {
        try {            

            if (this.senha.length < 6){
                return {result: false ,message:"o campo senha deve ter 6 caracteres"}
            }  
            else if (!this.email ) {
                return {result: false ,message:"Informe o campo telefone!"}

            }
            else{
                let resultPhone ;
                if(this.ValidarTelefone(this.email) === false) return resultPhone = {result:true, message: 'Telefone inválido'} 
            
                const service = new UsuarioService();
                const result = await service.login({
                    email: this.removendoMascaraTelefone(),
                    senha: this.senha,
                    banco:this.banco_cardapio
                });

                if ( result.status === 200 ) {
                    entrar('IDUSUARIO', result.data.idUsuario);
                    entrar('TOKEN', result.data.token);
                    entrar('CLIENTE', result.data.nome);
                    entrar('NUMERO_WEB', result.data.telefone);
                    entrar('CPF_CNPJ', result.data.cpf_cnpj);
                    return {success:true, message:'Ok', newUser: false};

                }
                else if (result.success === false) {

                    return {success:true, message: result.message, newUser: false}
                }

            }
            
        } catch (error) {
            if(error.response.data !== undefined){
                const {success, message} = error.response.data;
                if(error.response.status === 404) {
            
                    return {success:false, message: '', newUser: true}
                }    

            }
            else if(error.response.data === undefined) {
                console.log(error.response)
    
                return {success :false, message: 'Ocorreu um problema para conectar ao servidor! Tente novamente.', newUser: false}
            }
            
        }
    }

    FinishDeliveryLogin = async() => {
        const token = getToken('TOKEN');
        const cnpj = getToken('CNPJ');
        const hoje = new Date();
        const dia = hoje.getDate().toString().padStart(2,'0');
        const mes = String(hoje.getMonth() + 1).padStart(2,'0');
        const ano = hoje.getFullYear();
        const data = `${dia}/${mes}/${ano}`;    
        const hora = hoje.toLocaleTimeString();

        let troco_para = getToken('VALORTROCO');
        const formapag = getToken('FORMAPAGAMENTO');
        const cliente = getToken('CLIENTE');
        const numero_web = getToken('NUMERO_WEB');
        const CPF_CNPJ = getToken('CPF_CNPJ');
        const NomeFormaPagamento = getToken('NOMEFORMAPAGAMENTO')


        const FinishDelivery1 = new FinishDelivery();
        
        if( token !== null) {  

            FinishDelivery1.cnpj = cnpj;
            FinishDelivery1.data = data;
            FinishDelivery1.hora = hora;
            FinishDelivery1.produtos = this.produtos;
            FinishDelivery1.RETIRAR_LOJA = 'S';
            FinishDelivery1.tx_entrega =  0.00;
            FinishDelivery1.numero_web = numero_web;
            FinishDelivery1.CPF_CNPJ = CPF_CNPJ;
            FinishDelivery1.subtotal = this.totalGeral;
            FinishDelivery1.total = this.totalGeral 
            FinishDelivery1.cliente = cliente;
            FinishDelivery1.formapag = formapag;
            FinishDelivery1.status = 'Aguardando o Estabelecimento Aceitar';
            FinishDelivery1.troco_para = troco_para;
            FinishDelivery1.pedidoMinimo = this.parametros[0].pedido_minimo;
            FinishDelivery1.enderecoEscolhido = this.enderecoEscolhido;
            FinishDelivery1.NomeFormaPagamento = NomeFormaPagamento;
            FinishDelivery1.enderedoEntrega = this.parametros;


            const verificarPedidoMinimo = FinishDelivery1.verificarPedidoMinimo();

            if (verificarPedidoMinimo.success === false) return alert(verificarPedidoMinimo.message);

            if (this.totalGeral > 0) await FinishDelivery1.salvarPedido1();
            
        }
    }

}