import React from "react";

import 
{
    BoxLogin, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxModal,
    BoxImageHeader,
    BoxSuportInfo,
    Footer,
} from './style' 

import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import imageHelp from '../../assets/ImageSupport/logoHelp.svg'
import imageFooter from '../../assets/ImageFooter/imageFooter.svg'
import { verifyMouse } from "../../functionsGlobal";

class CancelOrderScreen extends React.Component {
 

    FecharModalLogin = (event) => {
        if (event.target.id === this.props.idd) {
            this.VoltarTelaAnterior();
        }
    }

    VoltarTelaAnterior = () => {
        this.props.fecharEstadoCancelarPedidos()
        this.props.location.goBack();    

    }

    motrarModalSemLogin = () => {
        this.props.MudandoEstadoCadastrar();   
    }

    render() {
        return(
            <>
                <BoxModal id={this.props.idd} onMouseDown={(e) => verifyMouse(e, this.FecharModalLogin)}
                    onMouseUp={(e) => verifyMouse(e, this.FecharModalLogin)}
                >
                    <BoxContainer>

                        <BoxHeader>
                            <BoxIconeVoltar src={IconeVoltar}  onClick={() => this.VoltarTelaAnterior()}/>
                            <h1>Cancelar</h1>
                        </BoxHeader> 

                        <BoxLogin>
                            <BoxImageHeader>
                                <img src={imageHelp} alt=""/>
                            </BoxImageHeader>

                            <div className="login-text-wrapper">
                                <p>Deseja cancelar o pedido?</p>
                            </div>

                            <BoxSuportInfo>
                                <div className="logo-image-wrapper">
                                    <img src={this.props.parametros.map(item => item.site_url_logo)} alt={this.props.parametros.map(item => item.site_url_logo)} />
                                </div>
                                <h4>Ligue para o número</h4>
                                <p>{this.props.parametros.map(item => item.site_telefone)}</p>
                            </BoxSuportInfo>
                            <div className="info-wrapper">
                                <p>*Esse pedido será realizado e entregue por: <strong>{this.props.parametros.map(item => item.site_nome_empresa)}</strong></p>
                            </div>
                            
                            <Footer>
                                <img src={imageFooter} alt={''}/>
                            </Footer>

                        </BoxLogin>
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default CancelOrderScreen;