import React from "react";

import 
{
    BoxLogin, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxModal,
    AboutTextWrapper,
} from './style' 

import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import { verifyMouse } from "../../functionsGlobal";

class AboutScreen extends React.Component {
   
    FecharModalLogin = (event) => {
        if (event.target.id === this.props.idd) {
            this.VoltarTelaAnterior();
        }
    }

    VoltarTelaAnterior = () => {
        this.props.location.history.goBack();
        this.props.FecharModalSobre()    
    }

    render() {
        return(
            <>
                <BoxModal id={this.props.idd} onMouseDown={(e) => verifyMouse(e,this.FecharModalLogin)}
                    onMouseUp={(e) => verifyMouse(e, this.FecharModalLogin)}
                >
                    <BoxContainer>
                        <BoxHeader>
                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.VoltarTelaAnterior()} />
                            <h1>Sobre</h1>
                        </BoxHeader> 

                        <BoxLogin>
                            <AboutTextWrapper>
                                <p>
                                    {this.props.About}
                                </p>
                            </AboutTextWrapper>
                            
                            <div className="login-text-wrapper">
                                <h3>Endereço</h3>
                                <p>{this.props.Address}</p>

                            </div>

                            <div className="login-text-wrapper">
                                <h3>Contato</h3>
                                <p>{this.props.phone}</p>

                            </div>
                        </BoxLogin>
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default AboutScreen;