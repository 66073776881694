import React from 'react'
import {Form, Col, Row, Alert} from 'react-bootstrap'
import {
    BoxEndereco, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxQtdProduto,
    BoxBotaototal,
    BoxModal,
    BoxImageHeader,
    SignUpQuoteBox
} from './style'

import { Spinner} from 'react-bootstrap'
import ServiceCep from '../../service/cep';
import ServiceEndereco from '../../service/enderecoApiNode';
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import { getToken, logout } from '../../service/auth';
import imageAddressList from '../../assets/ImageBackGroundLocation/imageAddressList.svg';
import { FinishDelivery } from '../../Class/finishDelivery';
import { verifyMouse } from '../../functionsGlobal';
import { login } from '../../Class/login';

let frete = 0;
let selectedAddress = [];
let selecionado = '';

class Endereco extends React.Component{
    constructor(props){
        super(props)

        this.state ={
            usuarioId:0,
            cep:'',
            rua:'',
            bairro:'',
            cidade:'',
            numero:'',
            uf:'',
            complemento:'',
            ponto_referencia:'',
            arrayCep:[],
            error:'',
            isLoadingEndereco: false,
            disabled: false,
            cepInvalido:''
        }

    }

    componentDidMount() {
        this.carregarParaEdicao();
    }

    componentWillUnmount() {
        frete = 0;
        selectedAddress = [];
        selecionado = '';
    }

    mountingAddress = () => {
        let userAddres = JSON.parse(getToken('ARRAYADDRESSUSER'));
        if (userAddres.length > 0 ){
            this.setState({
                // numero: userAddres[0].short_name,
                rua:    userAddres[0].long_name,
                bairro: userAddres[1].long_name,
                cidade: userAddres[2].long_name,
                uf:     userAddres[3].short_name,
                cep:    userAddres[5].long_name.replace('-', ''),

            })

        } 
    }

    selecteAddress = () => {
        const {params: {id}} = this.props.location.match;
        const {enderecos} = this.props;

        const result = enderecos.filter((item) => {
            if(item.id === parseInt(id)){
                return item
            }
        })

        return result;
    }

    carregarParaEdicao = async() => {
        try {
            const result = this.selecteAddress();
            if(result.length > 0 ){
                selecionado = result[0].selecionado;
                this.setState({
                    usuarioId: getToken('IDUSUARIO'),
                    cep: result[0].cep,
                    rua: result[0].rua,
                    bairro: result[0].bairro,
                    cidade: result[0].cidade,
                    numero: result[0].numero,
                    uf: result[0].uf,
                    complemento: result[0].complemento,
                    ponto_referencia: result[0].ponto_referencia,
                });

            }

        } catch (error) {
            
        }
    }

    FecharModal = (event) => {
        if (event.target.id === this.props.idd){
            this.FecharModalEndereco();
        }
    }

    FecharModalEndereco = () => {
        this.props.FecharModal()
        const url = this.props.location.match.path;
        if(url ===  '/Informar/Endereco/Finalizar')   this.props.location.history.push('/Sacola');
        else if(url === '/UsuarioLogado/ListarEnderecos/Endereco') this.props.location.history.push('/UsuarioLogado/ListarEnderecos');
        else if(url === '/UsuarioLogado/ListarEnderecos/:id/Endereco') this.props.location.history.push('/UsuarioLogado/ListarEnderecos');
        else this.props.location.history.goBack();
        
    }

    carregarCep = async(value) => {
        try {
            const service = new ServiceCep();
            const result = await service.getOneCep(value)
            if (result.statusText ==="OK") {
                if(result.data.erro !== undefined){
                    return this.setState({error: 'Cep inválido', cepInvalido:'Cep inválido'})
                }
                else{
                    this.jogandoResultaCep(result.data);   
                    return this.setState({error: '', cepInvalido:''})
                }
            }

        } catch (error) {
        
        }
    }

    BuscarCEP = (event) => {
        if (event.keyCode === 13 || event.type === 'blur') this.carregarCep(event.target.value)
    }

    onChangeCEP = (e) => {
        let valor = (e.target.value).trim()

        if( isNaN(valor) ) {
            e.target.value= ''
            this.setState({cep:  e.target.value}) ;   
        }
        else {
            e.target.value = valor
            this.setState({cep:  valor}) ;
        }
        this.setState({cep: valor})
        if(valor.length === 8) {
            this.carregarCep(e.target.value);
        }
        if (isNaN(valor)) this.setState({cep: ''})
    }

    jogandoResultaCep = (result) =>{
        const array = [];
        array.push(result)
        this.setState({arrayCep : array})
        const {arrayCep} = this.state

        if (result.erro !== true) {
            arrayCep.map(item => 
                this.setState({
                    rua: item.logradouro,
                    bairro: item.bairro,
                    complemento : item.complemento,
                    cidade: item.localidade,
                    uf: item.uf,
                })
            )

        }
    } 

    HandleEndereco =  (event) => {
        event.preventDefault();
        const {cep, rua, bairro, cidade, numero, uf, cepInvalido } = this.state;
        const {params:{id}} = this.props.location.match;

        if(cepInvalido !== '') return  this.setState({error: 'Cep inválido'})
        if (cep.length === 8){  
            if((uf).length < 2) return  this.setState({error: 'O campo UF deve conter duas letras. Exemplo: PE'})
            if (!rua || !bairro || !cidade || !numero || !uf ){
                this.setState({error: 'Os campos: rua, bairro, cidade, número e uf são obrigatórios'})
            }   
            else if (id > 0) {
                this.alterarEndereco();
            } 
            else {
                this.addEndereco();
            }       
        }
        else{
            this.setState({error: 'Informe o campo CEP'}) 
        }      
    }

    finalizar = async() => {
        try {
            const token = getToken('TOKEN');
            const cnpj =  getToken('CNPJ');
            const hoje = new Date();
            const dia = hoje.getDate().toString().padStart(2,'0');
            const mes = String(hoje.getMonth() + 1).padStart(2,'0');
            const ano = hoje.getFullYear();
            const data = `${dia}/${mes}/${ano}`;    
            const hora = hoje.toLocaleTimeString();

            let troco_para = getToken('VALORTROCO');
            const formapag = getToken('FORMAPAGAMENTO');
            const cliente = getToken('CLIENTE');
            const numero_web = getToken('NUMERO_WEB');
            const CPF_CNPJ = getToken('CPF_CNPJ');
            const buttonWithdraw = getToken('WITHDRAW')
            const NomeFormaPagamento = getToken('NOMEFORMAPAGAMENTO')

            const FinishDelivery1 = new FinishDelivery();
     
            if( token !== null) {  
                FinishDelivery1.cnpj = cnpj;
                FinishDelivery1.data = data;
                FinishDelivery1.hora = hora;
                FinishDelivery1.produtos = this.props.produtosSacola;
                FinishDelivery1.RETIRAR_LOJA = buttonWithdraw === 'true'? 'S' :'N';
                FinishDelivery1.tx_entrega = buttonWithdraw === 'false' ? frete : 0.00;
                FinishDelivery1.numero_web = numero_web;
                FinishDelivery1.CPF_CNPJ = CPF_CNPJ;
                FinishDelivery1.subtotal = this.props.totalGeral;
                FinishDelivery1.total = buttonWithdraw === 'false' ? (this.props.totalGeral + frete) : this.props.totalGeral;
                FinishDelivery1.cliente = cliente;
                FinishDelivery1.formapag = formapag;
                FinishDelivery1.status = 'Aguardando o Estabelecimento Aceitar';
                FinishDelivery1.troco_para = troco_para;
                FinishDelivery1.pedidoMinimo = this.props.parametros[0].pedido_minimo;
                FinishDelivery1.enderecoEscolhido = selectedAddress;
                FinishDelivery1.NomeFormaPagamento = NomeFormaPagamento;
                FinishDelivery1.enderedoEntrega = this.props.parametros;

            
                const verificarPedidoMinimo = FinishDelivery1.verificarPedidoMinimo();
                

                if ( NomeFormaPagamento === '' || NomeFormaPagamento === null){
                    this.props.location.history.push('/Informar/Endereco/Finalizar/FormaPagamento')
                    this.props.mostrarModalFormaPagamento();
                } 
                else if (verificarPedidoMinimo.success === false) {
                    alert(verificarPedidoMinimo.message);
                    this.props.location.history.push('/Sacola')
                    return {result: false, message: verificarPedidoMinimo.message};
                }else if  (this.props.totalGeral > 0){
          
                    const result = await FinishDelivery1.salvarPedido1();
                    if (result.success === true ) { 
                        const removerProducts = new login();
                        removerProducts.removendoProdutosIndexDB();
                        this.props.selecionandoProdutosAdd();     
                        logout('WITHDRAW');
                        logout('FORMAPAGAMENTO');
                        logout('VALORTROCO');
                        logout('NOMEFORMAPAGAMENTO');
                        this.props.FecharModalSacola() ;
                        this.props.mostrarModalPedido();
                        this.props.FecharModalUnlogged()  
                        this.props.location.history.push('/Pedidos')
                    }

                } 

            }
    

        }catch(error){
            
            console.log(error)
        }
        
    }

    alterarEndereco = async () => {
        try {
            const { usuarioId, cep, rua, bairro, cidade, numero, uf, complemento, ponto_referencia } = this.state;
            const {params:{id}} = this.props.location.match;
            
            this.setState({isLoadingEndereco : true, disabled: true});

            const service = new ServiceEndereco();
            const result  = await service.updateEnderecos(id,{
                usuarioId,
                cep,
                rua,
                bairro,
                cidade,
                numero,
                uf,
                complemento,
                ponto_referencia,
                banco : this.props.banco_cardapio
                
            });

            if(result.status === 200) {
                const array = [{id:parseInt(id), usuarioId, cep, rua, bairro, cidade, numero, uf, complemento, ponto_referencia}]
                this.setState({isLoadingEndereco : false});

                if(selecionado === 'S') this.props.mudandoEstadoEnderecoEscolhido(array);
                else this.props.updateStateAddress(array);

                this.FecharModalEndereco();   
            }
            else{
                this.setState({error: result.statusText, isLoadingEndereco:false});
            }

        } catch (errors) {
            this.setState({error: errors.response.statusText, isLoadingEndereco:false, disabled: false});
            if (errors.response.status === 401) {
                this.setState({error: 'Seção expirada. Faça o login novamente!'}) ;
            } 

        }
    }

    addEndereco = async () => {
        try {
            const {cep, rua, bairro, cidade, numero, uf, complemento, ponto_referencia } = this.state;
            this.setState({isLoadingEndereco : true, disabled: true});

            const service = new ServiceEndereco();
            const result  = await service.getEnderecoViaCep({
                usuarioId: getToken('IDUSUARIO'),
                cep,
                rua,
                bairro,
                cidade,
                numero,
                uf,
                complemento,
                ponto_referencia,
                banco: this.props.banco_cardapio
            });

            if(result.status === 201) {
                // this.setState({isLoadingEndereco : false});
                
                await this.props.getAllEndereco();
                selectedAddress = await this.props.selecionadoEndereco(this.props.enderecos[this.props.enderecos.length - 1])
                frete  = await this.props.taxaEntrega();
            
                const url = this.props.location.match.path;
               
                if (this.props.produtosSacola.length > 0 && url === '/Informar/Endereco/Finalizar'){
                    if(this.props.revalidadorNaoEntregamosAi) {
                        alert(this.props.message);
                        this.setState({disabled: false});
                        return false
                    }

                    await this.finalizar();
                } 
                else this.props.location.history.goBack();

    
                this.props.FecharModalUnlogged();
                this.props.FecharModal();

            }
            else{
                this.setState({error: result.statusText, isLoadingEndereco:false, disabled: false});
            }

        } catch (errors) {
            this.setState({error: errors.response.statusText, isLoadingEndereco:false, disabled: false});
            
            if (errors.response.status === 401) {
                this.setState({error: 'Seção expirada. Faça o login novamente!'}) ;
            } 
        }
    }

    Mensagem = () => {
        const {error} = this.state;
        return(
            <Alert variant='warning'>
                {error}
            </Alert>
        )
    }

    ValidarSomenteLetras = (e) => {
        const valid = new RegExp("[a-zA-Z\d]");
        return valid.test(e)
        
    }

    ApenasLetras = (e) => {
        let result = this.ValidarSomenteLetras(e);
        
        if (result){
            this.setState({uf: e})
        } 
        else{
            this.setState({uf: ''}) 
        }
    } 

    render(){
        const {error, isLoadingEndereco, disabled} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onMouseDown={(e) => verifyMouse(e, this.FecharModal)} onMouseUp={(e) => verifyMouse(e, this.FecharModal)}>
                    <BoxContainer >
                        <BoxHeader>
                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.FecharModalEndereco()}/>
                            <p>Endereço de Entrega</p>
                        </BoxHeader>

                        <BoxEndereco>
                            <BoxImageHeader>
                                <img src={imageAddressList} alt="imagem do mascote do sistefood"/>
                            </BoxImageHeader>

                            <SignUpQuoteBox>
                                <h1>Onde deseja receber o pedido ? </h1>
                            </SignUpQuoteBox>

                            <Form onSubmit={this.HandleEndereco}>
                                <p className="input-text-nome">CEP*</p>
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    maxLength={8}
                                    onChange={(e) => this.onChangeCEP(e) }
                                    value={this.state.cep}
                                />

                                <p className="input-text-nome">Cidade*</p>
                                <input
                                    type="text"
                                    maxLength={50}
                                    onChange={(e) => this.setState({cidade: e.target.value})}
                                    value={this.state.cidade}
                                />

                                <p className="input-text-nome">Endereço*</p>
                                    <input
                                        type="text"
                                        maxLength={50}
                                        onChange={(e) => this.setState({rua: e.target.value})}
                                        value={this.state.rua}
                                />

                                <p className="input-text-nome">Bairro*</p>
                                    <input
                                        type="text"
                                        maxLength={50}
                                        onChange={(e) => this.setState({bairro: e.target.value})}
                                        value={this.state.bairro}
                                />


                                    
            
                                <Row>
                                    <Col id="numero">
                                        <p className="input-text-nome">Número*</p>
                                            <input
                                                type="text"
                                                maxLength={10}
                                                onChange={(e) => this.setState({numero: e.target.value})}
                                                value={this.state.numero}
                                            />
                                    </Col>

                                    <Col id="complemento">
                                        <p className="input-text-nome">Complemento</p>
                                        <input className='input-text-below'
                                            type="text"
                                            maxLength={100}
                                            onChange={(e) => this.setState({complemento: e.target.value})}
                                            value={this.state.complemento}
                                        />
                                        <div className="forgot-password-text-wrapper">
                                            <p className="forget-password-text">Ex.:Casa,apartamento, etc.</p>
                                        </div>
                                    </Col>
                                </Row>

                                
                                <Row>
                                    <Col id="referencia">
                                        <p className="input-text-nome">Ponto de Referência</p>
                                            <input
                                                type="text"
                                                maxLength={50}
                                                onChange={(e) => this.setState({ponto_referencia: e.target.value})}
                                                value={this.state.ponto_referencia}
                                            />
                                    </Col>

                                    <Col id="uf">
                                        <p className="input-text-nome">UF</p>
                                            <input
                                                type="text"
                                                maxLength={2}
                                                onChange={(e) => this.ApenasLetras(e.target.value)}
                                                value={this.state.uf}
                                            />
                                    </Col>

                                </Row>
                                <Row>
                                    {
                                        error !== '' ? (
                                            <this.Mensagem />
                                        )
                                        :(
                                            null
                                        )
                                    }
                                </Row>
                                <BoxQtdProduto>
                                    <BoxBotaototal>
                                        <button type='submit' disabled={disabled}>
                                            Salvar
                                            {
                                                isLoadingEndereco ?(
                                                    <Spinner animation="border"/>
                                                ) 
                                                :(
                                                    null
                                                )
                                  
                                            }
                                        </button>
                                    </BoxBotaototal>
                                </BoxQtdProduto> 
                            </Form>          
                        </BoxEndereco>               
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }   
}

export default Endereco