import React from 'react';
import {
    BoxModal,
    BoxContainer,
    BoxHeader,
    BoxIconeVoltar, 
    BoxFormaPagamento,
    BoxQtdProduto,
    BoxBotaototal,
    BoxEntrega,
    BoxPagueEntrega,
    BoxCartao,
    BoxContainerTroco,
    BoxComTroco,
    BoxSemTroco,
    BoxIconCoin,
    BoxNotIconCoin,
    BoxIconCard,
    BoxTroco,
    WrapperBoxTotais,
    BoxTotais,
    Item,
    Mark,
    Label,
    Input
    
} from './style'

import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import IconCoin from '../../assets/FormaPagamento/iconCoin.svg'
import IconNotCoin from '../../assets/FormaPagamento/iconNotCoin.svg'
import IconCardActive from '../../assets/FormaPagamento/iconCard.svg'
import iconCardNotActive from '../../assets/FormaPagamento/iconCardNotActive.svg'
import iconMoney from '../../assets/FormaPagamento/iconMoney.svg'
import iconMoneyNotActive from '../../assets/FormaPagamento/iconMoneyNotActive.svg'
import iconNoChange from '../../assets/FormaPagamento/iconNoChange.svg'
import iconCoinNotActive from '../../assets/FormaPagamento/iconCoinNotActive.svg'
import iconPix from '../../assets/FormaPagamento/iconPix.svg'
import iconNotPix from '../../assets/FormaPagamento/iconNotPix.svg'
import { entrar, getToken, logout } from '../../service/auth';
import { formatCompare, verifyMouse } from '../../functionsGlobal';
import { FinishDelivery } from '../../Class/finishDelivery';
import { login } from '../../Class/login';
import { Spinner} from 'react-bootstrap'

class FormaPagamento extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            dinheiro:false,
            cartao: true,
            comTroco:false,
            semTroco:true,
            troco:0.00,
            isLoadingSpinner:false,
            disabled: true,
            Payment:[
                {
                    id:2,
                    name: 'Cartão de Débito',
                    selected: true,
                    iconActive:  IconCardActive,
                    iconNotActive: iconCardNotActive,
                    NotMoney: true
                },
                {
                    id:3,
                    name:'Cartão de Crédito',
                    selected:false,
                    iconActive: IconCardActive,
                    iconNotActive:iconCardNotActive,
                    NotMoney: true
                },
                {
                    id:4,
                    name:'Pix',
                    selected:false,
                    iconActive:  iconPix ,
                    iconNotActive: iconNotPix,
                    NotMoney: true
                },
                {
                    id:1,
                    name:'Dinheiro',
                    selected:false,
                    iconActive:   iconMoney,
                    iconNotActive: iconMoneyNotActive,
                    NotMoney: true,
                    withChange:false
                },
            ]

        }
    }

    componentDidMount () {
        this.carregandoValorTroco();
    }

    carregandoValorTroco = () => {
        const {Payment} = this.state;
        this.setState({disabled: false});
        let valorTroco = (getToken('VALORTROCO')); 
        let verificarForma = JSON.parse(getToken('FORMAPAGAMENTO'));



        if (verificarForma !== null) {
            valorTroco = formatCompare(valorTroco);
            this.setState({Payment:Payment[0].selected = false}) ;
        }

        if (verificarForma === 1 ) {
            Payment[3].selected = true;
            Payment[1].selected = false;
            Payment[2].selected = false;
            Payment[0].selected = false;

            if (valorTroco){
                Payment[3].withChange = true;
                Payment[3].NotMoney = false;
            }

            this.setState({
                Payment:Payment,
                troco: valorTroco ? getToken('VALORTROCO') : '0,00'

            })

        }
        else if (verificarForma === 2) {

            Payment[0].selected = true;
            Payment[1].selected = false;
            Payment[2].selected = false;
            Payment[3].selected = false;

            this.setState({
                Payment:Payment


            })
        }
        else if (verificarForma === 3) {

            Payment[1].selected = true;
            Payment[0].selected = false;
            Payment[2].selected = false;
            Payment[3].selected = false;
            this.setState({
                Payment:Payment

            })

        }
        else if (verificarForma === 4) {

            Payment[2].selected = true;
            Payment[0].selected = false;
            Payment[1].selected = false;
            Payment[3].selected = false;
            this.setState({
                Payment:Payment

            })
        }

    }
    
    fecharModal = (event) => {
        if (event.target.id === this.props.idd) {
            this.fecharModalFormaPagamento();
        }
    }

    proibirCaracteres = e => {
        let valor = e.target.value;

        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ",$1");

        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        }

        if (valor < 1 && valor !== '0') {
            valor = 0 + valor 
        }
        
        e.target.value = valor;
        if(valor === 'NaN' )  e.target.value= '0,00';
    }

    fecharModalFormaPagamento = () => {
        this.props.fecharModalPagamento();
    }

    onChangeTroco = (e) => {
        this.proibirCaracteres(e);
        this.setState({troco: e.target.value})
    }

    GravarTrocoNew = () => {
        const {troco} = this.state;

        let resultFilterArray = this.filterArrayPayment();
        let trocoFormatado = parseFloat (troco) > 0 ? troco : '0,00'

        trocoFormatado =  trocoFormatado.replace('.','');
        trocoFormatado = trocoFormatado.replace(',', '.');
  
        
        let totalPedido = this.props.entrega ? parseFloat(this.props.totalGeral + this.props.parametro).toFixed(2) : parseFloat(this.props.totalGeral).toFixed(2);

        if (resultFilterArray[0].id === 1 ) {

            if (parseFloat(trocoFormatado) === 0 && resultFilterArray[0].NotMoney ) {
                entrar('VALORTROCO', 0.00);
                entrar('FORMAPAGAMENTO', resultFilterArray[0].id); 
                entrar('NOMEFORMAPAGAMENTO', 'Dinheiro');

            }

            else if ( (parseFloat(trocoFormatado) > 0) && (parseFloat(trocoFormatado) >  parseFloat(totalPedido) ) ) {

                entrar('VALORTROCO', troco);
                entrar('FORMAPAGAMENTO', resultFilterArray[0].id); 
                entrar('NOMEFORMAPAGAMENTO', 'Dinheiro'); 
            }
            else if ((parseFloat(trocoFormatado) > 0) && (parseFloat(trocoFormatado) <=  parseFloat(totalPedido) )) {
                return alert('O valor do troco deve ser maior que o total da compra!')
            }

        }
        else if (resultFilterArray[0].id === 2) {
            entrar('VALORTROCO', 0.00);
            entrar('FORMAPAGAMENTO', resultFilterArray[0].id); 
            entrar('NOMEFORMAPAGAMENTO', 'Cartão de Débito');

        }
        else if (resultFilterArray[0].id === 3) {
            entrar('VALORTROCO', 0.00);
            entrar('FORMAPAGAMENTO', resultFilterArray[0].id); 
            entrar('NOMEFORMAPAGAMENTO', 'Cartão de Crédito');


        }
        else if (resultFilterArray[0].id === 4) {
            entrar('VALORTROCO', 0.00);
            entrar('FORMAPAGAMENTO', resultFilterArray[0].id); 
            entrar('NOMEFORMAPAGAMENTO', 'Pix');

        }
        
        const url = this.props.router.match.path;

        if(url === '/EscolhaSeuEndereco/FormaPagamento' || url === '/Informar/Endereco/Finalizar/FormaPagamento') return this.finalizar();

        this.props.alterarEstadoNomeFormaPagamento();
        this.fecharModalFormaPagamento();
       
    }

    finalizar = async() => {
        try {
            let statusLoja = this.props.parametros.map(item => item.status_cardapio);
            if (statusLoja.toString() === 'N') return alert('Estabelecimento fechado!')
            if (this.props.totalGeral <= 0) return alert('Sacola sem produto. Informa algum produto para finalizar!')

            const token = getToken('TOKEN');
            const cnpj =  getToken('CNPJ');
            const hoje = new Date();
            const dia = hoje.getDate().toString().padStart(2,'0');
            const mes = String(hoje.getMonth() + 1).padStart(2,'0');
            const ano = hoje.getFullYear();
            const data = `${dia}/${mes}/${ano}`;    
            const hora = hoje.toLocaleTimeString();

            let troco_para = getToken('VALORTROCO');
            const formapag = getToken('FORMAPAGAMENTO');
            const cliente = getToken('CLIENTE');
            const numero_web = getToken('NUMERO_WEB');
            const CPF_CNPJ = getToken('CPF_CNPJ');
            const buttonWithdraw = getToken('WITHDRAW')
            const NomeFormaPagamento = getToken('NOMEFORMAPAGAMENTO');

            const FinishDelivery1 = new FinishDelivery();

            if( token !== null) {  

                FinishDelivery1.cnpj = cnpj;
                FinishDelivery1.data = data;
                FinishDelivery1.hora = hora;
                FinishDelivery1.produtos = this.props.produtosSacola;
                FinishDelivery1.RETIRAR_LOJA = buttonWithdraw ==='true' ? 'S' :'N';
                FinishDelivery1.tx_entrega = buttonWithdraw === 'false' ? this.props.parametro : 0.00;
                FinishDelivery1.numero_web = numero_web;
                FinishDelivery1.CPF_CNPJ = CPF_CNPJ;
                FinishDelivery1.subtotal = this.props.totalGeral;
                FinishDelivery1.total = buttonWithdraw === 'false' ? (this.props.totalGeral + this.props.parametro) : this.props.totalGeral;
                FinishDelivery1.cliente = cliente;
                FinishDelivery1.formapag = formapag;
                FinishDelivery1.status = 'Aguardando o Estabelecimento Aceitar';
                FinishDelivery1.troco_para = troco_para;
                FinishDelivery1.pedidoMinimo = this.props.parametros[0].pedido_minimo;
                FinishDelivery1.enderecoEscolhido = this.props.enderecoEscolhido;
                FinishDelivery1.NomeFormaPagamento = NomeFormaPagamento;
                FinishDelivery1.enderedoEntrega = this.props.parametros;
            
                const verificarPedidoMinimo = FinishDelivery1.verificarPedidoMinimo();
          
          
                if (verificarPedidoMinimo.success === false) {
                    alert('pedido minimio')
                    alert(verificarPedidoMinimo.message);
                    
                    this.props.router.history.push('/Sacola')
                    return {result: false, message: verificarPedidoMinimo.message};

                }else if  (this.props.totalGeral > 0){
                    
                    this.setState({disabled: true, isLoadingSpinner : false})
        
                    const result = await FinishDelivery1.salvarPedido1();
                    
                    if (result.success === true ) {
                        const Product = new login();
                        Product.removendoProdutosIndexDB();
                        this.props.selecionandoProdutosAdd();
                        this.props.FecharModalEnderecos();
                        this.props.FecharModalSacola();
                        this.props.FecharModalEndereco(); 
                        this.props.fecharModalPagamento();
                        this.props.mostrarModalPedido();

                    
                 
                        logout('WITHDRAW');
                        logout('FORMAPAGAMENTO');
                        logout('VALORTROCO');
                        logout('NOMEFORMAPAGAMENTO');
                        this.props.router.history.push('/Pedidos');
                    }
                    else if(result.success === false ){
                        this.setState({disabled: false, isLoadingSpinner : false})
                    }
                } 
            }

        }catch(error){
            console.log(error)
            this.setState({disabled: false, isLoadingSpinner : false})
        }
        
    }

    filterArrayPayment = () => {
        const {Payment} = this.state;

        const result = Payment.filter((item) => {return item.selected})
        return result
    }

    SelectionCard = () => {
        const {Payment} = this.state;

        if (Payment[3].selected ) {
            Payment[3].NotMoney = false;
            Payment[3].withChange = true;
            this.setState({Payment:Payment})
        }

    }

    SelectionMoney = () => {
        const {Payment} = this.state;

        Payment[3].NotMoney = true;
        Payment[3].withChange = false;
        
        this.setState({Payment:Payment, troco:0.00})

    }

    selectionPayment = (index) => {
        const {Payment} = this.state;
        Payment[index].selected = true;
        this.setState({Payment: Payment});

        if (index !== 3 ){
            Payment[3].NotMoney   = true;
            Payment[3].withChange = false;
            this.setState({troco:'0,00'})
        } 

        this.selectionNotPayment(index);
    }

    selectionNotPayment = (index) => {
        const {Payment} = this.state; 

        Payment.map((item, indexPayment) => {
            if (indexPayment !== index){
                Payment[indexPayment].selected = false;

            }
        })

        this.setState({Payment: Payment});
    }

    renderLine = ({id}) => {
        if (id !== 1) {
            return <div id='linha-meio'/>
        }
        else return null
    }

    render(){
        const {Payment, troco, disabled, isLoadingSpinner} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onMouseDown={(e) => verifyMouse(e, this.fecharModal)}
                    onMouseUp={(e) => verifyMouse(e, this.fecharModal)}
                >
                    <BoxContainer>
                        <BoxHeader>
      
                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.fecharModalFormaPagamento()}/>

                            <h1>Forma de Pagamento</h1>
                        </BoxHeader>

                        <BoxEntrega>
                            <div id='pagamento-entrega'>
                                <p>Pagamento na Entrega</p>
                            </div>
                        </BoxEntrega>

                        <BoxPagueEntrega>
                            <p>Pague no momento da entrega!</p>
                        </BoxPagueEntrega>

                        <BoxFormaPagamento>

                            {
                                Payment.map((item, index)  =>              
                                    <>
                                        <BoxCartao key={item.id} cartao={item.selected} onClick={() =>  this.selectionPayment(index)} >
                                            <BoxIconCard src={item.selected ? item.iconActive : item.iconNotActive }/>
                                            <Item>
            
                                                <p className='forma-pagamento'>{item.name}</p>
                                                <Label >
                                                    <Input 
                                                        type='radio' 
                                                        id={item.id}
                                                        name={item.name}
                                                        className='radio-input'
                                                        onChange={() =>  this.selectionPayment(index)}
                                                        value={Payment[index].selected}
                                                        checked={Payment[index].selected}
                                                    />
                                                      <Mark />
                                                </Label>
                                            </Item>
                                        </BoxCartao>
                                        <this.renderLine id={item.id}/>
                       
                                    </>
                                )
                            }
            
                            <BoxContainerTroco>
                                <BoxComTroco onClick={()=> this.SelectionCard()} comTroco={Payment[3].withChange}>
                                    <BoxIconCoin src={Payment[3].withChange ? IconCoin : iconCoinNotActive }/>
                                    <p>Com Troco</p>
                                </BoxComTroco>

                                <BoxSemTroco  onClick={() => this.SelectionMoney()} semTroco={Payment[3].NotMoney}>
                                    <BoxNotIconCoin src={Payment[3].NotMoney? iconNoChange : IconNotCoin }/>
                                    <p>Sem Troco</p>
                                </BoxSemTroco>
                            </BoxContainerTroco>


                            <BoxTroco comTroco={Payment[3].withChange ? true : false} >
                                <p>Troco para:</p>    
                                <input 
                                    onChange={(e) => this.onChangeTroco(e)} 
                                    value={troco}
                                    maxLength={10}
                                    inputMode="numeric"
                                    readOnly={Payment[3].NotMoney ? true : false} 
                                />
                            </BoxTroco>

                            
                            <WrapperBoxTotais>
                                <BoxTotais>
                                    {
                                        <>
                                            <div id='subtotal'>
                                                <p>
                                                    Sub Total 
                                                </p>

                                                <p>
                                                    {parseFloat(this.props.totalGeral).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}
                                                </p>
                                            </div>

                                            <div id='frete'>
                                                <p>
                                                    Taxa de entrega:
                                                </p>

                                                <p className="total-delivery">

                                                {
                                                    this.props.entrega ?
                                                        parseFloat(this.props.parametro).toLocaleString('pt-br', {style:'currency', currency:'BRL'})
                                                    :
                                                        'Grátis'
                                                } 

                                                </p>
                                            </div>

                                            <div id='total-text'>
                                                <p>
                                                    Total:
                                                </p>

                                                <p>
                                                {
                                                    this.props.entrega ? 
                                                        parseFloat(this.props.parametro +  this.props.totalGeral ).toLocaleString('pt-br', {style:'currency', currency:'BRL'})
                                                    :
                                                        parseFloat( this.props.totalGeral).toLocaleString('pt-br', {style:'currency', currency:'BRL'})
                                                }  
                                                </p>
                                            </div>

                                            </>
                                        }
                                </BoxTotais>
                            </WrapperBoxTotais>
                        </BoxFormaPagamento>

                        <BoxQtdProduto>
                            <BoxBotaototal>
                                <button onClick={() => this.GravarTrocoNew()} disabled={disabled}>
                                    Salvar
                                    {
                                        isLoadingSpinner ? (
                                            <Spinner animation="border" variant="primary"   id='spinner'/>
                                        )
                                        :(
                                            null
                                        )
                                    }
                                </button>
                            </BoxBotaototal>
                        </BoxQtdProduto>
                    </BoxContainer>
                </BoxModal>              
            </>
        )
    }
}
export default FormaPagamento;