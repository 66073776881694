import styled from "styled-components";


export  const  Boxwrapperpromotion = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 60px;
`;

export const BoxButtonLeft = styled.img`
  width: 2.5em; 
  height: 2.5em;
  z-index: 10;
  display:flex;
  position: absolute;
  top:40%;
  left: -1em;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  background-color: #E6E3E3;
  transform: rotate(0);

  @media (max-width:750px) {
    width: 0;
  }
`;

export const BoxButtonRight = styled.img`
  width: 2.5em; 
  height: 2.5em;
  z-index: 10;
  display:flex;
  top:40%;
  right: -1em;
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  background-color: #E6E3E3;
  transform: rotate(180deg);
  
  @media (max-width:750px) {
    width: 0;
  }

`;

export const BoxContainerpromotion = styled.div`
    display: flex;
    width: 100%;
    overflow-x: scroll;
    position: relative;
    scroll-behavior: smooth;
    scrollbar-width: none;
 
    ::-webkit-scrollbar {
      display: none;
   
    }

`;

export const BoxCardPromotion = styled.div`

  display: flex;
  width: 230px;
  height: 298px;
  flex: none;
  flex-direction: column;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 9.22018px;
  margin: 0.5em 0.5em 0.5em 0.5em;
  overflow: hidden;
  align-items: flex-start;

  background-color: ${props=>props.theme.background.White_2};


  .link {
    display: flex;
    flex-direction: column;
    width: 230px ;
    height: 298px;
    text-decoration: none;
    gap: 11.83px;
    padding: 8px 8px 14px;
    color: ${props=>props.theme.text.Black_1};
  }

  .row {
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 140px;

  }
  
  span > img{
    border: 1px solid transparent;
    border-radius: 10px;
  }
  
  @media (min-width: 280px) and (max-width: 720px) {
    width: 180px;

    .link{
      width: 180px; 
    }

  }
`;

export const BoxCardinternoPromotion = styled.div`

  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  font-weight: 600;
  flex-wrap: wrap;


  .prices{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 100%;
    height: 24px;

    .valuepromotion {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #C51F2A;
    }

    .valuenotpromotion{
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-decoration-line: line-through;
      color: #999999;
    }
  }

  .nomeproduto,
  .infoitem {
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .infoitem {
    color: rgb(113, 113, 113);
    font-weight: 300;
  }

  .nomeproduto {
    font-size: 15px;
    line-height: 24px;
  }

`;

export const IconPromotion = styled.img`
  width: 24px;
  height: 24px; 
  
`;