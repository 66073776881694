import styled from "styled-components";

export const Container = styled.div`
  width: 55px;
  height: 55px;
  background-color: ${(props)=>props.BackgroundColor};
  border: none;
  outline: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 4px 12px #b3b0b8; */
  box-shadow: 0px 4px 12px ${props=>props.shadow};
`;
