import baseURL from "../configs/baseURL";
import baseApi from "./api";

class ServiceCep {

    constructor() {
       this.api = baseApi(baseURL.VIACEP)
    }
    

    async getOneCep(cep) {
        const result = await this.api.get(`${cep}/json`)
        return result;
    }

}

export default ServiceCep
