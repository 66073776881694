import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  background: ${props=>props.theme.background.Primary};
  width: 100%;
  max-width: 520px;
  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxIconeVoltar = styled.img`
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;

export const BoxImageHeader = styled.div`
  width: 100%;
  max-width: 520px;
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`;

export const Address = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  cursor: pointer;
  height: 75px;
  border: 1px solid grey;
  border-radius: 15px;
  margin-bottom: 20px;
  
  :focus{
    border: 1px solid ${props => props.theme.colors.Red};
  }
  
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rua {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 48px;
    align-items: initial;
    justify-content: center;
    margin-right: 10px;
    margin-left: 18px;

    p {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: ${props=>props.theme.text.Black_1};
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
    }
    .text-2 {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: ${props=>props.theme.text.Black_1};
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
    }

    .add-new-address {
      color: ${props=>props.theme.text.Red_1};
    }
  }

  @media (min-width: 320px) and (max-width: 374px) {
    .icon-wrapper {
      display: none;
    }
    .rua {
      margin-left: 0px;
      p {

        font-size: 10px;
        line-height: 14px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
      }
      .text-2 {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
`;

export const Address2 = styled(Address)`
  border: 1px solid ${props=>props.theme.colors.Red};
  .rua {

    p {
      color: ${props=>props.theme.text.Red_1};
    }
  }

  @media (min-width: 320px) and (max-width: 374px) {
    .icon-wrapper {
      display: flex;
    }
    .rua {
        width: auto;
        margin-right:0;
        margin-left:15px;
      p {
        color: ${props=>props.theme.text.Red_1};
      }
    }
  }
`;

export const BoxEnderecos = styled.div`
  padding: 24px;
  display: flex;
  overflow-x: auto;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 520px;
  padding-bottom: 1em;

  .header-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 52px;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;

    h4 {
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
    }

  }

  .enderecos {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    width: 100%;
    height: 75px;
    border: 1px solid ${props=>props.theme.colors.Grey_3};
    border-radius: 15px;
  }

  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background-color: ${props=>props.theme.background.Primary};
  height: 52px;
  border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
  }
`;

export const BoxIconeEditar = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

export const BoxIconeExcluir = styled(BoxIconeEditar)`
  margin-right: 8px;
  margin-left: 32px;
`;

export const BoxAcoes = styled.div`
  display: flex;
  justify-content: space-between;
  width: 120px;
  
`;


export const BoxMessage = styled.div`
  display: flex;
  position: fixed;
  bottom: 60px;
  justify-content: center;
  max-width: 520px;
  align-items: center;
  padding: 1em;

`;

export const BoxSpinner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 75px;

  .spinner-address{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 75px;
  }
`;