import React from 'react';
import 
{
    BoxEnderecos, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxModal,
    BoxIconeExcluir,
    BoxAcoes,
    BoxIconeEditar,
    BoxImageHeader,
    Address,
    Address2,
    BoxSpinner

} from './style' 

import ServiceEndereco from '../../service/enderecoApiNode';
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import Exluir from '../../assets/Commons/iconTrash.svg';
import Editar from '../../assets/Commons/iconEdit.svg';
import imageAddressList from '../../assets/ImageBackGroundLocation/imageAddressList.svg';
import iconAddress from '../../assets/Commons/iconAddressList.svg';
import iconAddressAdd from '../../assets/Commons/iconAddAddress.svg';
import { getToken, logout } from '../../service/auth';
import { FinishDelivery } from '../../Class/finishDelivery';
import { verifyMouse } from '../../functionsGlobal';
import { login } from "../../Class/login";
import { Spinner } from 'react-bootstrap';

let frete = 0;

class ListarEnderecos extends React.Component{

    constructor(props) {
        super(props)

        this.state = {
            selected: false,
            disabled: false,
            address:[]
        }
    }
   
    async componentDidMount() {
        await this.props.getAllEndereco();
        this.setState({address: this.props.enderecos})
    }

    componentWillUnmount(){
        frete = 0; 
    }

    componentDidUpdate(prevProps, prevState ){
        if(prevProps.enderecos.length > 0){
            if(prevProps.enderecos.length !== prevState.address.length){

                this.setState({address:prevProps.enderecos})
            }
        }
       
    }

    deleteAddress = (id) => {
        const {address} = this.state;

        if(address.length ===1 ) {
            this.props.updateEnderecos([]);
            return  this.setState({address: []});
        }
        const arrayTemp = address.filter(item => item.id !== id);

        this.props.updateEnderecos(arrayTemp, id);
        this.setState({address: arrayTemp})

    }

    deletarEndereco = async (id) => {
        try {
            const service = new ServiceEndereco();
            const result = await service.deleteEndereco(id, this.props.banco_cardapio)

            if (result.status === 200) {
                const {address} = this.state;
                this.props.alteraNaoEntregamosAi()
        
                if (address.length === 0)  {
                    this.fecharModalEnderecos();
                }
                else{
                    this.deleteAddress(id);
                } 
 
            }

        } catch (error) {
            console.log(error)
        }
    } 

    fecharModalEnderecos = () => {
        this.props.fecharModalListagemEnderecos();
    }

    fecharModal = (event) => {
        if (event.target.id === this.props.idd ) this.fecharModalEnderecos()
    }

    mudandoEstadoEndereco = (id) => {
        this.props.mudandoEstadoEndereco();
        const url =  this.props.location.match.path;

        if(url === '/Sacola/ListarEnderecos')  this.props.location.history.push(`/Sacola/ListarEnderecos/${id}/Endereco`);
        else if (url === '/EscolhaSeuEndereco') this.props.location.history.push(`/EscolhaSeuEndereco/${id}/Endereco`);
        else  this.props.location.history.push(`/UsuarioLogado/ListarEnderecos/${id}/Endereco`);
       
    }

    mostrarModalEndereco = () => {
        this.props.mudandoEstadoEndereco()
        const url =  this.props.location.match.path;

        if(url === '/Sacola/ListarEnderecos') this.props.location.history.push(`/Sacola/ListarEnderecos/Endereco`);
        else if (url === '/EscolhaSeuEndereco') this.props.location.history.push(`/EscolhaSeuEndereco/Endereco`);
        else this.props.location.history.push(`/UsuarioLogado/ListarEnderecos/Endereco`);
    }

    verifyUrlTextChange = ()=>{
        if(this.props.location.location.pathname === '/UsuarioLogado/ListarEnderecos') {
            return (
                <>
                    <h4>Aqui estão seus endereços de entrega!</h4>
                </>
            )
        }
        else{
            return(
                <>
                    <h4>Onde deseja receber o seu pedido?</h4>
                    <p>Escolha um endereço ou cadastre um novo! </p>
                </>

            )
        }
    }

    finalizar = async() => {
        try {
            const token = getToken('TOKEN');
            const cnpj =  getToken('CNPJ');
            const hoje = new Date();
            const dia = hoje.getDate().toString().padStart(2,'0');
            const mes = String(hoje.getMonth() + 1).padStart(2,'0');
            const ano = hoje.getFullYear();
            const data = `${dia}/${mes}/${ano}`;    
            const hora = hoje.toLocaleTimeString();

            let troco_para = getToken('VALORTROCO');
            const formapag = getToken('FORMAPAGAMENTO');
            const cliente = getToken('CLIENTE');
            const numero_web = getToken('NUMERO_WEB');
            const CPF_CNPJ = getToken('CPF_CNPJ');
            const buttonWithdraw = getToken('WITHDRAW');
            const FinishDelivery1 = new FinishDelivery();

            if( token !== null) {  
                FinishDelivery1.cnpj = cnpj;
                FinishDelivery1.data = data;
                FinishDelivery1.hora = hora;
                FinishDelivery1.produtos = this.props.produtosSacola;
                FinishDelivery1.RETIRAR_LOJA = buttonWithdraw === 'true' ? 'S' :'N';
                FinishDelivery1.tx_entrega = buttonWithdraw === 'false' ? frete : 0.00;
                FinishDelivery1.numero_web = numero_web;
                FinishDelivery1.CPF_CNPJ = CPF_CNPJ;
                FinishDelivery1.subtotal = this.props.totalGeral;
                FinishDelivery1.total = buttonWithdraw === 'false'? (this.props.totalGeral + frete) : this.props.totalGeral;
                FinishDelivery1.cliente = cliente;
                FinishDelivery1.formapag = formapag;
                FinishDelivery1.status = 'Aguardando o Estabelecimento Aceitar';
                FinishDelivery1.troco_para = troco_para;
                FinishDelivery1.pedidoMinimo = this.props.parametros[0].pedido_minimo;
                FinishDelivery1.enderecoEscolhido = this.props.enderecoEscolhido;
                FinishDelivery1.NomeFormaPagamento = getToken('NOMEFORMAPAGAMENTO');;
                FinishDelivery1.enderedoEntrega = this.props.parametros;

 
                const verificarPedidoMinimo = FinishDelivery1.verificarPedidoMinimo();
     

                if (  FinishDelivery1.NomeFormaPagamento === '' ||  FinishDelivery1.NomeFormaPagamento === null){
                    this.setState({disabled: false});
                    this.resetIsLoadingSpinnerStateAddress();
                    this.props.mostrarModalFormaPagamento();
                } 
                else if (verificarPedidoMinimo.success === false) {
                    alert(verificarPedidoMinimo.message);
                    this.props.location.history.push('/Sacola')
                    return {result: false, message: verificarPedidoMinimo.message};
                }else if  (this.props.totalGeral > 0){
                    await FinishDelivery1.salvarPedido1();
                    const Finish = new login();
                    Finish.removendoProdutosIndexDB();
                    this.props.selecionandoProdutosAdd();
                    logout('WITHDRAW');
                    logout('FORMAPAGAMENTO');
                    logout('VALORTROCO');
                    logout('NOMEFORMAPAGAMENTO');
                    this.props.mostrarModalPedido();
                    this.props.FecharModalSacola()  
                    this.props.FecharModalEnderecos();
                    this.props.location.history.push('/Pedidos')
                } 

   
            }

        }catch(error){
            console.log(error)
        }
        
    }

    resetIsLoadingSpinnerStateAddress = () => {
        const {address} = this.state;
        
        const arrayTemp = [];

        address.map((item) => {
            item.isLoadingSpinner = false;
        })

        arrayTemp.push(...address);
        this.setState({address: arrayTemp})
    }

    updateStateAddress = (id) => {
        const {address} = this.state;
        
        const arrayTemp = [];

        address.map((item) => {
            if(item.id === id){
                item.isLoadingSpinner = true;
                this.props.updateStateAddress([item])
            }
        })

        arrayTemp.push(...address);
        this.setState({address: arrayTemp})

    }

    verifyUrl = async(item) => {
        try {
            const {disabled} = this.state;
            this.updateStateAddress(item.id)
            if(disabled) return false;
            this.setState({disabled: true});
            const url = this.props.location.match.path;
    
            await this.props.selecionadoEndereco(item);
            frete = await this.props.taxaEntrega();

            if (url === '/EscolhaSeuEndereco') {
                if(this.props.revalidadorNaoEntregamosAi) {
                    alert(this.props.message);
                    this.setState({disabled: false});
                    this.resetIsLoadingSpinnerStateAddress();
                    return false
                }
                await this.finalizar();
            }
            else {
                this.props.fecharModalListagemEnderecos()
            }

        } catch (error) {
            this.setState({disabled: false});
            this.resetIsLoadingSpinnerStateAddress();
        }
    }

    VerifyAddress = () => {
        const {address} = this.state;

        return(
            address.length > 0 ? (
                address.map((item) => 
                    
                    item.isLoadingSpinner  ? (
                        <Address tabIndex={1} onClick={()=> this.setState({selected:true})} key={item.id}>
                            <BoxSpinner >
                                <div className='spinner-address'>
                                    <Spinner animation="border" variant="primary"/>
                                </div>
        
                                <div className='icon-wrapper' onClick={() => this.verifyUrl(item)}>
                                    <img src={iconAddress} alt="icone de um capacete simbolizando a entrega "/>
                                </div>
                                <div className='rua' onClick={() => this.verifyUrl(item)}>
                                    <p>{`${item.rua},${item.numero}`}</p>
                                    <p className='text-2'>{`${item.bairro}-${item.cidade}/${item.uf}`}</p>
                                </div>
                                
                                <BoxAcoes>
                                    <BoxIconeEditar src={Editar}   onClick={() => this.mudandoEstadoEndereco(item.id)} />
                                    <BoxIconeExcluir src={Exluir} onClick={() => this.deletarEndereco(item.id)}/>
                                </BoxAcoes>
                            </BoxSpinner> 
                        </Address>
                    )
                    :(
                        <Address tabIndex={1} onClick={()=> this.setState({selected:true})} key={item.id}>
                            <div className='icon-wrapper' onClick={() => this.verifyUrl(item)}>
                                <img src={iconAddress} alt="icone de um capacete simbolizando a entrega "/>
                            </div>
                            <div className='rua' onClick={() => this.verifyUrl(item)}>
                                <p>{`${item.rua},${item.numero}`}</p>
                                <p className='text-2'>{`${item.bairro}-${item.cidade}/${item.uf}`}</p>
                            </div>
                            
                            <BoxAcoes>
                                <BoxIconeEditar src={Editar}   onClick={() => this.mudandoEstadoEndereco(item.id)} />
                                <BoxIconeExcluir src={Exluir} onClick={() => this.deletarEndereco(item.id)}/>
                            </BoxAcoes>
                        </Address>
                    )
                    
                )
            )
            :(
                <Spinner animation="border" variant="primary"/>
            )
        )
    }

    render(){

        return(
            <>
                <BoxModal id={this.props.idd} onMouseDown={(event) => verifyMouse(event, this.fecharModal)}
                    onMouseUp={(event) => verifyMouse(event, this.fecharModal)}
                >
                    <BoxContainer>
                        <BoxHeader>
                     
                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.fecharModalEnderecos()}/>
                   
                            <h1>Endereços</h1>
                        </BoxHeader>

                        <BoxEnderecos >
                            <BoxImageHeader>
                                <img src={imageAddressList} alt = "imagem do mascote da sistefood"/>
                            </BoxImageHeader>
                            <div className='header-text'>
                                {this.verifyUrlTextChange()}
                            </div>

                            <Address2 className='add-new' onClick={()=> this.mostrarModalEndereco()}>
                                <div className='icon-wrapper'>
                                    <img src={iconAddressAdd} alt="imagem de um sinal de + para a adição dos novos endereços"/>

                                </div>
                                <div className='rua'>
                                    <p className='add-new-address'>Cadastrar Novo Endereço</p>
                                </div>                    
                            </Address2>
                           
                            {
                                this.VerifyAddress()
                            }                          
                        </BoxEnderecos>
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default ListarEnderecos;