import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 24px;
  gap: 16px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 32px 32px;
  margin-bottom: 17px;

  .wrapper-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 32px;
    gap: 10px;
    margin-top: 24px;
    width: 100%;
    text-align: center;

    .stablishment-name {
      width: 100%;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
 
    }
  };

  .wrapper-2 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 10px 32px;
    text-align: center;

    .welcome-quote {
      width: 188px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

  };

  .wrapper-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1; 
    cursor: pointer;
  }
`;

export const MoreInformation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  gap: 10px;
  width: 100%;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  p{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-decoration-line: underline;
    color: #C51F2A;
  }
`;

export const ContainerPreparationTimeAvarage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px;
  gap: 10px;
  margin-top:16px ;
  width:100%;
  height: 62px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;

  .text-preparation{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 24px;
    gap: 4px;
    width: 100%;
    height: 62px;
    background: #FFFFFF;
    border: 1px solid #E6E3E3;
    border-radius: 16px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; 
  }

  #preparation-time-avarage{
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 154px;
    height: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;

    p{
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #2F2F2F;
    }
  }

  #time{
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 84px;
    height: 18px;
    flex: none;
    order: 1;
    flex-grow: 0; 

    p{
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #2F2F2F;

    }
  }

`;